import { Route, Switch, useParams } from "react-router-dom";
import BandBoard from "./BandBoard";
import BandNotice from "./BandNotice";
import BandPray from "./BandPray";
import { BandMenu } from "./BandMenu";
import { BandSidebar } from "./BandSidebar";

interface ParamTypes {
  group: string;
}

export const Band = () => {
  const { group } = useParams<ParamTypes>();

  return (
    <>
      <BandMenu group={group} />
      <div className="d-md-flex vh-100">
        <BandSidebar group={group} />
        <div className="col-md-9 col-lg-10 p-2">
          <Switch>
            <Route path={`/band/:group/notice`}>
              <BandNotice />
            </Route>
            <Route path={`/band/:group/pray`}>
              <BandPray />
            </Route>
            <Route path={`/band/:group/board`}>
              <BandBoard />
            </Route>
            <Route>
              <BandNotice />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};