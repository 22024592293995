import { Link } from "react-router-dom";

type ChannelsListType = {
  channels: any[];
  onDelete: Function;
}

export const ChannelList = ({ channels, onDelete }: ChannelsListType) => {
  console.log("channels", channels);
  return (
    <div>
      <div className="d-flex mt-5 justify-content-between">
        <h5 className="">채널 리스트</h5>
        <Link to="/admin/media/channel/add">
          <button className="btn btn-primary">채널 추가</button>
        </Link>
      </div>
      <table className="table">
        <thead>
          <tr className="text-center">
            <th>제목</th>
            <th>홈페이지</th>
            <th>구분</th>
          </tr>
        </thead>
        <tbody>
          {channels.map((channel: any) => {
            return (
              <tr key={channel.id} className="text-center">
                <td>{channel.title}</td>
                <td>{channel.show ? "예" : "아니오"}</td>
                <td>
                  <Link to={`/admin/media/channel/edit/${channel.id}`}><button>수정</button></Link>
                  <button className="ms-2" onClick={() => onDelete(channel.id)}>삭제</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>    </div>
  );
};
