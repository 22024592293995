import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import bandApi from "../../apis/band.api";
import { useAuth } from "../../context/auth_context";
import { AlertMessage } from "../../types/heerak";
import { GROUP_NAMES } from "../../utils/constants";
import Title from "../Title";
import ToastAlert from "../ToastAlert";

type NoticeType = {
  [index: string]: string | Date | FileList | Blob | null;
  importance: string;
  title: string;
  content: string;
  files: FileList | null;
};

const INIT_NOTICE = {
  importance: "off",
  title: "",
  content: "",
  files: null,
};

const BandNoticeWrite = () => {
  const { catchErrorAuth } = useAuth();
  const history = useHistory();
  const { group } = useParams<{ group: string }>();
  const match = useRouteMatch();
  const [notice, setNotice] = useState<NoticeType>(INIT_NOTICE);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  function backClick() {
    history.goBack();
  }

  useEffect(() => {
    const isNotice = Object.values({
      title: notice.title,
      content: notice.content,
    }).every((el) => Boolean(el));
    isNotice ? setDisabled(false) : setDisabled(true);
  }, [notice]);

  function handleChange(e: any) {
    const { name, value, files } = e.target;
    if (files) {
      setNotice({ ...notice, [name]: files });
    } else if (name === "importance") {
      notice[name] !== "on"
        ? setNotice({ ...notice, [name]: value })
        : setNotice({ ...notice, [name]: "off" });
    } else {
      setNotice({ ...notice, [name]: value });
    }
  }

  function handleTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const { name, value } = e.target;
    setNotice({ ...notice, [name]: value });
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");
      const formData = new FormData();
      formData.append("title", notice["title"]);
      formData.append("content", notice["content"]);
      formData.append("importance", notice["importance"]);
      formData.append("group", group);
      if (notice.files) {
        for (let index = 0; index < notice.files.length; index++) {
          const element = notice.files[index];
          formData.append("files", element);
        }
      }
      console.log("form data", JSON.stringify(formData));
      await bandApi.createBandNotice(group, formData);
      setLoading(false);
      setAlertMessage({
        message: "공지사항 등록이 완료되었습니다.",
        msgType: "success",
        closeFunction: () => setSuccess(true),
        onCancel: () => setAlertMessage(null),
      });
    } catch (error) {
      setLoading(false);
      catchErrorAuth(error, setError);
    }
  }

  if (success) {
    let path = "";
    path = match.url.replace(/\/write$/, "");
    history.push(path, { refresh: true });
  }

  return (
    <div className="container-fluid">
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <div
        className="container-fluid p-3"
        style={{
          borderTop: "1px solid #4f4f4f",
          borderBottom: "1px solid #4f4f4f",
          marginTop: "16px",
        }}
      >
        <div className="row pb-3" style={{ borderBottom: "solid 1px #d9d9d9" }}>
          <div className="col-1 d-flex align-items-center">
            <i
              className="bi bi-chevron-left"
              onClick={backClick}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="col-10 d-flex justify-content-center align-items-center fs-4">
            <b>공지사항 등록</b>
          </div>
        </div>
        <>
          <Title>{`${GROUP_NAMES.get(group)?.name} 글 작성`}</Title>
          {error && <div className="alert alert-danger">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div
              className="d-flex justify-content-between"
              style={{ marginBottom: "0.5rem" }}
            >
              <label
                htmlFor="titleInput"
                className="form-label mb-0"
                style={{ fontSize: "1.25rem" }}
              >
                제목
              </label>
            </div>
            <input
              type="text"
              className="form-control"
              id="titleInput"
              name="title"
              onChange={handleChange}
            />
            <div className="my-2">
              <label
                htmlFor="contentTextarea"
                className="form-label"
                style={{ fontSize: "1.25rem" }}
              >
                내용
              </label>
              <textarea
                className="form-control"
                id="contentTextarea"
                name="content"
                onChange={handleTextAreaChange}
                style={{ resize: "none", height: "15rem" }}
              ></textarea>
            </div>
            <div className="my-4">
              <input
                className="form-control"
                type="file"
                id="multipleFile"
                name="files"
                onChange={handleChange}
                multiple
              />
            </div>
            <div className="d-grid gap-2 my-1">
              <button
                className="btn btn-primary text-white"
                type="submit"
                disabled={disabled || loading}
              >
                등록
              </button>
            </div>
          </form>
        </>
      </div>
    </div>
  );
};

export default BandNoticeWrite;
