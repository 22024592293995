import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import userApi from "../apis/user.api";
import Title from "../components/Title";
import ToastAlert from "../components/ToastAlert";
import { useAuth } from "../context/auth_context";
import { AlertMessage, User } from "../types/heerak";
import { ChangePassword } from "./ChangePassword";
import { ProfileForm } from "./ProfileForm";

export const ProfilePage = () => {
  const { user, catchErrorAuth, logout } = useAuth();
  const history = useHistory();
  const [profile, setProfile] = useState<User | null>(null);
  const [changePassword, setChangePassword] = useState(false);
  const [isChangeProfile, setIsChangeProfile] = useState(false);
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  useEffect(() => {
    const getInfo = async () => {
      if (user) {
        try {
          const userInfo = await userApi.getUser(String(user.userId));
          console.log("user info", userInfo);
          setProfile(userInfo);
        } catch (error) {
          catchErrorAuth(error, setError);
        }
      }
    };

    getInfo();
  }, [user]);

  const deleteAccount = async (userId: string) => {
    setAlertMessage({
      message: `회원을 탈퇴하시겠습니까?`,
      msgType: "confirm",
      closeFunction: async () => {
        await userApi.removeUserById(String(userId));
        setAlertMessage(null);
        logout();
        history.replace("/signup");
      },
      onCancel: () => setAlertMessage(null),
    });
  };

  const submitProfile = async (profile: User) => {
    console.log("profile:", profile);
    try {
      const updatedUser = await userApi.updateUserById(profile.id, {
        name: profile.name,
        email: profile.email,
      });
      console.log("updated user == ", updatedUser);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const submitPassword = async (currentPassword: string, password: string) => {
    console.log("submit password", currentPassword, password);
    try {
      setPasswordError("");
      const result = await userApi.updateUserPassword(String(user?.userId), {
        currentPassword,
        password,
      });
      console.log("updated password", result);
      alert("다시 로그인 하세요");
      logout();
    } catch (error) {
      catchErrorAuth(error, setPasswordError);
    }
  };

  return (
    <div className="container">
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <Title>프로필</Title>

      <button
        type="button"
        className="btn btn-primary mb-2"
        disabled={isChangeProfile}
        onClick={() => setIsChangeProfile(true)}
      >
        프로필 수정
      </button>
      <ProfileForm
        error={error}
        profile={profile}
        isChangeProfile={isChangeProfile}
        submitProfile={submitProfile}
        setIsChangeProfile={setIsChangeProfile}
      />
      <button
        type="button"
        className="btn btn-primary mb-2"
        disabled={changePassword}
        onClick={() => setChangePassword(true)}
      >
        비밀번호 변경
      </button>
      {changePassword && (
        <ChangePassword
          serverError={passwordError}
          setChangePassword={setChangePassword}
          submitPassword={submitPassword}
        />
      )}
      <div>
        <button
          className="btn btn-primary"
          onClick={() => deleteAccount(String(user?.userId))}
        >
          회원 탈퇴
        </button>
      </div>
    </div>
  );
};
