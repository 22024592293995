import MainBanner from "../components/MainBanner";
import { ShowRoom } from "../home/ShowRoom";

const HomePage = () => {
  return (
    <>
      <MainBanner />
      <ShowRoom />
    </>
  );
};

export default HomePage;
