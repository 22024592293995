import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/Pagination";
import postApi from "../../apis/notice.api";
import { useAuth } from "../../context/auth_context";
import styles from "./noticeList-table.module.scss";
import Search from "../Search";
import ToastAlert from "../ToastAlert";
import { AlertMessage } from "../../types/heerak";
import { GROUP_NAMES } from "../../utils/constants";

type Props = { group: string };

const NoticeList = ({ group }: Props) => {
  const history = useHistory();
  const { catchErrorAuth } = useAuth();
  const [noticeList, setNoticeList] = useState<any>({
    importantNotices: [],
    notices: [],
  });
  const [error, setError] = useState("");
  const { user } = useAuth();
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);

  console.log("history obj", history);

  useEffect(() => {
    paginate(1, group);
  }, [group]);

  const paginate = async (pageNum: number, groupName: string = group) => {
    try {
      const { total, notices, importantNotices } = await postApi.readPosts(
        groupName,
        pageNum,
        5
      );
      console.log("page number", pageNum, "total", total, "notices", notices);
      setActivePage(pageNum);
      setTotalPages(total);
      setNoticeList({ notices, importantNotices });
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      paginate(activePage - 1, group);
    } else {
      paginate(1, group);
    }
  };

  const nextPage = () => {
    if (activePage < totalPages) {
      paginate(activePage + 1, group);
    } else {
      paginate(totalPages, group);
    }
  };

  function handleClick(id: any) {
    history.push(`/board/${group}/${id}`);
  }

  async function deleteNotice(e: any, id: any) {
    e.preventDefault();
    try {
      setError("");
      // await postApi.remove(group, id);
      setAlertMessage({
        message: `${GROUP_NAMES.get(group)?.name} 글을 삭제하시겠습니까?`,
        msgType: "confirm",
        closeFunction: async () => {
          await postApi.remove(group, id);
          setAlertMessage(null);
          await paginate(activePage, group);
        },
        onCancel: async () => {
          console.log("on cancel on notice list");
          setAlertMessage(null);
        },
      });
      // await getNotices();
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }

  const getSearchResults = async (
    group: string,
    category: string,
    keyword: string
  ) => {
    console.log("category", category, "keyword", keyword);
    const { count, rows } = await postApi.getSearchResults(
      group,
      category,
      keyword
    );
    console.log("search results", rows);
    return rows;
    // setNoticeList({ notices: rows });
  };

  return (
    <>
      <Search getSearchResults={getSearchResults} />
      {console.log("rendering", history)}
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      {error && <div className="alert alert-danger">{error}</div>}
      <table className={`table ${styles.tableForm}`}>
        <thead className={styles.dNone}>
          <tr className="text-white bg-MinDarkBlue">
            {/* <th className="text-center" style={{ width: "8%" }}>
              순서
            </th> */}
            <th className="text-center" style={{ width: "66%" }}>
              제목
            </th>
            <th className="text-center" style={{ width: "10%" }}>
              작성자
            </th>
            <th className="text-center" style={{ width: "16%" }}>
              작성일
            </th>
            {user && user.role === "admin" ? (
              <th className="text-center" style={{ width: "8%" }}>
                삭제
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {noticeList.importantNotices.map((notice: any, index: number) => (
            <tr key={notice.id} className={styles.Row}>
              <td
                className={`${styles.titleData}`}
                onClick={() => handleClick(notice.id)}
              >
                <span className="badge bg-danger fw-normal fs-6 me-1">
                  중요
                </span>
                {notice.title}
              </td>
              <td className={`text-center ${styles.dInLine}`}>
                {notice.user.name}
              </td>
              <td
                data-label="/"
                className={`text-center ${styles.dInLine} ${styles.data}`}
              >
                {notice.createdAt.split(/T/)[0]}
              </td>
              {user && user.role === "admin" ? (
                <td className={`text-center ${styles.dInLine}`}>
                  <i
                    className={`bi bi-x text-danger ${styles.icon}`}
                    onClick={(e) => deleteNotice(e, notice.id)}
                  ></i>
                </td>
              ) : null}
            </tr>
          ))}
          {noticeList.notices.map((notice: any, index: number) => (
            <tr key={notice.id} className={styles.Row}>
              <td
                className={`${styles.titleData}`}
                onClick={() => handleClick(notice.id)}
              >
                {notice.title}
              </td>
              <td className={`text-center ${styles.dInLine}`}>
                {notice.user.name}
              </td>
              <td
                data-label="/"
                className={`text-center ${styles.dInLine} ${styles.data}`}
              >
                {notice.createdAt.split(/T/)[0]}
              </td>
              {user && user.role === "admin" ? (
                <td className={`text-center ${styles.dInLine}`}>
                  <i
                    className={`bi bi-x text-danger ${styles.icon}`}
                    onClick={(e) => deleteNotice(e, notice.id)}
                  ></i>
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        totalPages={totalPages}
        activePage={activePage}
        prevPage={prevPage}
        nextPage={nextPage}
        paginate={paginate}
      />
    </>
  );
};

export default NoticeList;
