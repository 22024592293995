import axios from "axios";
import baseUrl from "../utils/baseUrl";

const createBibleReading = async (id: number) => {
  const { data } = await axios.post(`${baseUrl}/api/bibles/reading/${id}`);
  return data
}

const removeBibleReading = async (id: string) => {
  const { data } = await axios.delete(`${baseUrl}/api/bibles/reading/${id}`);
  return data
}

const getBibleReading = async (id: number) => {
  const { data } = await axios.get(`${baseUrl}/api/bibles/reading/${id}`);
  return data
}

const getBibleReadingAchievement = async (pageNum: number, limit: number) => {
  const payload = {
    params: {
      page: pageNum,
      limit,
    }
  }
  const { data } = await axios.get(`${baseUrl}/api/bibles/reading/achievement`, payload);
  return data
}

const updateBibleReading = async (id: number, reading: any) => {
  // const payload = {}
  const { data } = await axios.put(`${baseUrl}/api/bibles/reading/${id}`, reading);
  return data
}

const bibleApi = {
  createBibleReading,
  getBibleReading,
  getBibleReadingAchievement,
  removeBibleReading,
  updateBibleReading,
}

export default bibleApi
