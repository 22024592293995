import Title from "../Title";
import { SearchResultsList } from "./SearchResultsList";

export const SearchPage = () => {
  return (
    <div>
      <Title>검색 결과</Title>
      <SearchResultsList />
    </div>
  );
};
