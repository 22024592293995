import { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import bannerApi from "../../apis/banner.api";
import { useAuth } from "../../context/auth_context";
import { AlertMessage } from "../../types/heerak";
import { getDate } from "../../utils/clientHelper";
import CropImg from "../EditImg/CropImg";
import ToastAlert from "../ToastAlert";

const keyValues = [
  ["weekly-verse", "이주의 말씀"],
  ["family-worship", "오늘의 가정예배"],
  ["event", "행사"],
  ["other", "기타"],
] as const;

export const BANNER_KINDS = new Map<string, string>(keyValues);

type BannerType = {
  description: string;
  endDate: string;
  fileInfo: any;
  files: FileList | null;
  id: string;
  kinds: string;
  show: boolean;
  startDate: string;
  url: string;
};

const InitBanner = {
  description: "",
  endDate: "",
  fileInfo: null,
  files: null,
  id: "",
  kinds: "",
  show: false,
  startDate: "",
  url: "",
};

const BannerEdit = () => {
  const history = useHistory();
  const { bannerId } = useParams<{ bannerId: string }>();
  const [banner, setBanner] = useState<BannerType>(InitBanner);
  const [orgImgDataUrl, setOrgImgDataUrl] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [error, setError] = useState("");
  const [isShow, setIsShow] = useState(false);
  const { catchErrorAuth } = useAuth();
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  useEffect(() => {
    const fetchBanner = async () => {
      const banner = await bannerApi.readBannerById(bannerId);
      console.log("banner in edit", banner);
      setBanner(banner);
      setIsShow(banner.show);
    };

    fetchBanner();
  }, [bannerId]);

  function handleChange(e: any, src: string = "") {
    const { name, value } = e.target;
    console.log("name", name, "value", value);
    setBanner({ ...banner, [name]: value });
  }

  function handleTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const { name, value } = e.target;
    setBanner({ ...banner, [name]: value });
  }

  function handleSelectChange(e: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = e.target;
    setBanner({ ...banner, [name]: value });
  }

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, files } = e.target;
    console.log("name=", name, "files=", files);
    if (files) {
      setBanner({ ...banner, files });
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = function (event: any) {
        setOrgImgDataUrl(event.target.result);
        setIsCropping(true);
      };
    }
  }

  const handleBannerEdit = async () => {
    const blob = croppedImage ? await (await fetch(croppedImage)).blob() : null;
    const formData = new FormData();
    console.log("banner i handle banner", banner);
    banner["kinds"] && formData.append("kinds", banner["kinds"]);
    if (banner.url) {
      let trimmedUrl = banner.url;
      trimmedUrl = trimmedUrl.replace(/^\/+|http[s]?:\/\//, "");
      console.log("trimmed url", trimmedUrl);
      formData.append("url", `/${trimmedUrl}`);
    }
    banner["description"] &&
      formData.append("description", banner["description"]);
    formData.append("show", String(isShow));
    banner["startDate"] && formData.append("startDate", banner["startDate"]);
    banner["endDate"] && formData.append("endDate", banner["endDate"]);
    blob && banner.files && formData.append("file", blob, banner.files[0].name);
    try {
      const { rows: updatedBanners } = await bannerApi.updateBanner(
        banner.id,
        formData
      );
      console.log("updated banners", updatedBanners);
      // setBanners(updatedBanners);
      setAlertMessage({
        message: "배너가 수정되었습니다.",
        msgType: "success",
        closeFunction: () => {
          setAlertMessage(null);
          history.push("/admin/banner", { refresh: true });
        },
        onCancel: () => setAlertMessage(null),
      });
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  return (
    <div>
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <h4 className="my-3">배너 수정</h4>

      <div className="row mb-3">
        <div className="col-md-6">
          <select
            value={banner.kinds}
            className="form-select"
            name="kinds"
            onChange={handleSelectChange}
            aria-label="배너 종류"
          >
            <option value="notused" disabled>
              배너 종류
            </option>
            {Array.from(BANNER_KINDS).map(([key, value]) => {
              return (
                <option key={key} value={key}>
                  {value}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-6">
          <div className="form-check mt-2">
            <input
              type="checkbox"
              className="form-check-input"
              name="show"
              id="show"
              checked={isShow}
              onChange={() => setIsShow(!isShow)}
            />
            <label htmlFor="show" className="form-check-label">
              홈화면에 보이기
            </label>
          </div>
        </div>
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text" id="url-text">
          연결링크주소
        </span>
        <input
          type="text"
          className="form-control"
          id="url"
          name="url"
          onChange={handleChange}
          placeholder="URL이 필요한 경우 작성해주세요"
          value={banner.url}
          aria-describedby="url-text"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="descriptionTextarea" className={`form-label`}>
          설명
        </label>
        <textarea
          className="form-control"
          id="descriptionTextarea"
          name="description"
          value={banner.description}
          onChange={handleTextAreaChange}
        ></textarea>
      </div>
      <div className="row my-2">
        <div className="col">
          <label htmlFor="startDateInput" className="form-label">
            행사시작 날짜
          </label>
          <input
            type="date"
            className="mb-2 form-control"
            id="startDateInput"
            value={getDate(new Date(banner.startDate))}
            min={getDate(new Date())}
            name="startDate"
            onChange={handleChange}
          />
        </div>
        <div className="col">
          <label htmlFor="endDateInput" className="form-label">
            행사종료 날짜
          </label>
          <input
            type="date"
            className="mb-2 form-control"
            id="endDateInput"
            value={getDate(new Date(banner.endDate))}
            min={getDate(new Date(banner.startDate))}
            name="endDate"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="input-group mb-3">
        {/* <label htmlFor="inputImg" className="input-group-text">
          배너 그림 추가
        </label> */}
        <input
          type="file"
          className="form-control"
          id="inputImg"
          accept="image/*"
          onClick={(e: any) => {
            e.target.value = null;
          }}
          onChange={handleFileChange}
        />
      </div>

      {isCropping && (
        <CropImg
          recievedImg={orgImgDataUrl}
          handleCloseCrop={() => setIsCropping(false)}
          editImg={setCroppedImage}
          group="banner"
        />
      )}
      <div className="">
        {croppedImage ? (
          <img src={croppedImage} alt="cropped" className="img-fluid" />
        ) : (
          banner?.fileInfo?.path && (
            <img
              src={`/${banner?.fileInfo?.path}`}
              alt="cropped"
              className="img-fluid"
            />
          )
        )}
        <div className="my-3">
          <button
            className="btn btn-outline-primary"
            onClick={handleBannerEdit}
          >
            확인
          </button>
          <button
            className="btn btn-outline-primary ms-3"
            onClick={() => history.goBack()}
          >
            취소
          </button>
          {/* <button className="me-3" onClick={() => setCroppedImage(null)}>
            다시하기
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default BannerEdit;
