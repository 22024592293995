import { Link, Route, Switch, useLocation } from "react-router-dom";
import Title from "../Title";
import { WorshipsList } from "./WorshipsList";
import { WorshipAdd } from "./WorshipAdd";
import { useEffect, useState } from "react";
import worshipApi from "../../apis/worship.api";
import { WorshipType } from "../../types/heerak";

// const worships = [
//   { id: "1", name: "주일 낮 예배", time: "주일 오전 11:00" },
//   { id: "2", name: "주일 낮 예배", time: "주일 오전 12:00" },
//   { id: "3", name: "주일 낮 예배", time: "주일 오전 13:00" },
//   { id: "4", name: "주일 낮 예배", time: "주일 오전 14:00" },
//   { id: "5", name: "주일 낮 예배", time: "주일 오전 15:00" },
//   { id: "6", name: "주일 낮 예배", time: "주일 오전 16:00" },
// ];

export const Worship = () => {
  const location = useLocation<{refresh: string}>();
  const [worships, setWorships] = useState<WorshipType[]>([])

  useEffect(() => {
    getWorships()
  }, [location.state?.refresh])

  const getWorships = async () => {
    const worshipsList = await worshipApi.getWorships();
    setWorships(worshipsList)
  }

  const saveWorships = async (updatedWorships: WorshipType[]) => {
    console.log('whorships list before', updatedWorships);
    const worshipsList = await worshipApi.saveWorships(updatedWorships)
    console.log('after updated worships', worshipsList);
    setWorships(worshipsList)
  }


  return (
    <div>
      <Title>예배 시간</Title>
      {/* <Link to="/admin/worship/add">
        <button className="btn btn-primary">예배 추가</button>
      </Link> */}

      <Switch>
        <Route path="/admin/worship/add">
          <WorshipAdd />
        </Route>
        <Route>
          <WorshipsList worships={worships} saveWorships={saveWorships} />
        </Route>
      </Switch>
    </div>
  );
};
