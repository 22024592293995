import { Band } from "./Band";
import { BandLayout } from "./BandLayout";

export const BandPage = () => {
  return (
    <BandLayout>
      <Band />
    </BandLayout>
  );
};
