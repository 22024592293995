import EventsListItem from "./EventsListItem";

const EventsList = ({ events, user, removeEvent }: any) => {
  return (
    <div className="accordion accordion-flush" id="accordionFlushExample">
      {events.map((event: any) => (
        <EventsListItem key={event.id} event={event} user={user} removeEvent={removeEvent} />
      ))}
    </div>
  );
};

export default EventsList;
