import BibleList from "../components/Bible/BibleList";
import Title from "../components/Title";

const BiblePage = () => {
  return (
    <div className="row mt-4 mx-auto">
      <Title>성경 읽기</Title>
      <BibleList />
    </div>
  );
};

export default BiblePage;
