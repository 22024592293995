import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useAuth } from "../../context/auth_context";
import { FileInfo } from "../../types/heerak";
import catchErrors from "../../utils/catchErrors";
import Title from "../Title";

type Props = {
  pastor: {
    fileInfo: FileInfo;
    data: { content: string; files: FileList | null };
  };
  updateOrCreateInfo: (category: string, infoData: object) => void;
};

const SeniorPastor = ({ pastor, updateOrCreateInfo }: Props) => {
  const { user } = useAuth();
  const [error, setError] = useState("");
  const [info, setInfo] = useState(pastor.data);
  const [isEdit, setIsEdit] = useState(false);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value, files } = e.target;
    if (files) {
      setInfo({ ...info, [name]: files });
    } else {
      setInfo({ ...info, [name]: value });
    }
  }

  function handleTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  }

  function handleSubmit() {
    // e.preventDefault();
    try {
      setError("");
      const formData = new FormData();
      formData.append("content", info["content"]);
      if (info.files) {
        formData.append("file", info.files[0]);
      }
      updateOrCreateInfo("seniorpastor", formData);
      setIsEdit(!isEdit);
    } catch {
      setIsEdit(!isEdit);
      catchErrors(error, setError);
    } finally {
    }
  }

  useEffect(() => {
    console.log("pastor in senior paster", pastor);
    setInfo(pastor.data);
    setIsEdit(false);
  }, [pastor]);

  return (
    <>
      <Title>
        <div className="d-flex justify-content-between mb-2">
          인사말
          {user?.role === "admin" ? (
            isEdit ? (
              <div>
                <button
                  className="btn btn-primary text-white me-2"
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  확인
                </button>

                <button
                  className="btn btn-primary text-white"
                  onClick={() => {
                    setIsEdit(!isEdit);
                    setInfo(pastor.data);
                  }}
                >
                  취소
                </button>
              </div>
            ) : (
              <div>
              <button
                className="btn btn-primary text-white"
                onClick={() => setIsEdit(!isEdit)}
              >
                수정
              </button>
              </div>
            )
          ) : null}
        </div>
      </Title>
      {isEdit ? (
        <div className="row d-flex justify-content-space mx-2 align-items-start">
          <input
            className="form-control"
            type="file"
            accept="image/*"
            id="fileInput"
            name="files"
            onChange={handleChange}
          />

          <form className="col-12 col-lg-9 pt-2" onSubmit={handleSubmit}>
            <textarea
              className="form-control w-100 mb-5"
              id="contentInput"
              name="content"
              rows={14}
              value={info.content}
              onChange={handleTextAreaChange}
            />
            {/* <button className="btn btn-primary text-white" type="submit">
              수정
            </button> */}
          </form>
        </div>
      ) : (
        <div className="row d-flex justify-content-spave mx-2 align-items-start">
          <img
            className="col-12 col-lg-3"
            alt={`pastor review`}
            style={{ objectFit: "contain" }}
            src={`/uploads/${pastor.fileInfo?.saveName}`}
          />
          <div className="col-12 col-lg-9 pt-2">
            <p>
              {info.content.split("\n").map((line) => {
                return (
                  <span>
                    {line}
                    <br />
                  </span>
                );
              })}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SeniorPastor;
