import axios from "axios";
import { MinisterType } from "../types/heerak";
// import { MinistersType } from "../types/heerak";
import baseUrl from "../utils/baseUrl";

const addMinister = async (minister: any) => {
  const { data } = await axios.post(`${baseUrl}/api/ministers`, minister);
  return data;
};

const deleteMinisters = async (id: string) => {
  const { data } = await axios.delete(`${baseUrl}/api/ministers/${id}`);
  return data;
};

const getMinisters = async () => {
  const { data } = await axios.get(`${baseUrl}/api/ministers`);
  return data;
};

const saveMinisters = async (ministers: any[]) => {
  const { data } = await axios.put(`${baseUrl}/api/ministers`, ministers);
  return data;
};

const updateMinister = async (id: string, minister: MinisterType) => {
  const { data } = await axios.put(`${baseUrl}/api/ministers/${id}`, minister);
  return data;
};

const ministerApi = {
  addMinister,
  deleteMinisters,
  getMinisters,
  saveMinisters,
  updateMinister,
};

export default ministerApi;
