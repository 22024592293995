import { useEffect, useState } from "react";
import eventApi from "../../apis/event.api";
import styles from "./Event.module.scss";
import EventCalendar from "./EventCalendar";
import EventsList from "./EventsList";
import { useAuth } from "../../context/auth_context";
import { Route, useLocation } from "react-router-dom";
import EventEditModal from "./EventEditModal";
import { AlertMessage } from "../../types/heerak";
import ToastAlert from "../ToastAlert";

export const CalendarContainer = () => {
  const { user, catchErrorAuth } = useAuth();
  const location = useLocation<{ refresh: string }>();
  const [date, setDate] = useState(new Date());
  const [eventsList, setEventsList] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [error, setError] = useState("");
  const color = ["#6FCBFE", "#F2B705"];
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  useEffect(() => {
    getEvent();
  }, [date, location.state?.refresh]);

  async function getEvent() {
    try {
      setError("");
      const year = date.getFullYear();
      const month = date.getMonth();
      const events = await eventApi.read(year, month, 0);
      let calendars: any = [];
      events.forEach((event: any, index: number) => {
        let calendar = { start: 0, end: 0, color: "", title: event.title };
        calendar.start = event.startDate;
        calendar.color = color[index % 2];
        calendar.end = new Date(event.endDate).setDate(
          new Date(event.endDate).getDate() + 1
        );
        event.startDate = event.startDate.split(/T/)[0];
        event.endDate = event.endDate.split(/T/)[0];
        event.startDay = event.startDate.split(/-/)[2];
        calendars.push(calendar);
      });
      setCalendarEvents(calendars);
      console.log("events", events);
      setEventsList(events);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }
  async function removeEvent(eventId: any) {
    try {
      setError("");
      setAlertMessage({
        message: "행사일정 수정이 완료되었습니다.",
        msgType: "confirm",
        closeFunction: async () => {
          const response = await eventApi.remove(eventId);
          console.log(response);
          setAlertMessage(null);
          await getEvent();
        },
        onCancel: () => setAlertMessage(null),
      });
      // const response = await eventApi.remove(eventId);
      // console.log(response);
      // getEvent();
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }
  return (
    <div className="row mx-0 pt-5 justify-content-between">
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
          style={{zIndex: 4}}
        />
      )}
      <div
        className={`col-xl-7 pe-4 d-flex align-items-start justify-content-center ${styles.eventBox}`}
        >
        <EventCalendar calendarEvents={calendarEvents} setDate={setDate} />
      </div>
      <div className={`col-xl-5 ps-4 ${styles.line}  ${styles.eventBox}`}>
        <EventsList events={eventsList} user={user} removeEvent={removeEvent} />
      </div>
      <Route path="/event/edit/:eventId">
        <EventEditModal />
      </Route>
    </div>
  );
};
