import { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import mediaApi from "../../apis/media.api";
import { useAuth } from "../../context/auth_context";
import { AlertMessage } from "../../types/heerak";
import Title from "../Title";
import ToastAlert from "../ToastAlert";

export const AddVideo = () => {
  const { catchErrorAuth } = useAuth();
  const history = useHistory();
  const [video, setVideo] = useState({ kind: "", url: "" });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  function handleTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const { name, value } = e.target;
    console.log(`name: ${name}, value: ${value}`);
    setVideo({ ...video, [name]: value });
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setVideo({ ...video, [name]: checked });
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    console.log(`name: ${name}, value: ${value}`);
    setVideo({ ...video, [name]: value });
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    if (!video.kind) {
      return setAlertMessage({
        message: "영상 구분을 선택해 주세요",
        msgType: "error",
        closeFunction: () => setAlertMessage(null),
        onCancel: () => setAlertMessage(null),
      });
    }
    if (video.url) {
      try {
        setLoading(true);
        setError("");

        console.log("video submit", video);
        await mediaApi.postVideo(video);

        setLoading(false);
        setAlertMessage({
          message: "동영상 추가가 완료되었습니다.",
          msgType: "success",
          closeFunction: () =>
            history.push("/admin/media/video", { refresh: true }),
        onCancel: () => setAlertMessage(null),
      });
      } catch (error) {
        setLoading(false);
        catchErrorAuth(error, setError);
      }
    } else {
      setAlertMessage({
        message: "올바른 형식의 주소를 입력하세요",
        msgType: "error",
        closeFunction: () => setAlertMessage(null),
        onCancel: () => setAlertMessage(null),
      });
    }
  }

  return (
    <div>
      <Title>
        <h5 className="mt-5">동영상 추가</h5>
      </Title>
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}>
        <textarea
          name="url"
          className="form-control my-4"
          placeholder="동영상 주소를 입력하세요"
          id="url"
          value={video.url}
          style={{ resize: "none" }}
          onChange={handleTextAreaChange}
        ></textarea>
        <div className="row">
          <div className="col-md-6">
            <select
              className="form-select"
              defaultValue=""
              aria-label="video select"
              name="kind"
              onChange={handleSelect}
            >
              <option selected>영상 구분</option>
              <option value="heerak">희락</option>
              <option value="church">교회</option>
            </select>
          </div>
          <div className="col-md-6">
            <div className="form-check mt-2">
              <input
                type="checkbox"
                className="form-check-input"
                name="show"
                id="show"
                onChange={handleInputChange}
              />
              <label htmlFor="show" className="form-check-label">
                홈화면에 보이기
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex mt-4">
          <button className="btn btn-primary me-2" type="submit">
            등록
          </button>
          <button className="btn btn-primary" type="button" onClick={() => history.goBack()}>
            취소
          </button>
        </div>
      </form>
    </div>
  );
};
