import { MediaList } from "../components/Media";
import Pagination from "../components/Pagination";

const VideoPage = () => {
  return (
    <div className="row mt-4 mx-auto">
      <MediaList />
      <Pagination />
    </div>
  );
};

export default VideoPage;
