import BibleReadingModal from "./BibleReadingModal";
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { Modal } from "bootstrap";
import Pagination from "../Pagination";
import bibleApi from "../../apis/bible.api";
import { useAuth } from "../../context/auth_context";
import { AlertMessage } from "../../types/heerak";
import ToastAlert from "../ToastAlert";

const BibleList = () => {
  const { user, catchErrorAuth } = useAuth();
  const [bibleAchievementList, setBibleAchievementList] = useState<any[]>([]);
  const [error, setError] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  const [reading, setReading] = useState<{ [key: string]: number[] } | null>(
    null
  );
  const [modalTitle, setModalTitle] = useState("");
  const [isJoined, setIsJoined] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);
  const modalUserId = useRef<number>();
  const modal = useRef<Modal | null>();

  useEffect(() => {
    paginate(1);
  }, []);

  useEffect(() => {
    if (modalRef.current) {
      modal.current = Modal.getInstance(modalRef.current);
      if (!modal.current) {
        modal.current = new Modal(modalRef.current);
      }
    }
  });

  const deleteReading = async (userId: string) => {
    console.log("delete reading: ", userId);
    setAlertMessage({
      message: "삭제하시겠습니까?",
      msgType: "confirm",
      closeFunction: async () => {
        const result = await bibleApi.removeBibleReading(userId);
        console.log("deleted result", result);
        const newlyUpdated = bibleAchievementList.filter(
          (achivement) => achivement.user.id !== userId
        );
        setBibleAchievementList(newlyUpdated);
        setIsJoined(false)
        setAlertMessage(null);
      },
      onCancel: () => setAlertMessage(null),
    });
  };

  const handleVerse = async (e: ChangeEvent<HTMLInputElement>) => {
    const { id: bookVerseStr, checked } = e.target;
    const bookVerse = bookVerseStr.match(/(\p{L}+)(\d+)/u);

    if (bookVerse) {
      console.log(bookVerse[1], bookVerse[2]);
      const bookName = bookVerse[1];
      const verseNum = Number(bookVerse[2]);
      if (!reading) {
        // TEST용
        modalUserId.current &&
          (await bibleApi.updateBibleReading(modalUserId.current, {
            [bookName]: [verseNum],
          }));
        // TEST용
        setReading({ [bookName]: [verseNum] });
      } else {
        let verseArr = reading.hasOwnProperty(bookName)
          ? reading[bookName]
          : [];
        if (checked) {
          verseArr = [...verseArr, verseNum];
        } else {
          verseArr = verseArr.filter((verse) => verse !== verseNum);
        }
        console.log("verse array", verseArr);
        // TEST용
        modalUserId.current &&
          (await bibleApi.updateBibleReading(modalUserId.current, {
            [bookName]: verseArr,
          }));
        // TEST용
        setReading({ ...reading, [bookName]: [...verseArr] });
      }
      console.log("reading state in handle verse", reading);
    }
  };

  const handleClose = () => {
    paginate(activePage);
    modal.current?.hide();
  };

  const handleModal = async (e: MouseEvent<HTMLButtonElement>) => {
    modalUserId.current = Number(e.currentTarget.id);

    setModalTitle(`${e.currentTarget.name}님의 성경 읽기표`);
    const readings = await bibleApi.getBibleReading(modalUserId.current);
    setReading(readings);
  };

  const handleCreateReading = async (e: any) => {
    try {
      if (user) {
        const newReadingAchievement = await bibleApi.createBibleReading(
          user.userId
        );
        console.log("new reading", newReadingAchievement);
        setBibleAchievementList([
          ...bibleAchievementList,
          newReadingAchievement,
        ]);
        setIsJoined(true);
      } else {
        alert("로그인 및 교인등록이 필요합니다");
      }
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const paginate = async (pageNum: number) => {
    const { total, readingAchievements } =
      await bibleApi.getBibleReadingAchievement(pageNum, 2);
    console.log(
      "page number",
      pageNum,
      "total",
      total,
      "achievements",
      readingAchievements
    );
    setActivePage(pageNum);
    setTotalPages(total);
    setBibleAchievementList(readingAchievements);
    readingAchievements.forEach((achievement: any) => {
      if (achievement.user.id === user?.userId) {
        return setIsJoined(true);
      }
    });
  };

  const prevPage = () => {
    if (activePage > 1) {
      paginate(activePage - 1);
    } else {
      paginate(1);
    }
  };

  const nextPage = () => {
    if (activePage < totalPages) {
      paginate(activePage + 1);
    } else {
      paginate(totalPages);
    }
  };

  return (
    <>
      {error && <div className="alert alert-danger">{error}</div>}
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <table className="table table-hover" style={{ tableLayout: "fixed" }}>
        <thead>
          <tr className="text-white align-middle bg-MinDarkBlue">
            <th className="text-center " style={{ width: "10%" }}>
              순서
            </th>
            <th className="text-center " style={{ width: "10%" }}>
              이름
            </th>
            <th className="text-center " style={{ width: "50%" }}>
              진행상황
            </th>
            <th className="text-center " style={{ width: "10%" }}>
              상세보기
            </th>
            <th className="text-center " style={{ width: "15%" }}>
              시작일
            </th>
            <th className="text-center " style={{ width: "10%" }}>
              완독수
            </th>
          </tr>
        </thead>
        <tbody>
          {bibleAchievementList.map((bible: any, index: any) => {
            return (
              <tr
                key={bible.id}
                className={
                  index < 3 ? "bg-LotusSky align-middle" : "align-middle"
                }
              >
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{bible.user.name}</td>
                <td>
                  <div className="progress align-items-center">
                    <div
                      className="progress-bar  bg-primary"
                      style={{
                        width: `${Math.floor((100 * bible.total) / 1189)}%`,
                        height: "2rem",
                      }}
                      role="progressbar"
                      aria-valuemin={0}
                      aria-valuemax={100}
                      aria-valuenow={80}
                    >
                      {bible.total >= 180 ? (
                        <span className="">
                          {Math.floor((100 * bible.total) / 1189)}% (
                          {bible.total}/1189)
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="progress-bar bg-secondary"
                      style={{
                        width: `${
                          100 - Math.floor((100 * bible.total) / 1189)
                        }%`,
                        height: "2rem",
                      }}
                      role="progressbar"
                      aria-valuemin={0}
                      aria-valuemax={100}
                      aria-valuenow={80}
                    >
                      {bible.total < 180 ? (
                        <span className="">
                          {Math.floor((100 * bible.total) / 1189)}% (
                          {bible.total}/1189)
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </td>
                <td className="text-center ">
                  <button
                    type="button"
                    className="btn btn-sm btn-Gray"
                    data-bs-toggle="modal"
                    data-bs-target="#bibleModal"
                    name={`${bible.user.name}`}
                    id={`${bible.user.id}`}
                    onClick={handleModal}
                  >
                    +
                  </button>
                </td>
                <td className="text-center">{bible.createdAt.split("T")[0]}</td>
                <td className="text-center">
                  {bible.repetition}회{" "}
                  {bible.user.id === user?.userId && <i
                    className="ms-1 bi bi-x-square"
                    onClick={() => deleteReading(bible.user.id)}
                  ></i>}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {!isJoined && (
        <button
          type="submit"
          className="btn btn-secondary"
          onClick={handleCreateReading}
        >
          새로 시작하기
        </button>
      )}
      <Pagination
        totalPages={totalPages}
        activePage={activePage}
        prevPage={prevPage}
        nextPage={nextPage}
        paginate={paginate}
      />
      <BibleReadingModal
        ref={modalRef}
        reading={reading}
        modalTitle={modalTitle}
        handleVerse={handleVerse}
        handleClose={handleClose}
        isOwner={modalUserId.current === user?.userId}
      />
    </>
  );
};

export default BibleList;
