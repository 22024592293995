import { FormEvent, useEffect, useRef, useState } from "react";
import { Modal } from "bootstrap";
import eventApi from "../../apis/event.api";
import { useAuth } from "../../context/auth_context";
import { useHistory, useParams } from "react-router-dom";
import { getDate } from "../../utils/clientHelper";
import ToastAlert from "../ToastAlert";
import { AlertMessage } from "../../types/heerak";

type ChurchEventType = {
  id: string;
  title: string;
  content: string;
  place: string;
  phone: string;
  startDate: string;
  endDate: string;
};

const InitEvent = {
  id: "",
  title: "",
  content: "",
  place: "",
  phone: "",
  startDate: "",
  endDate: "",
};

const EventEditModal = () => {
  const { catchErrorAuth } = useAuth();
  const { eventId } = useParams<{ eventId: string }>();
  const history = useHistory();
  const [event, setEvent] = useState<ChurchEventType>(InitEvent);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  const modalElement = useRef<HTMLDivElement>(null);
  const eventModal = useRef<Modal | null>();

  async function getEventById(id: string) {
    const event = await eventApi.readById(id);
    console.log("event in event edit modal", event);
    setEvent(event);
  }

  useEffect(() => {
    getEventById(eventId);
  }, [eventId]);

  useEffect(() => {
    if (modalElement.current) {
      eventModal.current = Modal.getInstance(modalElement.current);
      if (!eventModal.current) {
        eventModal.current = new Modal(modalElement.current);
      }
      eventModal.current?.show();
    }
  }, []);

  function handleChange(e: any) {
    const { name, value } = e.target;
    setEvent({ ...event, [name]: value });
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");
      await eventApi.update(
        Number(event.id),
        event.title,
        event.content,
        event.place,
        event.phone,
        event.startDate,
        event.endDate
      );
      setLoading(false);
      setAlertMessage({
        message: "행사일정 수정이 완료되었습니다.",
        msgType: "success",
        closeFunction: () => {
          eventModal.current?.hide();
          history.push("/event", { refresh: true });
        },
        onCancel: () => setAlertMessage(null),
      });
    } catch (error) {
      setLoading(false);
      catchErrorAuth(error, setError);
      eventModal.current?.hide();
    }
  }

  const onClose = () => {
    eventModal.current?.hide();
    history.goBack();
  };

  return (
    <div
      ref={modalElement}
      className="modal fade vh-100"
      id={eventId}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          {alertMessage?.message && (
            <ToastAlert
              message={alertMessage.message}
              msgType={alertMessage.msgType}
              closeFunction={alertMessage.closeFunction}
              onCancel={alertMessage.onCancel}
            />
          )}
          <div className="modal-header">
            <h5 className="modal-title px-2" id="exampleModalLabel">
              행사 수정
            </h5>
            <button
              type="button"
              className="btn-close"
              // data-bs-dismiss="modal"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="modal-body row row-cols-1 row-cols-lg-2 h-50">
            <form className="w-100" onSubmit={handleSubmit}>
              <div className="mb-2">
                <label
                  htmlFor="titleInput"
                  className="form-label"
                  style={{ fontSize: "1.25rem" }}
                >
                  행사 제목
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="titleInput"
                  name="title"
                  value={event.title}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="contentInput"
                  className="form-label"
                  style={{ fontSize: "1.25rem" }}
                >
                  행사 내용
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contentInput"
                  name="content"
                  value={event.content}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="placeInput"
                  className="form-label"
                  style={{ fontSize: "1.25rem" }}
                >
                  행사 장소
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="placeInput"
                  name="place"
                  value={event.place}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="phoneInput"
                  className="form-label"
                  style={{ fontSize: "1.25rem" }}
                >
                  문의 번호
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phoneInput"
                  name="phone"
                  value={event.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="dateInput"
                  className="form-label"
                  style={{ fontSize: "1.25rem" }}
                >
                  행사 기간
                </label>
                <div className="row px-4">
                  <div className="col">
                    <label
                      htmlFor="startDateInput"
                      className="form-label"
                      style={{ fontSize: "1rem" }}
                    >
                      시작 날짜
                    </label>
                    <input
                      type="date"
                      className="mb-2 form-control"
                      style={{ width: "250px" }}
                      id="startDateInput"
                      value={getDate(new Date(event.startDate))}
                      // min={String(new Date().toJSON()).split(/T/)[0]}
                      name="startDate"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="endDateInput"
                      className="form-label"
                      style={{ fontSize: "1rem" }}
                    >
                      종료 날짜
                    </label>
                    <input
                      type="date"
                      className="mb-2 form-control"
                      style={{ width: "250px" }}
                      id="endDateInput"
                      value={getDate(new Date(event.endDate))}
                      min={getDate(new Date(event.startDate))}
                      name="endDate"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="d-grid gap-2 my-1">
                <button
                  className="btn btn-primary text-white"
                  type="submit"
                  disabled={loading}
                >
                  등록
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventEditModal;
