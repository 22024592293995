import worshipApi from "../../apis/worship.api";
import { WorshipType } from "../../types/heerak";
import styles from "./Menu.module.css";

type Params = {
  worships: WorshipType[];
};

const WorshipTimetable = ({ worships = [] }: Params) => {
  return (
    <div>
      <table className="w-100">
        <thead>
          <tr>
            <th className={styles.worshipTimeTableTh}>예배</th>
            <th className={styles.worshipTimeTableTh}>시간</th>
          </tr>
        </thead>
        <tbody>
          {worships.map((worship) => (
            <tr key={worship.id}>
              <td className={styles.worshipTimeTableTd}>{worship.name}</td>
              <td className={styles.worshipTimeTableTd}>{worship.time}</td>
            </tr>
          ))}
          {/* <tr>
            <td className={styles.worshipTimeTableTd}>주일 낮 예배</td>
            <td className={styles.worshipTimeTableTd}>주일 오전 11:00</td>
          </tr>
          <tr>
            <td className={styles.worshipTimeTableTd}>주일 찬양 예배</td>
            <td className={styles.worshipTimeTableTd}>주일 오후 02:30</td>
          </tr>
          <tr>
            <td className={styles.worshipTimeTableTd}>수요 예배</td>
            <td className={styles.worshipTimeTableTd}>수요일 저녁 07:30</td>
          </tr>
          <tr>
            <td className={styles.worshipTimeTableTd}>새벽 예배</td>
            <td className={styles.worshipTimeTableTd}>매일 새벽 05:00</td>
          </tr>
          <tr>
            <td className={styles.worshipTimeTableTd}>교육부 통합 예배</td>
            <td className={styles.worshipTimeTableTd}>주일 오후 01:00</td>
          </tr>
          <tr>
            <td className={styles.worshipTimeTableTd}>청년부 모임</td>
            <td className={styles.worshipTimeTableTd}>토요일 오후 05:00</td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default WorshipTimetable;
