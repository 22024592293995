import { HTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

type PropsTypes = {
  children: ReactNode;
};

interface PageItemProps extends HTMLAttributes<HTMLElement> {
  active?: boolean;
  disabled?: boolean;
  activeLabel?: string;
}

const PaginationBoot = ({ children }: PropsTypes) => {
  return (
    <nav aria-label="page navigation">
      <ul className="pagination justify-content-center py-3">{children}</ul>
    </nav>
  );
};

const PageItem = ({
  active = false,
  disabled = false,
  className,
  style,
  activeLabel = "(current)",
  children,
  ...props
}: PageItemProps) => {
  return (
    <li
      style={style}
      className={classNames(className, "page-item", { disabled, active })}
    >
      <Link to="#" className="page-link" {...props}>
        {children}
      </Link>
    </li>
  );
};

const createButton = (name: string, defaultValue: ReactNode, label = name) => {
  function Button({ children, ...props }: PageItemProps) {
    return (
      <PageItem {...props}>
        <span aria-hidden="true">{children || defaultValue}</span>
        <span className="visually-hidden">{label}</span>
      </PageItem>
    );
  }

  Button.displayName = name;
  return Button;
};

PaginationBoot.First = createButton("First", "«");
PaginationBoot.Prev = createButton("Prev", "‹");
PaginationBoot.Item = PageItem;
PaginationBoot.Next = createButton("Next", "›");
PaginationBoot.Last = createButton("Last", "»");

export default PaginationBoot;
