import { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import bannerApi from "../../apis/banner.api";
import { useAuth } from "../../context/auth_context";
import { AlertMessage } from "../../types/heerak";
import { getDate } from "../../utils/clientHelper";
import CropImg from "../EditImg/CropImg";
import ToastAlert from "../ToastAlert";

type BannerType = {
  show: boolean;
  endDate: string;
  files: FileList | null;
  startDate: string;
};

const keyValues = [
  ["weekly-verse", "이주의 말씀"],
  ["family-worship", "오늘의 가정예배"],
  ["event", "행사"],
  ["other", "기타"],
] as const;

export const BANNER_KINDS = new Map(keyValues);

const BannerAdd = () => {
  const history = useHistory();
  const { catchErrorAuth } = useAuth();
  const [error, setError] = useState("");
  const [banner, setBanner] = useState<BannerType>({
    show: false,
    files: null,
    startDate: getDate(new Date()),
    endDate: getDate(new Date()),
  });
  const [orgImgDataUrl, setOrgImgDataUrl] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value, checked } = e.target;
    if (name === "show") {
      return setBanner({ ...banner, [name]: checked });
    }
    setBanner({ ...banner, [name]: value });
  }

  function handleTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const { name, value } = e.target;
    setBanner({ ...banner, [name]: value });
  }

  function handleSelectChange(e: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = e.target;
    setBanner({ ...banner, [name]: value });
  }

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, files, value } = e.target;
    console.log("name=", name, "files=", files);
    if (files?.length && files.length > 0) {
      setBanner({ ...banner, files });
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = function (event: any) {
        setOrgImgDataUrl(event.target.result);
        setIsCropping(true);
      };
    }
    if (!value) {
      console.log("file이 없을 때?", files);
      setCroppedImage(null);
    }
  }

  const submitBanner = async (blob: Blob, banner: any) => {
    console.log("add banner", banner);
    const formData = new FormData();
    banner["kinds"] && formData.append("kinds", banner["kinds"]);
    if (banner.url) {
      formData.append("url", `/${banner["url"]}`);
    }
    banner["description"] &&
      formData.append("description", banner["description"]);
    banner["startDate"] && formData.append("startDate", banner["startDate"]);
    banner["endDate"] && formData.append("endDate", banner["endDate"]);
    banner["show"] && formData.append("show", banner["show"]);
    formData.append("file", blob, banner.files[0].name);
    try {
      await bannerApi.submit(formData);
      // history.push("/admin/banner", { refresh: true });
      setAlertMessage({
        message: "새로운 배너가 등록되었습니다",
        msgType: "success",
        closeFunction: () => history.push("/admin/banner", { refresh: true }),
        onCancel: () => setAlertMessage(null),
      });
      // alert("새로운 배너가 등록되었습니다");
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const addBanner = async () => {
    const blob = await (await fetch(croppedImage)).blob();
    console.log("blob", blob);
    submitBanner(blob, banner);
  };

  return (
    <div>
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <h4 className="my-3">배너 추가</h4>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="row mb-3">
        <div className="col-md-6">
          <select
            defaultValue="notused"
            className="form-select"
            name="kinds"
            onChange={handleSelectChange}
            aria-label="배너 종류"
          >
            <option value="notused" disabled>
              배너 종류
            </option>
            {Array.from(BANNER_KINDS).map(([key, value]) => {
              return (
                <option key={key} value={key}>
                  {value}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-6">
          <div className="form-check mt-2">
            <input
              type="checkbox"
              className="form-check-input"
              name="show"
              id="show"
              onChange={handleChange}
            />
            <label htmlFor="show" className="form-check-label">
              홈화면에 보이기
            </label>
          </div>
        </div>
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text " id="url-text">
          연결링크주소
        </span>
        <input
          type="text"
          className="form-control"
          id="url"
          name="url"
          onChange={handleChange}
          placeholder="URL이 필요한 경우 작성해주세요"
          aria-describedby="url-text"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="descriptionTextarea" className={`form-label`}>
          설명
        </label>
        <textarea
          className="form-control"
          id="descriptionTextarea"
          name="description"
          onChange={handleTextAreaChange}
        ></textarea>
      </div>
      <div className="row my-2">
        <div className="col">
          <label htmlFor="startDateInput" className="form-label">
            행사시작 날짜
          </label>
          <input
            type="date"
            className="mb-2 form-control"
            id="startDateInput"
            value={banner.startDate}
            min={String(new Date().toJSON()).split(/T/)[0]}
            name="startDate"
            onChange={handleChange}
          />
        </div>
        <div className="col">
          <label htmlFor="endDateInput" className="form-label">
            행사종료 날짜
          </label>
          <input
            type="date"
            className="mb-2 form-control"
            id="endDateInput"
            value={banner.endDate}
            min={banner.startDate}
            name="endDate"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="input-group mb-3">
        {/* <label htmlFor="inputImg" className="input-group-text">
          배너 이미지 추가
        </label> */}
        <input
          type="file"
          className="form-control"
          id="inputImg"
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
      {isCropping && (
        <CropImg
          recievedImg={orgImgDataUrl}
          handleCloseCrop={() => setIsCropping(false)}
          editImg={setCroppedImage}
          group="banner"
        />
      )}
      <div className="">
        {croppedImage && (
          <img src={croppedImage} alt="cropped" className="img-fluid" />
        )}
        <div className="my-3">
          <button
            className="btn btn-outline-primary me-3"
            onClick={() => setCroppedImage(null)}
            disabled={!croppedImage}
          >
            다시하기
          </button>
          <button
            className="btn btn-outline-primary me-3"
            disabled={!croppedImage}
            onClick={addBanner}
          >
            등록하기
          </button>
          <button
            className="btn btn-outline-primary"
            onClick={() => history.goBack()}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default BannerAdd;
