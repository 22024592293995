import { useHistory, useLocation } from "react-router-dom";
import { getDate } from "../../utils/clientHelper";
import Title from "../Title";

export const SearchResultsList = () => {
  const location = useLocation<{ results: any[], from: string, keyword: string }>();
  const history = useHistory();
  const { results, from, keyword } = location.state;
  console.log("results : ", results);
  console.log("from : ", from);
  console.log("location : ", location);

  return (
    <div>
      <p>검색어: <span className="fw-bold">{keyword}</span></p>
      <table className={`table`}>
        <thead className="">
          <tr className="text-white bg-MinDarkBlue">
            <th className="text-center" style={{ width: "58%" }}>
              제목
            </th>
            <th className="text-center" style={{ width: "10%" }}>
              작성자
            </th>
            <th className="text-center" style={{ width: "16%" }}>
              작성일
            </th>
          </tr>
        </thead>
        <tbody>
          {results.map((notice: any, index: number) => (
            <tr key={notice.id} className="text-center">
              <td className="" onClick={() => history.push(`${from}/${notice.id}`)}>
                {notice.title}
              </td>
              <td className={`text-center`}>{notice.user.name}</td>
              <td data-label="/" className={`text-center`}>
                {getDate(new Date(notice.createdAt))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
