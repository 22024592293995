import { AlertMessage, MinisterType } from "../../types/heerak";
import { MinisterItem } from "./MinisterItem";
import { useAuth } from "../../context/auth_context";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ToastAlert from "../ToastAlert";
import ministerApi from "../../apis/minister.api";

type MembersParams = {
  ministers: MinisterType[];
  // deleteMinister: Function;
  saveMinisters: Function;
};

export const MinistersList = ({
  ministers,
  // deleteMinister,
  saveMinisters,
}: MembersParams) => {
  const { user } = useAuth();
  const history = useHistory();
  const [localMembers, setLocalMembers] = useState<MinisterType[]>([]);
  const [selectedId, setSelectedId] = useState("");
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  useEffect(() => {
    setLocalMembers([...ministers]);
  }, [ministers]);

  const deleteMinister = async (id: string) => {
    console.log("delete minister", id);
    setAlertMessage({
      message: "삭제 하시겠습니까?",
      msgType: "confirm",
      closeFunction: async () => {
        await ministerApi.deleteMinisters(id)
        setAlertMessage(null)
        history.replace("/info/minister", {refresh: id})
      },
      onCancel: () => setAlertMessage(null)
    })
  };

  const onCancel = () => {
    setLocalMembers([...ministers]);
  };

  const moveDown = (id: string) => {
    const index = localMembers.findIndex((member) => member.id === id);
    if (index + 1 >= localMembers.length || !id) {
      return;
    }
    const temp = localMembers[index + 1];
    localMembers[index + 1] = localMembers[index];
    localMembers[index] = temp;
    setLocalMembers([...localMembers]);
  };

  const moveUp = (id: string) => {
    const index = localMembers.findIndex((member) => member.id === id);
    if (index - 1 < 0 || !id) {
      return;
    }
    const temp = localMembers[index - 1];
    localMembers[index - 1] = localMembers[index];
    localMembers[index] = temp;
    setLocalMembers([...localMembers]);
  };

  const updateMinister = async (minister: MinisterType) => {
    console.log("update ministers", minister);
    const index = localMembers.findIndex((item) => item.id === minister.id);
    localMembers[index] = minister;
    setLocalMembers([...localMembers]);
  };

  return (
    <div className="">
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}

      {user?.role === "admin" && (
        <div className="d-flex justify-content-between mb-2">
          <div>
            <button
              className="btn btn-primary me-2"
              onClick={() => moveUp(selectedId)}
            >
              <i className="bi bi-arrow-up"></i>위로
            </button>
            <button
              className="btn btn-primary me-2"
              onClick={() => moveDown(selectedId)}
            >
              <i className="bi bi-arrow-down"></i>아래로
            </button>
            <button
              className="btn btn-primary me-2"
              onClick={() => saveMinisters(localMembers)}
            >
              <i className="bi bi-save me-1"></i>저장하기
            </button>
            <button className="btn btn-primary me-2" onClick={() => onCancel()}>
              <i className="bi bi-x-square me-1"></i>취소
            </button>
          </div>
          <Link to="/info/minister/add">
            <button className="btn btn-primary">
              <i className="bi bi-plus"></i>추가
            </button>
          </Link>
        </div>
      )}

      <div className="table-responsive">
        <table className="table text-center">
          <thead>
            <tr className="text-white bg-MinDarkBlue">
              <th className="col-5">직분</th>
              <th className="col-5">이름</th>
              {user?.role === "admin" ? (
                <th className="col-2">수정/삭제</th>
              ) : null}
            </tr>
          </thead>

          <tbody>
            {localMembers.length > 0 &&
              localMembers.map((minister: MinisterType) => {
                return (
                  <tr
                    key={minister.id}
                    onClick={() => setSelectedId(minister.id)}
                    className={`text-center ${
                      selectedId === minister.id ? "bg-secondary" : null
                    }`}
                  >
                    <MinisterItem
                      key={minister.id}
                      minister={minister}
                      deleteMinister={deleteMinister}
                      updateMinister={updateMinister}
                    />
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
