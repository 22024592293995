import { FC } from "react"

const Layout:FC = ({children}) => {
  return (
    <div className='container'>
      {children}
    </div>
  )
}

export default Layout
