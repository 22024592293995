import { BandBoardCard } from "./BandBoardCard";
import { BandBoardSearchBox } from "./BandBoardSearchBox";
import { GROUP_NAMES } from "../../utils/constants";
import { useAuth } from "../../context/auth_context";

export const BandBoardHome = ({
  group,
  getSearchResults,
  writePost,
  postList,
  setSelectedPost,
}: any) => {
  const { user } = useAuth();

  return (
    <>
      <div className="container-fluid">
        <div
          className="row justify-content-center align-items-center"
          style={{ height: "8rem", borderBottom: "1px solid #4f4f4f" }}
        >
          <div className="col-8">
            <h3>
              <b>HEERAK BAND STORY</b>
            </h3>
            <h6>희락 {GROUP_NAMES.get(group)?.name} 자유게시판 입니다.</h6>
          </div>
        </div>
        <div className="row justify-content-between mt-3">
          <div className="col-8">
            <BandBoardSearchBox getSearchResults={getSearchResults} />
          </div>
          <div className="col-auto">
            <button
              type="button"
              className={`btn btn-primary btn-md ${user ? null : "d-none"}`}
              onClick={writePost}
            >
              글 등록
            </button>
          </div>
        </div>
        <div className="row row-cols-2 row-cols-md-3 m-3 py-1">
          {postList.map((post: any, index: any) => (
            <div key={post.id} className="col p-1">
              <BandBoardCard post={post} setSelectedPost={setSelectedPost} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
