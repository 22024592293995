import axios from "axios";
import { User } from "../types/heerak";
import baseUrl from "../utils/baseUrl";

const createChurchMember = async (member: any) => {
  const { data } = await axios.post(`${baseUrl}/api/members`, member);
  return data;
};

const findMembers = async (params: { name: string; email: string }) => {
  const payload = {
    params: {
      name: params.name,
      email: params.email,
    },
  };
  const { data } = await axios.get(`${baseUrl}/api/members/search`, payload);
  return data;
};

const getAllRoles = async () => {
  const { data } = await axios.get(`${baseUrl}/api/users/roles`);
  return data;
};

const getChurchMembers = async () => {
  const { data } = await axios.get(`${baseUrl}/api/members`);
  return data;
};

const getUser = async (id: string) => {
  const { data } = await axios.get(`${baseUrl}/api/users/${id}`);
  return data;
};

const getUsers = async () => {
  const { data } = await axios.get(`${baseUrl}/api/users`);
  return data;
};

const removeUser = async (userId: string) => {
  const payload = {
    params: {
      userId,
    },
  };
  const { data } = await axios.delete(`${baseUrl}/api/users/`, payload);
  return data;
};

const removeUserById = async (userId: string) => {
  const { data } = await axios.delete(`${baseUrl}/api/users/${userId}`);
  return data;
}

const updateMemberStatus = async (userId: string, isMember: boolean) => {
  const payload = {
    isMember,
  };
  const { data } = await axios.put(`${baseUrl}/api/members/${userId}`, payload);
  return data;
};

const updateUser = async (user: User) => {
  const { data } = await axios.put(`${baseUrl}/api/users/`, user);
  return data;
};

const updateUserById = async (userId: string, user: Partial<User>) => {
  const { data } = await axios.put(`${baseUrl}/api/users/${userId}`, user);
  return data;
};

const updateUserPassword = async (
  userId: string,
  payload: { currentPassword: string; password: string }
) => {
  const { data } = await axios.put(
    `${baseUrl}/api/users/${userId}/password`,
    payload
  );
  return data;
};

const userApi = {
  createChurchMember,
  getChurchMembers,
  getAllRoles,
  getUser,
  getUsers,
  findMembers,
  removeUser,
  removeUserById,
  updateMemberStatus,
  updateUser,
  updateUserById,
  updateUserPassword,
};

export default userApi;
