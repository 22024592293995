import { FormEvent, useState, useEffect, useRef } from "react";
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { Modal } from "bootstrap";
import BandPrayCardsList from "./BandPrayCardsList";
import BandPrayList from "./BandPrayList";
import bandApi from "../../apis/band.api";
import { GROUP_NAMES } from "../../utils/constants";
import { getDate } from "../../utils/clientHelper";
import { useAuth } from "../../context/auth_context";
import ToastAlert from "../ToastAlert";
import { AlertMessage } from "../../types/heerak";

const LEAST_PRAYCARD_NUM = 4;

const INIT_PRAY = {
  id: 1,
  title: "",
  endDate: getDate(new Date()),
  importance: false,
  content: "",
  visible: "yes",
  group: "",
};

type PrayType = {
  id: number;
  title: string;
  endDate: string;
  importance: boolean;
  content: string;
  visible: string;
  group: string;
};

interface ParamTypes {
  group: string;
}

const BandPray = () => {
  const { catchErrorAuth, user } = useAuth();
  const history = useHistory();
  const match = useRouteMatch<any>();
  const { group } = useParams<ParamTypes>();

  const modalRef = useRef<HTMLDivElement>(null);
  const checkBoxRef = useRef<HTMLInputElement>(null);
  const writeMode = useRef("write");

  const [data, setData] = useState(INIT_PRAY);

  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [importantPrays, setImportantPrays] = useState<any>([]);
  const [normalPrays, setNormalPrays] = useState<any>([]);
  const [finishedPrays, setFinishedPrays] = useState<any>([]);

  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  useEffect(() => {
    getCards();
  }, []);

  const getCards = async () => {
    const { importantPrays, normalPrays, finishedPrays } =
      await bandApi.groupPrayRead(group, LEAST_PRAYCARD_NUM);
    setImportantPrays(importantPrays);
    setNormalPrays(normalPrays);
    setFinishedPrays(finishedPrays);
  };

  useEffect(() => {
    const isData = Object.values(data).every(
      (el) => typeof el == "boolean" || Boolean(el)
    );
    isData ? setDisabled(false) : setDisabled(true);

    if (checkBoxRef.current) {
      checkBoxRef.current.checked = data.importance;
    }
  }, [data]);

  function handleListShow() {
    history.push(`${match.url}/list`);
  }

  function handleChange(e: any) {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });

    if (name === "importance") setData({ ...data, [name]: !data.importance });
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");

      if (writeMode.current === "write") {
        const res = await bandApi.createBandPray(data);
        getCards();

        setAlertMessage({
          message: "기도제목 등록이 완료되었습니다.",
          msgType: "success",
          closeFunction: () => setAlertMessage(null),
          onCancel: () => setAlertMessage(null),
        });
      } else if (writeMode.current === "edit") {
        const res = await bandApi.groupPrayEdit(data.id, data);
        getCards();

        setAlertMessage({
          message: "기도제목 수정이 완료되었습니다.",
          msgType: "success",
          closeFunction: () => setAlertMessage(null),
          onCancel: () => setAlertMessage(null),
        });
      }
      setLoading(false);

      if (modalRef.current) {
        const modal = Modal.getInstance(modalRef.current);
        modal?.hide();
      }
    } catch (error) {
      setLoading(false);
      catchErrorAuth(error, setError);
    }
  }

  function setWriteMode(mode: string) {
    writeMode.current = mode;
  }

  async function deletePray(id: any) {
    try {
      setError("");
      setLoading(true);

      setAlertMessage({
        message: "기도제목을 삭제하시겠습니까?",
        msgType: "confirm",
        closeFunction: async () => {
          try {
            await bandApi.groupPrayRemove(id);
            getCards();
            setAlertMessage(null);
          } catch (error) {
            setAlertMessage(null);
          }
        },
        onCancel: () => setAlertMessage(null),
      });
    } catch (error) {
      catchErrorAuth(error, setError);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/list`}>
          <BandPrayList match={match} />
        </Route>
        <Route>
          <>
            <div className="card bg-dark text-white" style={{ height: "8rem" }}>
              <div className="card-img-overlay">
                <h3 className="card-title">기도제목</h3>
                <p className="card-text">
                  {GROUP_NAMES.get(group)?.name} 기도제목을 전해주세요.
                </p>
              </div>
            </div>
            {alertMessage?.message && (
              <ToastAlert
                message={alertMessage.message}
                msgType={alertMessage.msgType}
                closeFunction={alertMessage.closeFunction}
                onCancel={alertMessage.onCancel}
              />
            )}
            <div className="container-fluid py-3 mt-3 border-top border-dark">
              <div className="row">
                <div className="col-md-9 py-2">
                  <BandPrayCardsList
                    cards={{ importantPrays, normalPrays, finishedPrays }}
                    setData={setData}
                    deletePray={deletePray}
                    setWriteMode={setWriteMode}
                  />
                </div>
                <div className="col-md-3 py-2">
                  <div className="row row-cols-2 row-cols-md-1">
                    <div className="col">
                      <button
                        className={`btn card card-body fs-5 mx-auto ${
                          user ? null : "disabled"
                        }`}
                        data-bs-toggle="modal"
                        data-bs-target="#prayModal"
                        onClick={() => {
                          setWriteMode("write");
                          setData({ ...INIT_PRAY, ["group"]: group });
                        }}
                      >
                        기도제목 쓰기
                      </button>
                    </div>
                    <div className="col">
                      <button
                        className="btn card card-body mt-3 mx-auto fs-5"
                        onClick={handleListShow}
                      >
                        지난 기도제목
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <PrayModal
                modalRef={modalRef}
                checkBoxRef={checkBoxRef}
                pray={data}
                disabled={disabled}
                loading={loading}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </div>
          </>
        </Route>
      </Switch>
    </div>
  );
};

type modalProps = {
  modalRef: any;
  checkBoxRef: any;
  pray: PrayType;
  disabled: boolean;
  loading: boolean;
  handleChange: any;
  handleSubmit: any;
};

const PrayModal = ({
  modalRef,
  checkBoxRef,
  pray,
  disabled,
  loading,
  handleChange,
  handleSubmit,
}: modalProps) => {
  return (
    <div
      ref={modalRef}
      className="modal fade"
      id="prayModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              기도 제목 등록
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="row">
                <label className="col-2 col-form-label">제목</label>
                <div className="col-10">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="제목을 입력해주세요."
                    name="title"
                    value={pray.title}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row my-2 d-flex align-item-center">
                <label className="col-2 col-form-label">기한</label>
                <div className="col-6">
                  <input
                    type="date"
                    className="form-control"
                    name="endDate"
                    value={getDate(new Date(pray.endDate))}
                    min={INIT_PRAY.endDate}
                    onChange={handleChange}
                  />
                </div>
                <label className="col-2 col-form-label">중요</label>
                <div className="col-2 form-check form-switch d-flex align-items-center">
                  <input
                    ref={checkBoxRef}
                    className="form-check-input"
                    type="checkbox"
                    id="prayModalCheck"
                    name="importance"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-floating my-2">
                <textarea
                  className="form-control"
                  id="floatingTextarea2"
                  style={{ height: "30vh" }}
                  name="content"
                  value={pray.content}
                  onChange={handleChange}
                ></textarea>
                <label>기도 제목 상세 내용</label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={disabled || loading}
              >
                등록
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                취소
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BandPray;
