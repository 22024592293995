import axios from "axios";
import baseUrl from "../utils/baseUrl";

const createBandBoard = async (formData: any) => {
  const { data } = await axios.post(`${baseUrl}/api/band-boards`, formData);
  return data;
};

const createBandPray = async (prayItems: any): Promise<any> => {
  const { data } = await axios.post(`${baseUrl}/api/band-prays`, prayItems);
  return data;
};    

const createBandNotice = async (group: string, formData: any) => {
  const { data } = await axios.post(`${baseUrl}/api/band-notices/${group}`, formData);
  return data;
};

const editBandBoard = async (group: string, id: string, formData: any) => {
  const { data } = await axios.put(`${baseUrl}/api/band-boards/${group}/${id}`, formData);
  return data;
};

const editBandNotice = async (group: string, id: string, formData: any) => {
  const { data } = await axios.put(`${baseUrl}/api/band-notices/${group}/${id}`, formData);
  return data;
};

const getSearchResults = async (bandKind:string, group:string, category: string, keyword: string) => {
  const payload = {
    params: { category, group, keyword },
  };
  const { data } = await axios.get(`${baseUrl}/api/${bandKind}/search`, payload);
  return data;
};

const groupPrayRead = async (group: string, num: Number) => {
  const res = await axios.get(`${baseUrl}/api/band-prays?group=${group}`, {
    params: {
      num,
    },  
  });  
  return res.data;
};  

const groupFinishedPrayRead = async (group: string) => {
  const res = await axios.get(
    `${baseUrl}/api/band-prays/finishedPrays?group=${group}`
  );  
  return res.data;
};  

const groupPrayEdit = async (id: any, prayItems: any) => {
  const res = await axios.put(`${baseUrl}/api/band-prays/${id}`, prayItems);
  return res.data;
};  

const groupPrayRemove = async (id: string) => {
  const res = await axios.delete(`${baseUrl}/api/band-prays/${id}`);
  return res.data;
};  

const postReadPreviousOne = async (id: any) => {
  const res = await axios({
    method: "GET",
    url: `${baseUrl}/api/fileinfos/${id}`,
    responseType: "blob",
  });
  console.log("res.data==", res.data, "res===", res);
  return res.data;
};

const readBandBoard = async (group: string, page: number, limit: number) => {
  const payload = {
    params: {
      page,
      limit,
    },
  };
  const { data } = await axios.get(
    `${baseUrl}/api/band-boards/${group}`,
    payload
  );
  return data;
};

const readBandBoardById = async (group: string, id: string) => {
  const { data } = await axios.get(`${baseUrl}/api/band-boards/${group}/${id}`);
  return data;
};

const readBandNotice = async (group: string, page: number, limit: number) => {
  const payload = {
    params: {
      page,
      limit,
    },
  };
  const { data } = await axios.get(
    `${baseUrl}/api/band-notices/${group}`,
    payload
  );
  return data;
};

const readBandNoticeById = async (group: string, id: string) => {
  const { data } = await axios.get(`${baseUrl}/api/band-notices/${group}/${id}`);
  return data;
};


const removeBandBoard = async (group: string, id: string) => {
  const { data } = await axios.delete(`${baseUrl}/api/band-boards/${group}/${id}`);
  return data;
};

const removeBandNotice = async (group: string, id: string) => {
  const { data } = await axios.delete(`${baseUrl}/api/band-notices/${group}/${id}`);
  return data;
};

const bandApi = {
  createBandBoard,
  createBandPray,
  createBandNotice,
  editBandBoard,
  editBandNotice,
  getSearchResults,
  groupFinishedPrayRead,
  groupPrayEdit,
  groupPrayRead,
  groupPrayRemove,
  postReadPreviousOne,
  readBandBoard,
  readBandBoardById,
  readBandNoticeById,
  readBandNotice,
  removeBandBoard,
  removeBandNotice,
};

export default bandApi;
