import { Link } from "react-router-dom";

const VideoList = ({ videos, onDelete }: any) => {
  return (
    <>
      <div className="d-flex mt-5 justify-content-between">
        <h5 className="">동영상 리스트</h5>
        <Link to="/admin/media/video/add">
          <button className="btn btn-primary">동영상 추가</button>
        </Link>
      </div>
      <table className="table">
        <thead>
          <tr className="text-center">
            <th>제목</th>
            <th>홈화면</th>
            <th>구분</th>
            <th>수정/삭제</th>
          </tr>
        </thead>
        <tbody>
          {videos.map((video: any) => {
            return (
              <tr key={video.id} className="text-center">
                <td>{video.title}</td>
                <td>{video.show ? "예" : "아니오"}</td>
                <td>{video.kind}</td>
                <td>
                  <Link to={`/admin/media/video/edit/${video.id}`}><button>수정</button></Link>
                  <button onClick={() => onDelete(video.id)}>삭제</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default VideoList;
