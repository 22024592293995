import axios from "axios";
import baseUrl from "../utils/baseUrl";

const createComment = async (comment: any) => {
  const { data } = await axios.post(`${baseUrl}/api/comments`, comment);
  return data;
};

// const editComment = async (comment: any) => {
//   const updatedComment = await axios.put(`${baseUrl}/api/comments`, comment);
//   return updatedComment;
// };

const getComments = async (
  group: string,
  bandBoardId: string,
  page: number,
  limit: number = 5
) => {
  const payload = {
    params: {
      bandBoardId,
      group,
      limit,
      page,
    },
  };
  const { data } = await axios.get(`${baseUrl}/api/comments`, payload);
  return data;
};

const removeComment = async (id: string) => {
  const { data } = await axios.delete(`${baseUrl}/api/comments/${id}`);
  return data;
};

const commentApi = { createComment, getComments, removeComment };

export default commentApi;
