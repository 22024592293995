import { FormEvent, useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Title from ".././Title";
import eventApi from "../../apis/event.api";
import { getDate } from "../../utils/clientHelper";
import { useAuth } from "../../context/auth_context";
import ToastAlert from "../ToastAlert";
import { AlertMessage } from "../../types/heerak";

const INIT_DATA = {
  title: "",
  content: "",
  place: "",
  phone: "",
  startDate: getDate(new Date()),
  endDate: getDate(new Date()),
};

const EventWrite = () => {
  const { catchErrorAuth } = useAuth();
  const history = useHistory();
  const [data, setData] = useState(INIT_DATA);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  useEffect(() => {
    const isData = Object.values(data.title).every((el) => !Boolean(el));
    isData ? setDisabled(true) : setDisabled(false);
  }, [data]);

  function handleChange(e: any) {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (!Object.values(data).every((el) => Boolean(el))) {
      if (
        window.confirm(
          "정보가 모두 입력되지않았습니다. 행사를 등록하시겠습니까?"
        )
      ) {
      } else {
        return;
      }
    }
    try {
      setLoading(true);
      setError("");
      const response = await eventApi.create(
        data.title,
        data.content,
        data.place,
        data.phone,
        data.startDate,
        data.endDate
      );
      console.log(response);
      setData(INIT_DATA);
      setAlertMessage({
        message: "행사일정 등록이 완료되었습니다.",
        msgType: "success",
        closeFunction: () => {
          setSuccess(true);
        },
        onCancel: () => setAlertMessage(null),
      });
    } catch (error) {
      catchErrorAuth(error, setError);
    } finally {
      setLoading(false);
    }
  }

  if (success) {
    return <Redirect to="/event" />;
  }

  return (
    <>
      <Title>행사 등록</Title>
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-2">
          <label
            htmlFor="titleInput"
            className="form-label"
            style={{ fontSize: "1.25rem" }}
          >
            행사 제목
          </label>
          <input
            type="text"
            className="form-control"
            id="titleInput"
            name="title"
            onChange={handleChange}
          />
        </div>
        <div className="mb-2">
          <label
            htmlFor="placeInput"
            className="form-label"
            style={{ fontSize: "1.25rem" }}
          >
            행사 장소
          </label>
          <input
            type="text"
            className="form-control"
            id="placeInput"
            name="place"
            onChange={handleChange}
          />
        </div>
        <div className="mb-2">
          <label
            htmlFor="contentInput"
            className="form-label"
            style={{ fontSize: "1.25rem" }}
          >
            행사 내용
          </label>
          <input
            type="text"
            className="form-control"
            id="contentInput"
            name="content"
            onChange={handleChange}
          />
        </div>
        <div className="mb-2">
          <label
            htmlFor="phoneInput"
            className="form-label"
            style={{ fontSize: "1.25rem" }}
          >
            문의 번호
          </label>
          <input
            type="text"
            className="form-control"
            id="phoneInput"
            name="phone"
            onChange={handleChange}
          />
        </div>
        <div className="mb-2">
          <label
            htmlFor="dateInput"
            className="form-label"
            style={{ fontSize: "1.25rem" }}
          >
            행사 기간
          </label>
          <div className="row px-4">
            <div className="col">
              <label
                htmlFor="startDateInput"
                className="form-label"
                style={{ fontSize: "1rem" }}
              >
                시작 날짜
              </label>
              <input
                type="date"
                className="mb-2 form-control"
                style={{ width: "250px" }}
                id="startDateInput"
                value={data.startDate}
                min={INIT_DATA.startDate}
                name="startDate"
                onChange={handleChange}
              />
            </div>
            <div className="col">
              <label
                htmlFor="endDateInput"
                className="form-label"
                style={{ fontSize: "1rem" }}
              >
                종료 날짜
              </label>
              <input
                type="date"
                className="mb-2 form-control"
                style={{ width: "250px" }}
                id="endDateInput"
                value={data.endDate}
                min={data.startDate}
                name="endDate"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="d-flex my-1">
          <button
            className="btn btn-primary text-white me-2"
            type="submit"
            disabled={disabled || loading}
          >
            등록
          </button>
          <button
            className="btn btn-primary text-white"
            type="button"
            onClick={() => history.goBack()}
          >
            취소
          </button>
        </div>
      </form>
    </>
  );
};

export default EventWrite;
