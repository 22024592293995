import { Link } from "react-router-dom";

export const VideoComponent = ({
  churchVideoList,
  heerakVideoList,
  channelList,
  mainVideos,
}: any) => {
  return (
    <div className="col-12 col-md-12">
      <div className="card m-3">
        <div className="card-header ps-5 text-center">
          동영상
          <Link
            to="/video"
            className="card-link text-decoration-none"
            style={{ float: "right", color: "gray" }}
          >
            더보기 +
          </Link>
        </div>
        <div className="card-body p-0">
          <div className="row p-1">
            <div className="col-12 col-md-4">
              <p className="mt-3 mb-0 mt-md-1 mb-md-1">희락</p>
              <div className="d-flex flex-wrap ">
                {heerakVideoList.map((video: any) => (
                  <a
                    className="col-12 flex-grow-1 col-md-6 p-1"
                    href={video.url}
                  >
                    <img
                      className="img-fluid"
                      // style={{ height: "100%" }}
                      src={video.thumbnail}
                      alt=""
                    />
                  </a>
                ))}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <p className="mt-3 mb-0 mt-md-1 mb-md-1">외부 링크</p>
              <div className="d-flex flex-wrap ">
                {churchVideoList.map((video: any) => (
                  <a
                    className="col-12 flex-grow-1 col-md-6 p-1"
                    href={video.url}
                  >
                    <img
                      className="img-fluid"
                      // style={{ height: "100%" }}
                      src={video.thumbnail}
                      alt=""
                    />
                  </a>
                ))}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <p className="mt-3 mb-0 mt-md-1 mb-md-1">기독 채널</p>
              <div className="d-flex flex-wrap">
                {channelList.map((channel: any, index: number) => {
                  return (
                    <div key={index} className="col-6">
                      <a href={channel.url}>
                        <img
                          style={{ borderRadius: 130, blockSize: 50 }}
                          className="img-fluid pt-1"
                          src={channel.thumbnail}
                          alt=""
                        />
                      </a>
                      <a href={channel.url} style={{ textDecoration: "none" }}>
                        <div className="text-dark mt-1">{channel.title}</div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
