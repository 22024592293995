import { FC } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth_context";

const BaseLayout: FC = ({ children }) => {
  const { logout } = useAuth();

  return (
    <>
      <header className="navbar navbar-dark bg-primary flex-md-nowrap p-0 shadow">
        <Link className={`navbar-brand col-md-3 col-lg-2 me-0 px-3`} to="/home">
          희락교회
        </Link>
        <ul className="navbar-nav px-3">
          <Link className="text-white text-decoration-none" to="/login">
            <li className="nav-item text-nowrap" onClick={logout}>
              <i className="bi bi-box-arrow-right me-1"></i>로그아웃
            </li>
          </Link>
        </ul>
        <button
          className={`navbar-toggler d-md-none collapsed`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#adminSidebarMenu"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </header>
      {children}
    </>
  );
};

export default BaseLayout;
