import { useEffect, useState } from "react";
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import Pagination from "../Pagination";
import BandNoticeCard from "./BandNoticeCard";
import BandNoticePost from "./BandNoticePost";
import BandNoticeWrite from "./BandNoticeWrite";
import BandNoticeEdit from "./BandNoticeEdit";
import { GROUP_NAMES } from "../../utils/constants";
import bandApi from "../../apis/band.api";
import { useAuth } from "../../context/auth_context";
import Search from "../Search";

const BandNotice = () => {
  const { catchErrorAuth, user } = useAuth();
  const { group } = useParams<{ group: string }>();
  const match = useRouteMatch();
  const history: any = useHistory();
  const [noticeList, setNoticeList] = useState<any>([]);
  const [error, setError] = useState("");

  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const paginate = async (pageNum: number, groupName: string = group) => {
    try {
      const { total, notices } = await bandApi.readBandNotice(
        groupName,
        pageNum,
        5
      );
      console.log("page number", pageNum, "total", total, "notices", notices);
      setActivePage(pageNum);
      setTotalPages(total);
      setNoticeList(notices);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  useEffect(() => {
    paginate(1, group);
  }, [group, history.location.state?.refresh]);

  const prevPage = () => {
    if (activePage > 1) {
      console.log("group in pre page", group);
      paginate(activePage - 1, group);
    } else {
      paginate(1, group);
    }
  };

  const nextPage = () => {
    console.log("group in next page", group);
    if (activePage < totalPages) {
      paginate(activePage + 1, group);
    } else {
      paginate(totalPages, group);
    }
  };

  const getSearchResults = async (group: string, category: string, keyword: string) => {
    const { count, rows } = await bandApi.getSearchResults("band-notices", group, category, keyword);
    return rows;
  };

  return (
    <div>
      <div className="card bg-primary text-white" style={{ height: "8rem" }}>
        <div className="card-img-overlay">
          <h3 className="card-title">공지사항</h3>
          <p className="card-text">
            {GROUP_NAMES.get(group)?.name} 공지가 올라옵니다.{" "}
          </p>
        </div>
      </div>
      <Switch>
        <Route path={`${match.path}/write`}>
          <BandNoticeWrite />
        </Route>
        <Route path={`${match.path}/edit/:noticeId`}>
          <BandNoticeEdit />
        </Route>
        <Route path={`${match.path}/:noticeId`}>
          <BandNoticePost match={match} />
        </Route>
        <Route>
          <div className="container-fluid">
            <div
              className="container-fluid py-3"
              style={{
                borderTop: "1px solid #4f4f4f",
                borderBottom: "1px solid #4f4f4f",
                marginTop: "16px",
              }}
            >
              <Search getSearchResults={getSearchResults} />
              <div className="row row-cols-1 row-cols-md-3 my-3 justify-content-center">
                <BandNoticeCard noticeList={noticeList} />
              </div>
            </div>
            <div className="row justify-content-end align-items-center">
              <div className="col-4">
                <Pagination
                  totalPages={totalPages}
                  activePage={activePage}
                  prevPage={prevPage}
                  nextPage={nextPage}
                  paginate={paginate}
                />
              </div>
              <div className="col-4 d-flex justify-content-end mx-2">
                <Link
                  to={`/band/${group}/notice/write`}
                  type="button"
                  className={`btn btn-primary btn-md ${user ? null : "d-none"}`}
                >
                  글 등록
                </Link>
              </div>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default BandNotice;
