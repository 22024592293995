import { useEffect, useState } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import mediaApi from "../../apis/media.api";
import { useAuth } from "../../context/auth_context";
import Title from "../Title";
import { AddChannel } from "./AddChannel";
import { AddVideo } from "./AddVideo";
import { ChannelList } from "./ChannelList";
import { EditChannel } from "./EditChannel";
import { EditVideo } from "./EditVideo";
import VideoList from "./VideoList";

export const Media = () => {
  const { catchErrorAuth } = useAuth();
  const location = useLocation<{ refresh: boolean }>();
  const [videos, setVideos] = useState([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [error, setError] = useState("");

  useEffect(() => {
    getAllVideos();
    getChannels();
  }, [location.state?.refresh]);

  const getAllVideos = async () => {
    try {
      const videos = await mediaApi.getAllVideos();
      console.log("videos", videos);
      setVideos(videos);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const getChannels = async () => {
    try {
      const channels = await mediaApi.getChannels();
      setChannels(channels);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const removeVideo = async (id: string) => {
    await mediaApi.removeVideo(id);
    await getAllVideos();
  };

  const removeChannel = async (id: string) => {
    await mediaApi.removeChannel(id);
    await getChannels();
  };

  return (
    <div>
      <Title>미디어 관리</Title>
      {error && <div className="alert alert-danger">{error}</div>}
      <ul className="list-group list-group-horizontal">
        <Link className="text-decoration-none" to="/admin/media/video">
          <li className="list-group-item border-0 border-end">동영상</li>
        </Link>
        <Link className="text-decoration-none" to="/admin/media/channel">
          <li className="list-group-item border-0">채널</li>
        </Link>
      </ul>

      <Switch>
        <Route path="/admin/media/video/add">
          <AddVideo />
        </Route>
        <Route path="/admin/media/video/edit/:videoId">
          <EditVideo />
        </Route>
        <Route path="/admin/media/video">
          <VideoList videos={videos} onDelete={removeVideo} />
        </Route>
        <Route path="/admin/media/channel/add">
          <AddChannel />
        </Route>
        <Route path="/admin/media/channel/edit/:channelId">
          <EditChannel />
        </Route>
        <Route path="/admin/media/channel">
          <ChannelList channels={channels} onDelete={removeChannel} />
        </Route>
        <Route>
          <VideoList videos={videos} onDelete={removeVideo} />
        </Route>
      </Switch>
    </div>
  );
};
