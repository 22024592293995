import { CalendarContainer } from "../components/Event";

const EventPage = () => {
  return (
    <div className="row mt-4 mx-auto pb-5">
      <h2 className="border-bottom border-2 border-dark pb-2 my-4">행사</h2>
      <CalendarContainer />
    </div>
  );
};

export default EventPage;
