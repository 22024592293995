import { FormEvent, ChangeEvent, useState, useEffect } from "react";
import {
  Redirect,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import Title from "./Title";
import postApi from "../apis/notice.api";
import { GROUP_NAMES } from "../utils/constants";
import { useAuth } from "../context/auth_context";
import ToastAlert from "./ToastAlert";
import { AlertMessage } from "../types/heerak";

interface ParamTypes {
  group: string;
}

type NoticeType = {
  [index: string]: string | Date | FileList | Blob | null;
  importance: string;
  title: string;
  content: string;
  files: FileList | null;
};

const INIT_NOTICE = {
  importance: "off",
  title: "",
  content: "",
  files: null,
};

const NoticeWrite = () => {
  const { group } = useParams<ParamTypes>();
  const { catchErrorAuth } = useAuth();
  const match = useRouteMatch();
  const history = useHistory();
  console.log("notice write match ==== ", match);

  const [notice, setNotice] = useState<NoticeType>(INIT_NOTICE);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  useEffect(() => {
    const isNotice = Object.values({ title: notice.title }).every((el) =>
      Boolean(el)
    );
    isNotice ? setDisabled(false) : setDisabled(true);
  }, [notice]);

  function handleChange(e: any) {
    const { name, value, files } = e.target;
    if (files) {
      setNotice({ ...notice, [name]: files });
    } else if (name === "importance") {
      notice[name] !== "on"
        ? setNotice({ ...notice, [name]: value })
        : setNotice({ ...notice, [name]: "off" });
    } else {
      setNotice({ ...notice, [name]: value });
    }
  }

  function handleTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const { name, value } = e.target;
    setNotice({ ...notice, [name]: value });
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");
      const formData = new FormData();
      formData.append("title", notice["title"]);
      formData.append("content", notice["content"]);
      formData.append("importance", notice["importance"]);
      formData.append("group", group);
      if (notice.files) {
        for (let index = 0; index < notice.files.length; index++) {
          const element = notice.files[index];
          formData.append("file", element);
        }
      }
      await postApi.create(group, formData);
      setLoading(false);
      setAlertMessage({
        message: `${GROUP_NAMES.get(group)?.name} 등록이 완료되었습니다.`,
        msgType: "success",
        closeFunction: () => setSuccess(true),
        onCancel: () => setAlertMessage(null),
      });
    } catch (error) {
      setLoading(false);
      catchErrorAuth(error, setError);
    }
  }

  if (success) {
    let path = "";
    if (["other", "notice"].includes(group)) {
      path = `/board/${group}`;
    } else {
      path = match.url.replace(/\/write$/, "");
    }
    history.push(path, { refresh: true });
    // return <Redirect to={path} />;
  }

  return (
    <>
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <Title>{`${GROUP_NAMES.get(group)?.name} 글 작성`}</Title>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div
          className="d-flex justify-content-between"
          style={{ marginBottom: "0.5rem" }}
        >
          <label
            htmlFor="titleInput"
            className="form-label mb-0"
            style={{ fontSize: "1.25rem" }}
          >
            제목
          </label>
          {group === "notice" ? (
            <div className="d-flex align-items-center form-check form-switch mb-0">
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                중요
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  name="importance"
                  onChange={handleChange}
                />
              </label>
            </div>
          ) : null}
        </div>
        <input
          type="text"
          className="form-control"
          id="titleInput"
          name="title"
          onChange={handleChange}
        />
        <div className="my-2">
          <label
            htmlFor="contentTextarea"
            className="form-label"
            style={{ fontSize: "1.25rem" }}
          >
            내용
          </label>
          <textarea
            className="form-control"
            id="contentTextarea"
            name="content"
            onChange={handleTextAreaChange}
            style={{ resize: "none", height: "15rem" }}
          ></textarea>
        </div>
        <div className="my-4">
          <input
            className="form-control"
            type="file"
            id="multipleFile"
            name="files"
            onChange={handleChange}
            multiple
          />
        </div>
        <div className="d-grid gap-2 my-1">
          <button
            className="btn btn-primary text-white"
            type="submit"
            disabled={disabled || loading}
          >
            등록
          </button>
        </div>
      </form>
    </>
  );
};

export default NoticeWrite;
