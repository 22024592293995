import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";

const BandNoticeCard = ({ noticeList }: any) => {
  const history = useHistory();
  const match = useRouteMatch();

  function handleClick(id: number) {
    history.push(`${match.url}/${id}`);
  }

  return (
    <>
      {noticeList.map((card: any, index: any) => (
        <div key={card.id} className="col">
          <div
            className="card my-1 p-2 text-decoration-none"
            onClick={() => handleClick(card.id)}
            style={{ height: "13rem", cursor: "pointer" }}
          >
            <div className="card-body">
              <h5 className="card-title d-flex nowrap">
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {card.title}
                </span>
                <span
                  className={
                    card.attachments.length > 0 ? "visible" : "invisible"
                  }
                >
                  <i className="bi bi-paperclip" />
                </span>
              </h5>
              <h6 className="card-subtitle mb-2 text-muted">
                {card.user.name} {card.createdAt.split(/T/)[0]}
              </h6>
              <p
                className="card-text"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {card.content}
              </p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default BandNoticeCard;
