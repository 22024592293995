import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../context/auth_context";

const INIT_USER = {
  email: "",
  password: "",
};

const Login = () => {
  const { error, loading, login } = useAuth();
  const [user, setUser] = useState(INIT_USER);
  const [disabled, setDisabled] = useState(true);
  // const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const isUser = Object.values(user).every((el) => Boolean(el));
      isUser ? setDisabled(false) : setDisabled(true);
    }
    return () => {
      isMounted = false;
    };
  }, [user]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    try {
      // setLoading(true);
      // setError("");
      const success = await login(user.email, user.password);
      setSuccess(success);
    } catch (error) {
      // catchErrors(error, setError);
    } finally {
      // setLoading(false);
    }
  }

  if (success) {
    return <Redirect to="/home" />;
  }

  const { email, password } = user;

  return (
    <div className="row align-items-center justify-content-center m-5">
      <div
        className="border col-xl-5 col-lg-6 col-md-8 col-sm-11 col-12 p-4"
        style={{ minWidth: "max-content" }}
      >
        <h1 className="my-3 pb-3 text-MinDarkBlue text-center">로그인</h1>
        {error && <div className="alert alert-danger">{error}</div>}
        {loading && <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>}
        <form onSubmit={handleSubmit}>
          <div className="row mb-4">
            <label htmlFor="email" className="col-sm-4 col-form-labell">
              이메일
            </label>
            <div className="col-sm-8">
              <input
                className="form-control"
                id="email"
                type="email"
                name="email"
                placeholder="이메일을 입력하세요"
                value={email}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row mb-4">
            <label className="col-sm-4 col-form-label">비밀번호</label>
            <div className="col-sm-8">
              <input
                className="form-control"
                name="password"
                type="password"
                placeholder="비밀번호를 입력하세요"
                value={password}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="d-grid">
            <button
              disabled={disabled || loading}
              type="submit"
              className="my-2 btn btn-MinDarkBlue"
            >
              확인
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
