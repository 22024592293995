import { useEffect, useState } from "react";
import { AlertMessage, WorshipType } from "../../types/heerak";
import { WorshipItem } from "./WorshipItem";
import worshipApi from "../../apis/worship.api";
import { Link, useHistory } from "react-router-dom";
import ToastAlert from "../ToastAlert";

type TimetableProps = {
  worships: WorshipType[];
  saveWorships: Function;
};

export const WorshipsList = ({ worships, saveWorships }: TimetableProps) => {
  const history = useHistory();
  const [worshipsList, setWorshipsList] = useState<WorshipType[]>([]);
  const [selectedId, setSelectedId] = useState("");
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  useEffect(() => {
    console.log("use effect", worships);
    setWorshipsList([...worships]);
  }, [worships]);

  const cancel = () => {
    console.log("cancel worships", worships);
    setWorshipsList([...worships]);
  };

  const deleteWorship = async (id: string) => {
    console.log("delete worship id", id);
    setAlertMessage({
      message: "삭제하시겠습니까?",
      msgType: "confirm",
      closeFunction: async () => {
        const deletedWorship = await worshipApi.deleteWorship(id);
        console.log("deleted worship", deletedWorship);
        setAlertMessage(null);
        history.replace("/admin/worship", { refresh: id });
      },
      onCancel: () => setAlertMessage(null),
    });
  };

  const moveDown = (id: string) => {
    const index = worshipsList.findIndex((worship) => worship.id === id);
    if (index + 1 >= worshipsList.length || !id) {
      return;
    }
    const temp = worshipsList[index + 1];
    worshipsList[index + 1] = worshipsList[index];
    worshipsList[index] = temp;
    setWorshipsList([...worshipsList]);
  };

  const moveUp = (id: string) => {
    const index = worshipsList.findIndex((worship) => worship.id === id);
    if (index - 1 < 0 || !id) {
      return;
    }
    const temp = worshipsList[index - 1];
    worshipsList[index - 1] = worshipsList[index];
    worshipsList[index] = temp;
    setWorshipsList([...worshipsList]);
  };

  const updateWorship = async (worship: WorshipType) => {
    console.log("update worship", worship);
    const index = worshipsList.findIndex((item) => item.id === worship.id);
    worshipsList[index] = worship;
    setWorshipsList([...worshipsList]);
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        {alertMessage?.message && (
          <ToastAlert
            message={alertMessage.message}
            msgType={alertMessage.msgType}
            closeFunction={alertMessage.closeFunction}
            onCancel={alertMessage.onCancel}
          />
        )}
        <div>
          <button
            className="btn btn-primary me-2"
            onClick={() => moveUp(selectedId)}
          >
            <i className="bi bi-arrow-up"></i>위로
          </button>
          <button
            className="btn btn-primary me-2"
            onClick={() => moveDown(selectedId)}
          >
            <i className="bi bi-arrow-down"></i>아래로
          </button>
          <button
            className="btn btn-primary me-2"
            onClick={() => saveWorships(worshipsList)}
          >
            <i className="bi bi-save me-1"></i>저장하기
          </button>
          <button className="btn btn-primary" onClick={() => cancel()}>
            <i className="bi bi-x-square me-1"></i>취소
          </button>
        </div>
        <Link to="/admin/worship/add">
          <button className="btn btn-primary">
            <i className="bi bi-plus"></i>예배 추가
          </button>
        </Link>
      </div>

      <table className="table">
        <thead>
          <tr className="text-center">
            <th>예배 이름</th>
            <th>예배 시간</th>
            <th>작업</th>
          </tr>
        </thead>
        <tbody>
          {worshipsList.map((worship) => (
            <tr
              key={worship.id}
              onClick={() => setSelectedId(worship.id)}
              className={`text-center ${
                selectedId === worship.id ? "bg-secondary" : null
              }`}
            >
              <WorshipItem
                worship={worship}
                deleteWorship={deleteWorship}
                updateWorship={updateWorship}
              />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
