import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import EventWrite from "../Event/EventWrite";
import { Media } from "../Media";
import Banner from "../Banner";
import MemberManager from "../Member/MemberManager";
import { Worship } from "./Worship";

export const AdminHome = () => {
  const match = useRouteMatch();
  console.log("match in AdminHome", match);

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-md-3 col-lg-2 d-md-block bg-light collapse"
          id="adminSidebarMenu"
          role="tablist"
          aria-orientation="vertical"
        >
          <ul className="navbar-nav flex-column">
            <Link to={`${match.url}/banner`} className="nav-link active">
              <li
                className="nav-item"
                data-bs-toggle="collapse"
                data-bs-target=".collapse.show"
              >
                배너 관리
              </li>
            </Link>
            <Link to={`${match.url}/media`} className="nav-link">
              <li
                className="nav-item"
                data-bs-toggle="collapse"
                data-bs-target=".collapse.show"
              >
                미디어 관리
              </li>
            </Link>
            <Link to={`${match.url}/event`} className="nav-link">
              <li
                className="nav-item"
                data-bs-toggle="collapse"
                data-bs-target=".collapse.show"
              >
                행사 관리
              </li>
            </Link>
            <Link to={`${match.url}/member`} className="nav-link">
              <li
                className="nav-item"
                data-bs-toggle="collapse"
                data-bs-target=".collapse.show"
              >
                교인 관리
              </li>
            </Link>
            <Link to={`${match.url}/worship`} className="nav-link">
              <li
                className="nav-item"
                data-bs-toggle="collapse"
                data-bs-target=".collapse.show"
              >
                예배 관리
              </li>
            </Link>
          </ul>
        </div>
        <div
          className="col-md-9 ms-sm-auto col-lg-10 px-md-4"
          id="v-pills-tabContent"
        >
          <Switch>
            <Route path={`${match.path}/banner`}>
              <Banner />
            </Route>
            <Route path={`/admin/media`}>
              <Media />
            </Route>
            <Route path={`${match.path}/event`}>
              <EventWrite />
            </Route>
            <Route path={`${match.path}/member`}>
              <MemberManager />
            </Route>
            <Route path={`${match.path}/worship`}>
              <Worship />
            </Route>
            <Route path={`${match.path}`}>
              <Banner />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};
