import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bannerApi from "../apis/banner.api";
import { useAuth } from "../context/auth_context";

const MainBanner = () => {
  const { catchErrorAuth } = useAuth();
  const [banners, setBanners] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    getBanners();
  }, []);

  async function getBanners() {
    try {
      setError("");
      const { rows } = await bannerApi.readMainBanners();
      console.log("main banners", rows);
      setBanners(rows);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }

  return (
    <div
      id="myCarousel"
      className="carousel slide carousel-fade"
      data-bs-ride="carousel"
      data-bs-touch="true"
    >
      <div className="carousel-indicators">
        {banners.map((banner: any, index: number) => {
          return (
            <button
              key={banner.id}
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to={index}
              className={`${index === 0 ? "active" : ""}`}
              aria-current="true"
              aria-label={`slide ${index}`}
            ></button>
          );
        })}
      </div>
      <div className="carousel-inner">
        {banners.map((banner: any, index: number) => {
          return (
            <div
              key={banner.id}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              <Link to={banner.url ? banner.url : ""}>
                <img
                  src={`/uploads/${banner.fileInfo.saveName}`}
                  alt={banner.description}
                  className="img-fluid"
                  style={{ objectFit: "contain", width: "100%" }}
                />
              </Link>
            </div>
          );
        })}
        {/*
        //주의: carousel-indicator의 갯수와 같이 일치시켜야 합니다.
        <div
          className={
            "carousel-item bg-secondary" + (count === 0 ? " active" : "")
          }
          style={{ height: "15em" }}
        >
          <Link to="/board/bulletin">
            <img
              src="..."
              alt="..."
              className="img-fluid d-block h-100 w-100"
              style={{ objectFit: "contain", width: "100%" }}
            />
            <div className="carousel-caption" style={{ top: "1.25rem" }}>
              <h3 className="h3-responsive">우리들의 게시판</h3>
            </div>
          </Link>
        </div> 
        */}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default MainBanner;
