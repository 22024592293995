import { Link } from "react-router-dom";

export const AdminBannerCarousel = ({ mainBanners }: any) => {
  return (
    <div
      id="adminCarousel"
      className="carousel slide carousel-fade"
      data-bs-ride="carousel"
      data-bs-touch="true"
    >
      <div className="carousel-indicators">
        {mainBanners.map((banner: any, index: number) => {
          return (
            <button
              type="button"
              data-bs-target="#adminCarousel"
              data-bs-slide-to={index}
              className={`${index === 0 ? "active" : ""}`}
              aria-current="true"
              aria-label={`slide ${index}`}
            ></button>
          );
        })}
      </div>
      <div className="carousel-inner">
        {mainBanners.map((banner: any, index: number) => {
          return (
            <div className={`carousel-item ${index === 0 ? "active" : ""}`}>
              <Link to={banner.url || ""}>
                {banner.fileInfo.saveName && (
                  <img
                    src={`/uploads/${banner.fileInfo?.saveName}`}
                    alt={banner.description}
                    className="img-fluid"
                    style={{ objectFit: "contain", width: "100%" }}
                  />
                )}
              </Link>
            </div>
          );
        })}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#adminCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#adminCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};
