import axios from "axios";
import baseUrl from "../utils/baseUrl";

// const create = async (category: string, content: any) => {
//   const { data } = await axios.post(`${baseUrl}/api/info/${category}`, content);
//   return data;
// };

const read = async () => {
  const { data } = await axios.get(`${baseUrl}/api/info`);
  return data;
};

const updateOrCreate = async (category: string, content: any) => {
  const { data } = await axios.put(`${baseUrl}/api/info/${category}`, content);
  return data;
};

const infoApi = {
  // create,
  read,
  updateOrCreate,
};

export default infoApi;
