import { Route, Switch } from "react-router-dom";
import Footer from "./components/Footer";
import Layout from "./components/Layout";
import Menu from "./components/Menu";
import PrivateRoute from "./components/PrivateRoute";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import NoticePage from "./pages/NoticePage";
import InfoPage from "./pages/InfoPage";
import EventPage from "./pages/EventPage";
import BiblePage from "./pages/BiblePage";
import VideoPage from "./pages/VideoPage";
import NoticeWritePage from "./pages/NoticeWritePage";
import NoticeEditPage from "./pages/NoticeEditPage";
import NoticeListPage from "./pages/NoticeListPage";
import { SearchPage } from "./components/Search";
import { ProfilePage } from "./profile/ProfilePage";

export const AppWrapper = () => {
  return (
    <Layout>
      <Menu />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/home" component={HomePage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/signup" component={SignupPage} />
        <Route path="/search" component={SearchPage} />
        <PrivateRoute path="/profile" component={ProfilePage} />

        <PrivateRoute path="/write/:group" component={NoticeWritePage} />
        <Route path="/edit/:group/:noticeId" component={NoticeEditPage} />
        <Route path="/board/:group/:noticeId" component={NoticePage} />
        <Route path="/board/:group" component={NoticeListPage} />
        <Route path="/info" component={InfoPage} />
        <Route path="/event" component={EventPage} />
        <Route path="/bible" component={BiblePage} />
        <Route path="/video" component={VideoPage} />
      </Switch>
      <Footer />
    </Layout>
  );
};
