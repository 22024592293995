import Direction from "./Direction";
import HeeRakInfo from "./HeeRakInfo";
import Ministers from "./Ministers";
import SeniorPastor from "./SeniorPastor";
import { useEffect, useState } from "react";
import infoApi from "../../apis/info.api";
import { useAuth } from "../../context/auth_context";
import { Link, Route, Switch } from "react-router-dom";
import ToastAlert from "../ToastAlert";
import { AlertMessage } from "../../types/heerak";

// const ministers = [
//   { id: "1", role: "담임 목사", name: "김현호 목사", kind: "pastor" },
//   { id: "2", role: "교육", name: "최선웅 전도사", kind: "pastor" },
//   { id: "3", role: "시무 장로", name: "손창진", kind: "elder" },
//   { id: "4", role: "시무 장로", name: "박성주", kind: "elder" },
//   { id: "5", role: "협동 장로", name: "이대귀", kind: "elder" },
// ];

// const pastors = [
//   { role: "담임 목사", name: "김현호 목사" },
//   { role: "교육", name: "최선웅 전도사" },
// ];

// const elders = [
//   { role: "시무 장로", name: "손창진" },
//   { role: "시무 장로", name: "박성주" },
//   { role: "협동 장로", name: "이대귀" },
// ];

const Info = () => {
  const { catchErrorAuth } = useAuth();
  const [heerak, setHeerak] = useState({
    data: { vision: "", history: "", policy: "" },
  });
  const [pastor, setPastor] = useState({
    fileInfo: { id: "", saveName: "", orgName: "", type: "" },
    data: {
      content: "",
      files: null,
    },
  });
  const [direction, setDirection] = useState({
    fileInfo: { id: "", saveName: "", orgName: "", type: "" },
    data: {
      src: "",
      address: "",
      phone: "",
      files: null,
    },
  });
  const [error, setError] = useState("");
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  useEffect(() => {
    getInfos();
  }, []);

  const getInfos = async () => {
    try {
      setError("");
      const infos = await infoApi.read();
      console.log("infos===", infos);
      infos.forEach((info: any, index: number) => {
        console.log("get info data", info.data);
        // if (info.attachments.length !== 0) {
        //   info.content["img"] = info.attachments;
        // }
        switch (info.category) {
          case "heerakinfo":
            setHeerak(info);
            break;
          case "seniorpastor":
            console.log("pastor=", info);
            setPastor(info);
            break;
          case "direction":
            setDirection(info);
        }
      });
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const updateOrCreateInfo = async (category: string, infoData: any) => {
    try {
      setError("");
      // console.log('info data', infoData);
      const response = await infoApi.updateOrCreate(category, infoData);
      console.log("updated info", response);
      setAlertMessage({
        message: "수정이 완료되었습니다!",
        msgType: "success",
        closeFunction: async () => {
          await getInfos();
          setAlertMessage(null);
        },
        onCancel: () => setAlertMessage(null),
      });
      await getInfos();
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  return (
    <div className="" id="heeRakInformation">
      {error && <div className="alert alert-danger">{error}</div>}
      <ul className="list-group list-group-horizontal">
        <Link className="text-decoration-none" to="/info/intro">
          <li className="list-group-item border-0 border-end">교회안내</li>
        </Link>
        <Link className="text-decoration-none" to="/info/senior-pastor">
          <li className="list-group-item border-0 border-end">담임목사</li>
        </Link>
        <Link className="text-decoration-none" to="/info/minister">
          <li className="list-group-item border-0 border-end">섬기는 사람들</li>
        </Link>
        <Link className="text-decoration-none" to="/info/map">
          <li className="list-group-item border-0">오시는 길</li>
        </Link>
      </ul>
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <div className="container-fluid">
        <Switch>
          <Route path="/info/intro">
            <HeeRakInfo
              heerak={heerak}
              updateOrCreateInfo={updateOrCreateInfo}
            />
          </Route>
          <Route path="/info/senior-pastor">
            <SeniorPastor
              pastor={pastor}
              updateOrCreateInfo={updateOrCreateInfo}
            />
          </Route>
          <Route path="/info/minister">
            <Ministers />
          </Route>
          <Route path="/info/map">
            <Direction
              direction={direction}
              updateOrCreateInfo={updateOrCreateInfo}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Info;
