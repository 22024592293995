import axios from "axios";
import baseUrl from "../utils/baseUrl";

const download = async (id: any, fileName: string) => {
  const res = await axios({
    method: "GET",
    url: `${baseUrl}/api/fileinfos/${id}`,
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(
    new Blob([res.data], { type: res.headers["content-type"] })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

const fileApi = { download };

export default fileApi;
