const Footer = () => {
  return (
    <div
      className="d-flex flex-column align-items-center text-center mt-5"
      style={{ fontSize: "0.9rem" }}
    >
      <p>
        <b>희락교회</b>
        <br />
        주소 : 전북 전주시 덕진구 호성동 1가 호성로 134
        <br />
        진흥더블파크상가 301, 302호
        <br />
        063-242-2334, 010-4654-1995
        <br />
        ssss3217@gmail.com
      </p>
      <p>&copy; 희락교회 All Rights Reserved</p>
    </div>
  );
};

export default Footer;
