import { useAuth } from "../../context/auth_context";
import { getDate } from "../../utils/clientHelper";

const CARD_STYLE = new Map([
  [
    "important",
    {
      name: "중요",
      cardClassName: "border-danger",
      titleClassName: "text-danger",
      badgeClassName: "bg-danger",
    },
  ],
  [
    "normal",
    {
      name: "일반",
      cardClassName: "border-dark",
      titleClassName: "text-dark",
      badgeClassName: "bg-dark",
    },
  ],
  [
    "finished",
    {
      name: "마감",
      cardClassName: "",
      badgeClassName: "",
      color: "#999999",
    },
  ],
]);

type Props = {
  card: any;
  cardType: string;
  setData: Function;
  setWriteMode: Function;
  deletePray: Function;
};

const BandPrayCard = ({
  card,
  cardType,
  setData,
  setWriteMode,
  deletePray,
}: Props) => {
  const { user } = useAuth();
  console.log('card in band pray card', card);

  return (
    <div className="col">
      <div
        className={`card mb-3 ${CARD_STYLE.get(cardType)?.cardClassName}`}
        data-bs-toggle="collapse"
        data-bs-target={`#card${card.id}`}
        aria-expanded="false"
        aria-controls={`card${card.id}`}
        style={{ borderColor: CARD_STYLE.get(cardType)?.color }}
      >
        <div className="card-body">
          <h5
            className={`card-title ${CARD_STYLE.get(cardType)?.titleClassName}`}
          >
            <span
              className={`badge ${CARD_STYLE.get(cardType)?.badgeClassName}`}
              style={{ backgroundColor: CARD_STYLE.get(cardType)?.color }}
            >
              {CARD_STYLE.get(cardType)?.name}
            </span>
            {card.title}
            <span>
              {cardType !== "finished" && user?.userId === card.user.id && (
                <i
                  className="bi bi-pencil-fill px-1"
                  onClick={() => {
                    setData(card);
                    setWriteMode("edit");
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#prayModal"
                />
              )}
            {user?.userId === card.user.id && <i
              className="bi bi-trash-fill px-1"
              onClick={() => deletePray(card.id)}
            />}
            </span>
          </h5>
          <span>
            마감일 : {getDate(new Date(card.endDate))} 작성자 : {card.user.name}
          </span>
          <div className="card-text collapse" id={`card${card.id}`}>
            {card.content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BandPrayCard;
