import { useHistory } from "react-router-dom";
import styles from "./Event.module.scss";

const EventsListItem = ({ event, user, removeEvent }: any) => {
  const history = useHistory();

  console.log('event in events list item', event);

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`flush-heading-${event.id}`}>
        <button
          className={`accordion-button collapsed ${styles.accordionButton}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#flush-collapse-${event.id}`}
          aria-expanded="false"
          aria-controls={`flush-collapse-${event.id}`}
        >
          {event.startDay}일 {event.title}
        </button>
      </h2>
      <div
        id={`flush-collapse-${event.id}`}
        className="accordion-collapse collapse"
        aria-labelledby={`flush-heading-${event.id}`}
        data-bs-parent="#accordionFlushExample"
      >
        <div className="accordion-body">
          <p className="row">
            <span className="col-auto pe-2">일시: </span>
            <span className="col p-0">
              {event.startDate} ~ {event.endDate}
            </span>
          </p>
          <p className="row">
            <span className="col-auto pe-2">장소: </span>
            <span className="col p-0">{event.place}</span>
          </p>
          <p className="row">
            <span className="col-auto pe-2">내용: </span>
            <span className="col p-0">{event.content}</span>
          </p>
          <p className="row">
            <span className="col-auto pe-2">문의: </span>
            <span className="col p-0">{event.phone}</span>
          </p>
          {user?.role === "admin" ? (
            <div className="d-flex justify-content-end">
              <button
                className="m-1 btn btn-primary text-white"
                // data-bs-toggle="modal"
                // data-bs-target={"#editEvent" + event.id}
                onClick={() => history.push(`/event/edit/${event.id}`)}
              >
                수정
              </button>
              <button
                className="m-1 btn btn-primary text-white"
                onClick={() => removeEvent(event.id)}
              >
                삭제
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EventsListItem;
