import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth_context";
import Title from "../Title";

type Props = {
  heerak: { data: { vision: string; history: string; policy: string } };
  updateOrCreateInfo: (category: string, infoData: object) => void;
};

const HeeRakInfo = ({ heerak, updateOrCreateInfo }: Props) => {
  const { user } = useAuth();
  const [info, setInfo] = useState(heerak.data);
  const [isEdit, setIsEdit] = useState(false);

  function handleChange(e: any) {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  }

  function handleSubmit() {
    updateOrCreateInfo("heerakinfo", info);
  }

  useEffect(() => {
    setInfo(heerak.data);
    setIsEdit(false);
  }, [heerak]);

  return (
    <>
      <Title>
        <div className="d-flex justify-content-between mb-2">
          희 락 교 회
          {user?.role === "admin" ? (
            isEdit ? (
              <div>
                <button
                  className="btn btn-primary text-white me-2"
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  확인
                </button>
                <button
                  className="btn btn-primary text-white"
                  onClick={() => {
                    setIsEdit(!isEdit);
                    setInfo(heerak.data);
                  }}
                >
                  취소
                </button>
              </div>
            ) : (
              <div>
              <button
                className="btn btn-primary text-white"
                onClick={() => setIsEdit(!isEdit)}
              >
                수정
              </button>
              </div>
            )
          ) : null}
        </div>
      </Title>
      {isEdit ? (
        <div className="row d-flex justify-content-center mx-2">
          <form onSubmit={handleSubmit}>
            <h3>목회비전</h3>
            <textarea
              className="form-control mb-5"
              id="visionInput"
              name="vision"
              rows={10}
              value={info.vision}
              onChange={handleChange}
            />
            <h3>연혁</h3>
            <textarea
              className="form-control mb-5"
              id="historyInput"
              name="history"
              rows={7}
              value={info.history}
              onChange={handleChange}
            />
            <h3>목회방침</h3>
            <textarea
              className="form-control mb-5"
              id="policyInput"
              name="policy"
              rows={10}
              value={info.policy}
              onChange={handleChange}
            />
          </form>
        </div>
      ) : (
        <div className="row d-flex justify-content-center mx-2">
          <div>
            <h3>목회비전</h3>
            <p className="mb-5">
              {info.vision.split("\n").map((line) => {
                return (
                  <span>
                    {line}
                    <br />
                  </span>
                );
              })}
            </p>
            <h3>연혁</h3>
            <p className="mb-5">
              {info.history.split("\n").map((line) => {
                return (
                  <span>
                    {line}
                    <br />
                  </span>
                );
              })}
            </p>
            <h3>목회방침</h3>
            <p className="mb-5">
              {info.policy.split("\n").map((line) => {
                return (
                  <span>
                    {line}
                    <br />
                  </span>
                );
              })}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export default HeeRakInfo;
