import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import BandBoardWrite from "./BandBoardWrite";
import BandBoardEdit from "./BandBoardEdit";
import { BandBoardHome } from "./BandBoardHome";
import { BandBoardSearch } from "./BandBoardSearch";
import bandApi from "../../apis/band.api";
import { useAuth } from "../../context/auth_context";
import ToastAlert from "../ToastAlert";
import { useEffect, useState } from "react";
import BandBoardModal from "./BandBoardModal";
import { AlertMessage } from "../../types/heerak";

const INIT_POST = {
  id: 0,
  user: {id: 0, name: "" },
  attachments: [],
  content: "",
  group: "",
  createdAt: "",
  updateddAt: "",
  fileInfos: [],
};

const BandBoard = () => {
  const { catchErrorAuth } = useAuth();
  const history = useHistory();
  const location = useLocation<{ refresh: string }>();
  const match = useRouteMatch<any>();
  const { group } = useParams<{ group: string }>();
  const [postList, setPostList] = useState<any>([]);
  const [error, setError] = useState("");
  const [selectedPost, setSelectedPost] = useState(INIT_POST);

  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>({
    message: "",
    msgType: "Info",
    closeFunction: () => {},
    onCancel: () => {},
  });

  useEffect(() => {
    getPosts();
  }, [location.state?.refresh]);

  async function getPosts() {
    try {
      setError("");
      const posts = await bandApi.readBandBoard(group, 1, 5);
      console.log("post in band board", posts);
      setPostList(posts);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }

  async function deletePost(id: string, groupName: string = group) {
    try {
      setError("");
      console.log("group in delete post", groupName);
      setAlertMessage({
        message: "글을 삭제하시겠습니까?",
        msgType: "confirm",
        closeFunction: async () => {
          try {            
            await bandApi.removeBandBoard(groupName, id);
            getPosts();
            setAlertMessage(null);
          } catch (error) {
            setAlertMessage(null)
          }
        },
        onCancel: () => setAlertMessage(null),
      });
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }

  async function editPost() {
    history.push(`${match.url}/edit/${selectedPost.id}`);
  }

  async function writePost() {
    history.push(`${match.url}/write`);
  }

  const getSearchResults = async (
    group: string,
    category: string,
    keyword: string
  ) => {
    console.log("category", category, "keyword", keyword);
    const { count, rows } = await bandApi.getSearchResults(
      "band-boards",
      group,
      category,
      keyword
    );
    console.log("search results", rows);
    return rows;
  };

  return (
    <>
      <Switch>
        <Route path={`${match.path}/search`}>
          <BandBoardSearch setSelectedPost={setSelectedPost} />
        </Route>
        <Route path={`/band/:group/board/write`}>
          <BandBoardWrite match={match} />
        </Route>
        <Route path={`/band/:group/board/edit/:postId`}>
          <BandBoardEdit match={match} />
        </Route>
        <Route>
          <BandBoardHome
            group={group}
            getSearchResults={getSearchResults}
            writePost={writePost}
            postList={postList}
            setSelectedPost={setSelectedPost}
          />
          {alertMessage?.message && (
            <ToastAlert
              message={alertMessage.message}
              msgType={alertMessage.msgType}
              closeFunction={alertMessage.closeFunction}
              onCancel={alertMessage.onCancel}
            />
          )}
        </Route>
      </Switch>
      <BandBoardModal
        modalId="bandBoardModal"
        post={selectedPost}
        deletePost={deletePost}
        editPost={editPost}
      />
    </>
  );
};

export default BandBoard;
