import axios from "axios";
import baseUrl from "../utils/baseUrl";

const create = async (group: string, formData: any) => {
  const { data } = await axios.post(`${baseUrl}/api/post/${group}`, formData);
  return data;
};

const edit = async (group: string, id: any, formData: any) => {
  const { data } = await axios.put(
    `${baseUrl}/api/post/${group}/${id}`,
    formData
  );
  return data;
};

const getSearchResults = async (group:string, category: string, keyword: string) => {
  const payload = {
    params: { category, group, keyword },
  };
  const { data } = await axios.get(`${baseUrl}/api/post/search`, payload);
  return data;
};

const groupReadAll = async (groupName: string) => {
  const { data } = await axios.get(`${baseUrl}/api/post?group=${groupName}`);
  return data;
};

const readAll = async (group: string) => {
  const { data } = await axios.get(`${baseUrl}/api/post?group=${group}`);
  return data;
};

const readOne = async (group: string, id: any) => {
  const { data } = await axios.get(`${baseUrl}/api/post/${group}/${id}`);
  return data;
};

const readPosts = async (group: string, page: number, limit: number = 5) => {
  const payload = {
    params: {
      page,
      limit,
    },
  };
  const { data } = await axios.get(`${baseUrl}/api/post/${group}`, payload);
  return data;
};

const remove = async (group: string, id: any) => {
  const { data } = await axios.delete(`${baseUrl}/api/post/${group}/${id}`);
  return data;
};

const noticeApi = {
  create,
  edit,
  getSearchResults,
  groupReadAll,
  readAll,
  readOne,
  readPosts,
  remove,
};

export default noticeApi;
