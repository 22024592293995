import { forwardRef, Fragment } from "react";
import bibleInfo from "./bibleInfo.json";

const oldTestament = bibleInfo.filter((book: any) => book.testament === "old");
const newTestament = bibleInfo.filter((book: any) => book.testament === "new");
const oldBibleColor = "#d3dbea";
const newBibleColor = "#dacbdf";

const BibleReadingModal = forwardRef<HTMLDivElement, any>(
  ({ modalTitle, reading, handleVerse, handleClose, isOwner }: any, ref) => {
    console.log("reading in bible reading modal ====", reading);
    return (
      <>
        <div
          className="modal fade"
          id="bibleModal"
          ref={ref}
          // data-bs-backdrop="static"
          // data-bs-keyboard="false"
          aria-labelledby="bibleModal"
          aria-hidden="false"
        >
          <div className="modal-dialog modal-xl modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header" style={{ borderBottom: "0px" }}>
                <h5 className="modal-title" id="modalTitle">
                  {modalTitle}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <div
                className="list-group list-group-flush flex-row"
                id="bibleScrollspy"
              >
                <a
                  className="list-group-item list-group-item-action list-group-item-light my-1 mx-3"
                  href="#oldTestament"
                  style={{
                    borderRadius: "3px 3px 0px 0px",
                    borderBottom: `4px solid ${oldBibleColor}`,
                  }}
                >
                  구약
                </a>
                <a
                  className="list-group-item list-group-item-action list-group-item-light my-1 mx-3"
                  href="#newTestament"
                  style={{
                    borderRadius: "3px 3px 0px 0px",
                    borderBottom: `4px solid ${newBibleColor}`,
                  }}
                >
                  신약
                </a>
              </div>
              <div
                className="modal-body p-0"
                data-bs-spy="scroll"
                data-target="#bibleScrollspy"
                data-bs-offset="100"
                tabIndex={0}
              >
                <div id="oldTestament">
                  <Testament
                    testament={oldTestament}
                    bibleColor={oldBibleColor}
                    reading={reading}
                    handleVerse={handleVerse}
                    isOwner={isOwner}
                  />
                </div>
                <div
                  id="bibleDivideArea"
                  style={{
                    background: `linear-gradient( to bottom, ${oldBibleColor}, ${newBibleColor} )`,
                    height: "10rem",
                  }}
                />
                <div id="newTestament">
                  <Testament
                    testament={newTestament}
                    bibleColor={newBibleColor}
                    reading={reading}
                    handleVerse={handleVerse}
                    isOwner={isOwner}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

const Testament = ({
  testament,
  bibleColor,
  reading,
  handleVerse,
  isOwner,
}: any) => {
  return (
    <div
      className="container-fluid p-4"
      style={{ backgroundColor: `${bibleColor}` }}
    >
      {testament.map((book: any) => {
        return (
          <BibleBook
            key={book.name}
            book={book.name}
            verse={book.verse}
            bibleColor={bibleColor}
            handleVerse={handleVerse}
            reading={reading}
            isOwner={isOwner}
          />
        );
      })}
    </div>
  );
};

const BibleBook = ({
  book,
  bibleColor,
  verse,
  handleVerse,
  reading,
  isOwner,
}: any) => {
  let verses = [];
  for (let i = 1; i <= verse; i++) {
    verses.push(i);
  }

  const checkedVerseSet =
    reading && reading[book] ? new Set(reading[book]) : new Set();

  return (
    <div
      className="row my-2"
      style={{ backgroundColor: "white", borderRadius: "2px" }}
    >
      <div className=" col-4 col-md-3 d-flex align-items-center justify-content-center">
        <b>{book}</b>
      </div>
      <div className="col-8 col-md-9 p-2" style={{ textAlign: "left" }}>
        {verses.map((verse: number, index: number) => {
          return (
            <VerseBox
              key={verse}
              isOwner={isOwner}
              book={book}
              verse={verse}
              handleVerse={handleVerse}
              checkedVerseSet={checkedVerseSet}
              bibleColor={bibleColor}
            />
          );
        })}
      </div>
    </div>
  );
};

const VerseBox = ({
  isOwner,
  book,
  verse,
  handleVerse,
  checkedVerseSet,
  bibleColor,
}: any) => {
  return (
    <>
      <input
        type="checkbox"
        className="btn-check"
        disabled={!isOwner}
        id={book + verse}
        autoComplete="off"
        checked={checkedVerseSet.has(verse)}
        onChange={handleVerse}
      />
      <label
        className="btn btn-outline-LightGray text-center"
        style={{
          width: "3.3rem",
          border: `1px solid ${bibleColor}`,
          borderRadius: "2px",
          margin: "1px",
          color: "#666565",
        }}
        htmlFor={book + verse}
      >
        {verse}
      </label>
    </>
  );
};
export default BibleReadingModal;
