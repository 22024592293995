import { ChangeEventHandler, useEffect, useState } from "react";
import { useAuth } from "../../context/auth_context";
import { MinisterType } from "../../types/heerak";

type MinisterItemParams = {
  minister: MinisterType;
  deleteMinister: Function;
  updateMinister: Function;
};

export const MinisterItem = ({
  minister,
  deleteMinister,
  updateMinister,
}: MinisterItemParams) => {
  const { user } = useAuth();
  const [member, setMember] = useState(minister);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setMember(minister);
  }, [minister]);

  const onSave = () => {
    setIsEdit(false);
    console.log("member===", member);
    updateMinister(member);
  };

  const onCancel = () => {
    setIsEdit(false);
    setMember(minister);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value, checked } = event.target;
    console.log("member == ", member);
    console.log("name", name, "value", value, "checked", checked);
    setMember({ ...member, [name]: name === "isMember" ? checked : value });
  };

  return (
    <>
      <td>
        {isEdit ? (
          <input name="role" value={member.role} onChange={handleChange} />
        ) : (
          member.role
        )}
      </td>
      <td>
        {isEdit ? (
          <input name="name" value={member.name} onChange={handleChange} />
        ) : (
          member.name
        )}
      </td>
      {user?.role === "admin" && <td>
        {isEdit ? (
          <>
            <button className="btn btn-primary me-2" onClick={onSave}>
              확인
            </button>
            <button className="btn btn-primary" onClick={onCancel}>
              취소
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-primary me-2"
              onClick={() => setIsEdit(true)}
            >
              수정
            </button>
            <button
              className="btn btn-primary"
              onClick={() => deleteMinister(minister.id)}
            >
              삭제
            </button>
          </>
        )}
      </td>}
    </>
  );
};
