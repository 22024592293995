import { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import mediaApi from "../../apis/media.api";
import { useAuth } from "../../context/auth_context";
import { AlertMessage } from "../../types/heerak";
import Title from "../Title";
import ToastAlert from "../ToastAlert";

export const EditVideo = () => {
  const { catchErrorAuth } = useAuth();
  const history = useHistory();
  const { videoId } = useParams<{ videoId: string }>();
  const [video, setVideo] = useState({
    id: "",
    kind: "",
    show: false,
    url: "",
  });
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchVideo = async () => {
      const video = await mediaApi.getVideoById(videoId);
      setVideo(video);
    };

    fetchVideo();
  }, []);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = e.target;
    console.log(`name: ${name}, value: ${checked}`);
    setVideo({ ...video, [name]: checked });
  }

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    console.log(`name: ${name}, value: ${value}`);
    setVideo({ ...video, [name]: value });
  };

  async function handleEditVideo(e: any) {
    e.preventDefault();
    if (!video.kind) {
      return setAlertMessage({
        message: "영상 구분을 선택해 주세요",
        msgType: "error",
        closeFunction: () => setAlertMessage(null),
        onCancel: () => setAlertMessage(null),
      });
    }
    try {
      setLoading(true);
      setError("");

      console.log("video submit", video);
      await mediaApi.editVideoById(videoId, video);

      setLoading(false);
      setAlertMessage({
        message: "동영상 수정이 완료되었습니다.",
        msgType: "success",
        closeFunction: () =>
          history.push("/admin/media/video", { refresh: true }),
        onCancel: () => setAlertMessage(null),
      });
    } catch (error) {
      setLoading(false);
      catchErrorAuth(error, setError);
    }
  }

  return (
    <div>
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <Title>
        <h5 className="mt-5">동영상 수정</h5>
      </Title>
      <form onSubmit={handleEditVideo}>
        <textarea
          name="show"
          className="form-control my-4"
          id="show"
          value={video.url}
          style={{ resize: "none" }}
          disabled
        ></textarea>
        <div className="row">
          <div className="col-md">
            <select
              className="form-select"
              defaultValue=""
              aria-label="video select"
              name="kind"
              value={video.kind}
              onChange={handleSelect}
            >
              <option disabled>영상 구분</option>
              <option value="heerak">희락</option>
              <option value="church">교회</option>
            </select>
          </div>
          <div className="col-md">
            <div className="form-check">
              <input
                type="checkbox"
                id="check-show"
                name="show"
                className="form-check-input"
                checked={video.show}
                onChange={handleChange}
              />
              <label htmlFor="check-show" className="form-check-label">
                보이기
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex gap-2 mt-4">
          <button className="btn btn-primary" type="submit">
            확인
          </button>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => history.goBack()}
          >
            취소
          </button>
        </div>
      </form>
    </div>
  );
};
