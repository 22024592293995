import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth_context";
import { GROUP_NAMES } from "../../utils/constants";
import styles from "./band.module.scss";

export const BandMenu = ({ group }: any) => {
  const { user, logout } = useAuth();

  return (
    <div className={styles["band-menu"]}>
      <nav className="navbar navbar-expand-md navbar-light">
        <Link className="navbar-brand" to="/home">
          <img
            src="/heerak_logo.png"
            alt="홈페이지"
            style={{ height: "3rem" }}
          />
        </Link>
        <button
          className="navbar-toggler border-0 me-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#band-navbar-menu"
          aria-controls="band-navbar-menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span>{GROUP_NAMES.get(group)?.name}</span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-between"
          id="band-navbar-menu"
        >
          <ul className="navbar-nav">
            <Link className="nav-link p-0 m-2" to="/band/youth">
              <li
                className="navbar-item"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                중고등부
              </li>
            </Link>
            <Link className="nav-link p-0 m-2" to="/band/youngadult">
              <li
                className="navbar-item"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                청년부
              </li>
            </Link>
            <Link className="nav-link p-0 m-2" to="/band/menministry">
              <li
                className="navbar-item"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                남선교회
              </li>
            </Link>
            <Link className="nav-link p-0 m-2" to="/band/womenministry">
              <li
                className="navbar-item"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                여전도회
              </li>
            </Link>
            <Link className="nav-link p-0 m-2" to="/band/district">
              <li
                className="navbar-item"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                구역모임
              </li>
            </Link>
          </ul>
          <div className="d-md-none border-top"></div>
          <div className="navbar-nav me-3">
            {user ? (
              <li className="navbar-item">
                <Link className="nav-link p-0 m-2" to="/login" onClick={logout}>
                  로그아웃
                </Link>
              </li>
            ) : (
              <>
                <li className="navbar-item">
                  <Link className="nav-link p-0 m-2" to="/login">
                    로그인
                  </Link>
                </li>
                <li className="navbar-item">
                  <Link className="nav-link p-0 m-2" to="/signup">
                    회원가입
                  </Link>
                </li>
              </>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};
