import { Link } from "react-router-dom";
import { Notice } from "../types/heerak";

type Params = {
  notices: Notice[];
};

export const NoticeComponent = ({ notices }: Params) => {
  console.log("notices in notice component", notices);

  return (
    <div className="col-12 col-md-8">
      <div className="card m-3 mb-3" style={{ border: "none" }}>
        <div className="card-header">
          공지사항
          <Link
            to="/board/notice"
            className="card-link"
            style={{ textDecoration: "none", float: "right", color: "gray" }}
          >
            {" "}
            더보기 +{" "}
          </Link>
        </div>
        <div className="card-body p-0">
          {notices.map((notice: Notice, index: number) => {
            return notice.title.includes("\u200b") ? (
              <li key={index} className="list-group-item text-truncate">
                {notice.title}
              </li>
            ) : (
              <Link
                key={index}
                to={`/board/notice/${notice.id}`}
                className="list-group-item text-truncate text-start"
              >
                {notice.title}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};
