import { EventHandler, SyntheticEvent } from "react";
import PaginationBoot from "./PaginationBoot";

type PropsTypes = {
  totalPages?: number;
  activePage?: number;
  paginate?: Function;
  prevPage?: EventHandler<SyntheticEvent>;
  nextPage?: EventHandler<SyntheticEvent>;
};

/**
 *
 * @param totalPages 전체 페이지 수
 * @param activePage 현재 페이지
 * @param prevPage 이전 페이지 함수
 * @param nextPage 다음 페이지 함수
 * @param paginate 페이지 함수
 * @returns
 */
const Pagination = ({
  totalPages = 0,
  activePage = 0,
  prevPage,
  nextPage,
  paginate = () => {},
}: PropsTypes) => {
  const pageWidth = 5; // 보여질 페이지의 개수
  const section = Math.floor((activePage - 1) / pageWidth);

  let startPage = section * pageWidth + 1; // 보여질 시작 페이지
  if (startPage < 1) {
    startPage = 1;
  }

  let endPage = startPage + pageWidth - 1; // 보여질 끝 페이지
  if (endPage > totalPages) {
    endPage = totalPages;
  }

  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <PaginationBoot>
      <PaginationBoot.First
        disabled={activePage <= 1}
        onClick={() => paginate(1)}
      />
      <PaginationBoot.Prev onClick={prevPage} disabled={activePage <= 1} />
      {pageNumbers.map((number, index) => (
        <PaginationBoot.Item
          key={index}
          active={activePage === number}
          onClick={() => {
            console.log("number", number);
            paginate(number);
          }}
        >
          {number}
        </PaginationBoot.Item>
      ))}
      <PaginationBoot.Next
        onClick={nextPage}
        disabled={activePage >= totalPages}
      />
      <PaginationBoot.Last
        disabled={activePage >= totalPages}
        onClick={() => paginate(totalPages)}
      />
    </PaginationBoot>
  );
};

export default Pagination;
