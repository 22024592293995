// const IS_BROWSER = typeof window !== 'undefined' && typeof window.document !== 'undefined';
// const WINDOW = IS_BROWSER ? window : {};
export const REGEXP_DATA_URL = /^data:/;
export const REGEXP_DATA_URL_JPEG = /^data:image\/jpeg;base64,/;

const canvasMaxData = { width: Infinity, height: Infinity }

const RATIO = 1

async function initialCrop(imgsrc: string) {
    const image = new Image();
    image.src = imgsrc;
    await image.onload;

    const aspectRatio = image.naturalWidth / image.naturalHeight;

    //canvas w,h 설정

    let canvasWidth = Math.min(canvasMaxData.width, image.naturalWidth);
    let canvasHeight = Math.min(canvasMaxData.height, image.naturalHeight);

    if (canvasHeight * aspectRatio > canvasWidth) {
        canvasHeight = canvasWidth / aspectRatio;
    } else {
        canvasWidth = canvasHeight * aspectRatio;
    }

    const canvasData = {
        aspectRatio,
        naturalWidth: image.naturalWidth, //org 길이
        naturalHeight: image.naturalHeight,
        width: canvasWidth,
        height: canvasHeight,
    };

    setStyle(image, assign({
        width: canvasData.width,
        height: canvasData.height,
    }))

    //crop box w,h 정의

    const ratio = RATIO;

    let maxCropBoxWidth = canvasData.width;
    let maxCropBoxHeight = canvasData.height;

    if (maxCropBoxHeight * ratio > maxCropBoxWidth) {
        maxCropBoxHeight = maxCropBoxWidth / ratio;
    } else {
        maxCropBoxWidth = maxCropBoxHeight * ratio;
    }

    const cropBoxData = {
        width: maxCropBoxWidth,
        height: maxCropBoxHeight,
        left: ((canvasData.width - maxCropBoxWidth) / 2),
        top: ((canvasData.height - maxCropBoxHeight) / 2),
    };

    const newCanvas = document.createElement('canvas');
    const context = newCanvas.getContext('2d');
    newCanvas.width = cropBoxData.width;
    newCanvas.height = cropBoxData.height;

    const params = [
        cropBoxData.left, //srcX
        cropBoxData.top, //srcY
        cropBoxData.width, //srcWidth
        cropBoxData.height, //srcHeight
        0, //dstX
        0, //dstY
        cropBoxData.width, //dstWidth
        cropBoxData.height, //distHeight
    ];

    const drawParam = params.map((param) => Math.floor(normalizeDecimalNumber(param))) as [number, number, number, number, number, number, number, number]

    context?.drawImage(image, ...drawParam);

    return newCanvas
}

function isNumber(value: any) {
    return typeof value === 'number' && !isNaN(value);
}

function isObject(value: any) {
    return typeof value === 'object' && value !== null;
}

function isFunction(value: any) {
    return typeof value === 'function';
}

const { slice } = Array.prototype;

function toArray(value: any) {
    return Array.from ? Array.from(value) : slice.call(value);
}

function forEach(data: any, callback: any) {
    if (data && isFunction(callback)) {
        if (Array.isArray(data) || isNumber(data.length)/* array-like */) {
            toArray(data).forEach((value, key) => {
                callback.call(data, value, key, data);
            });
        } else if (isObject(data)) {
            Object.keys(data).forEach((key) => {
                callback.call(data, data[key], key, data);
            });
        }
    }

    return data;
}

function assign(target: any, ...args: any) {
    if (isObject(target) && args.length > 0) {
        args.forEach((arg: any) => {
            if (isObject(arg)) {
                Object.keys(arg).forEach((key) => {
                    target[key] = arg[key];
                });
            }
        });
    }

    return target;
};

const REGEXP_SUFFIX = /^width|height|left|top|marginLeft|marginTop$/;

function setStyle(element: any, styles: Object) {
    const { style } = element;

    forEach(styles, (value: any, property: any) => {
        if (REGEXP_SUFFIX.test(property) && isNumber(value)) {
            value = `${value}px`;
        }

        style[property] = value;
    });
}

const REGEXP_DECIMALS = /\.\d*(?:0|9){12}\d*$/;

function normalizeDecimalNumber(value: number, times = 100000000000) {
    return REGEXP_DECIMALS.test(String(value)) ? (Math.round(value * times) / times) : value;
}

export default initialCrop;