import axios from "axios";
import baseUrl from "../utils/baseUrl";

const editChannelById = async (id: string, payload: any) => {
  const { data } = await axios.put(`${baseUrl}/api/media/channel/${id}`, payload);
  return data;
};

const editVideoById = async (id: string, payload: any) => {
  const { data } = await axios.put(`${baseUrl}/api/media/video/${id}`, payload);
  return data;
};

const getAllVideos = async () => {
  const { data } = await axios.get(`${baseUrl}/api/media/video`);
  return data;
};

const getAllMainVideos = async () => {
  const { data } = await axios.get(`${baseUrl}/api/media/video/main`);
  return data;
};

const getChannels = async () => {
  const { data } = await axios.get(`${baseUrl}/api/media/channel`);
  return data;
};

const getVideo = async (kind: string) => {
  const payload = { params: { kind } };
  console.log("payload in get video", payload);
  const { data } = await axios.get(`${baseUrl}/api/media/video`, payload);
  return data;
};

const getVideoById = async (id: string) => {
  const { data } = await axios.get(`${baseUrl}/api/media/video/${id}`);
  return data;
};

const getChannelById = async (id: string) => {
  const { data } = await axios.get(`${baseUrl}/api/media/channel/${id}`);
  return data;
};

const postChannel = async (payload: any) => {
  const res = await axios.post(`${baseUrl}/api/media/channel`, payload);
  return res.data;
};

const postVideo = async (payload: any) => {
  const { data } = await axios.post(`${baseUrl}/api/media/video`, payload);
  return data;
};

const removeChannel = async (id: string) => {
  const { data } = await axios.delete(`${baseUrl}/api/media/channel/${id}`);
  return data;
}

const removeVideo = async (id: string) => {
  const { data } = await axios.delete(`${baseUrl}/api/media/video/${id}`);
  return data;
}

const mediaApi = {
  editChannelById,
  editVideoById,
  getAllMainVideos,
  getAllVideos,
  getChannelById,
  getChannels,
  getVideo,
  getVideoById,
  postChannel,
  postVideo,
  removeChannel,
  removeVideo,
};

export default mediaApi;
