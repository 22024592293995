import { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { MinisterType } from "../../types/heerak";
import Title from "../Title";
import { MinistersList } from "./MinistersList";
import { AddMinister } from "./AddMinister";
import ministerApi from "../../apis/minister.api";

const Ministers = () => {
  const location = useLocation<{ refresh: string }>();
  const [ministers, setMinisters] = useState<MinisterType[]>([]);

  useEffect(() => {
    getMinisters();
  }, [location.state?.refresh]);

  const getMinisters = async () => {
    const members = await ministerApi.getMinisters();
    setMinisters(members);
  };

  const saveMinisters = async (ministers: MinisterType[]) => {
    const updatedMinisters = await ministerApi.saveMinisters(ministers);
    setMinisters(updatedMinisters);
  };

  return (
    <>
      <Title>섬기는 사람들</Title>
      <Switch>
        <Route path="/info/minister/add">
          <AddMinister />
        </Route>
        <Route>
          <MinistersList
            ministers={ministers}
            saveMinisters={saveMinisters}
          />
        </Route>
      </Switch>
    </>
  );
};
export default Ministers;
