import { useEffect, useState } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import bannerApi from "../../apis/banner.api";
import { useAuth } from "../../context/auth_context";
import { AlertMessage } from "../../types/heerak";
import Title from "../Title";
import ToastAlert from "../ToastAlert";
import BannerAdd from "./BannerAdd";
import BannerEdit from "./BannerEdit";
import BannerList from "./BannerList";

const Banner = () => {
  const { catchErrorAuth } = useAuth();
  const location = useLocation<{ refresh: string }>();
  const [error, setError] = useState("");
  const [banners, setBanners] = useState<any[]>([
    {
      createdAt: "",
      description: "",
      endDate: "",
      fileInfo: { id: 0, orgName: "", saveName: "" },
      fileInfoId: 0,
      id: 0,
      kinds: "",
      show: false,
      startDate: "",
      updatedAt: "",
      url: "",
    },
  ]);
  const [alertMessage, setAlertMessage] = useState<any>({
    message: "",
    msgType: "Info",
    closeFunction: () => {},
    onCancel: () => {},
  });

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const { rows } = await bannerApi.readAll();
        console.log("banners = ", rows);
        setBanners(rows);
      } catch (error) {
        catchErrorAuth(error, setError);
      }
    };

    fetchBanners();
  }, [location.state?.refresh]);

  const handleRemove = async (id: number) => {
    try {
      console.log('handle remove?');
      setAlertMessage({
        message: "배너를 삭제하시겠습니까?",
        msgType: "confirm",
        closeFunction: async () => {
          await bannerApi.remove(id);
          console.log("alert msg banners", banners);
          setBanners(
            banners.filter((banner, i) => {
              return banner.id !== id;
            })
          );
          setAlertMessage("");
        },
        onCancel: () => {
          console.log("on cancel???");
          setAlertMessage("");
        },
      });
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  return (
    <div>
      <Title>배너 관리</Title>
      {alertMessage.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      {error && <div className="alert alert-danger">{error}</div>}
      <Switch>
        <Route path="/admin/banner/add">
          <BannerAdd />
        </Route>
        <Route path="/admin/banner/edit/:bannerId">
          <BannerEdit />
        </Route>
        <Route>
          <BannerList banners={banners} handleRemove={handleRemove} />
        </Route>
      </Switch>
    </div>
  );
};

export default Banner;
