import axios from "axios";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import authApi from "../apis/auth.api";
import { AlertMessage } from "../types/heerak";
import baseUrl from "../utils/baseUrl";
import catchErrors from "../utils/catchErrors";
import ToastAlert from "./ToastAlert";

const INIT_USER = {
  name: "",
  email: "",
  password: "",
  password2: "",
};

const Signup = () => {
  const [user, setUser] = useState(INIT_USER);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const isUser = Object.values(user).every((el) => Boolean(el));
      isUser ? setDisabled(false) : setDisabled(true);
    }

    return () => {
      isMounted = false;
    };
  }, [user]);

  const checkPasword = () => {
    const { password, password2 } = user;
    if (password !== password2) {
      setDisabled(true);
      throw new Error("비밀번호가 일치하지 않습니다");
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      setError("");
      setLoading(true);
      checkPasword();
      // const url = `${baseUrl}/api/auth/signup`;
      // await axios.post(url, user);
      const userData = {
        name: user.name,
        email: user.email,
        password: user.password,
      };
      await authApi.signup(userData);
      setAlertMessage({
        message: "가입이 완료되었습니다. 로그인 해주세요.",
        msgType: "success",
        closeFunction: () => setSuccess(true),
        onCancel: () => setAlertMessage(null),
      });
    } catch (error) {
      catchErrors(error, setError);
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return <Redirect to="/login" />;
  }

  const { name, email, password, password2 } = user;

  return (
    <div className="row align-items-center justify-content-center m-5">
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <div className="border col-12 col-md-8 col-xl-5 p-4">
        <h1 className="my-3 pb-3 text-MinDarkBlue text-center">회원가입</h1>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">이름</label>
            <div className="col-sm-8">
              <input
                className="form-control"
                type="text"
                placeholder="이름을 입력하세요"
                name="name"
                value={name}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4">이메일</label>
            <div className="col-sm-8">
              <input
                className="form-control"
                type="email"
                placeholder="이메일을 입력하세요"
                name="email"
                value={email}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">비밀번호</label>
            <div className="col-sm-8">
              <input
                className="form-control"
                type="password"
                placeholder="비밀번호를 입력하세요"
                name="password"
                value={password}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 ">비밀번호 확인</label>
            <div className="col-sm-8 ">
              <input
                className="form-control"
                style={{ fontSize: "95%" }}
                type="password"
                placeholder="동일한 비밀번호를 입력하세요"
                name="password2"
                value={password2}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="d-grid">
            <button
              disabled={disabled || loading}
              type="submit"
              className="my-2 btn btn-MinDarkBlue"
            >
              확인
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
