import { FormEvent, useState, useEffect } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import EditImg from "../EditImg";
import bandApi from "../../apis/band.api";
import { GROUP_NAMES } from "../../utils/constants";
import { useAuth } from "../../context/auth_context";
import ToastAlert from "../ToastAlert";
import { AlertMessage } from "../../types/heerak";

type PostType = {
  id: number;
  content: string;
  group: string;
};

type PreviousPostType = {
  id: number;
  writer: number;
  attachments: number[];
  content: string;
  group: string;
  createdAt: string;
  updateddAt: string;
  fileInfos: string[];
  user: { name: string };
};

const INIT_POST = {
  id: 0,
  content: "",
  group: "youth",
};

interface ParamTypes {
  group: string;
  postId: string;
}

const BandBoardWrite = ({ match }: any) => {
  const { catchErrorAuth } = useAuth();
  const history = useHistory();
  const { group } = useParams<ParamTypes>();

  const [post, setPost] = useState<PreviousPostType | PostType>(INIT_POST);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editedImages, setEditedImages] = useState<any>([]);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null);

  function handleChange(e: any) {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");
      console.log("edited images", editedImages);
      const formData = new FormData();
      if (editedImages) {
        for (let index = 0; index < editedImages.length; index++) {
          const element = dataURLtoFile(
            editedImages[index],
            `testFile${index}.jpg`
          );
          console.log("converted images", element);
          formData.append("files", element);
        }
      }
      formData.append("content", post["content"]);
      formData.append("group", group);
      const res = await bandApi.createBandBoard(formData);
      setLoading(false);
      setAlertMessage({
        message: "글 등록이 완료되었습니다.",
        msgType: "success",
        closeFunction: () => setSuccess(true),
        onCancel: () => setAlertMessage(null)
      });
    } catch (error) {
      setLoading(false);
      catchErrorAuth(error, setError);
    }
  }

  if (success) {
    return (
      <Redirect to={{ pathname: `${match.url}`, state: { refresh: true } }} />
    );
  }

  return (
    <>
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <div
        className="container-fluid p-3"
        style={{
          borderTop: "1px solid #4f4f4f",
          borderBottom: "1px solid #4f4f4f",
        }}
      >
        <div className="row pb-3" style={{ borderBottom: "solid 1px #d9d9d9" }}>
          <div className="col-1 d-flex align-items-center">
            <i
              className="bi bi-chevron-left"
              onClick={() => history.goBack()}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="col-10 d-flex justify-content-center align-items-center fs-4">
            <b>{GROUP_NAMES.get(group)?.name} 자유게시판 글 등록</b>
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="row row-cols-1 row-cols-md-2">
          <div className="col d-flex-column justify-content-center">
            <EditImg
              editedImages={editedImages}
              setEditedImages={setEditedImages}
            />
          </div>
          <div className="form-floating col my-2">
            <textarea
              className="form-control h-100"
              id="floatingTextarea2"
              name="content"
              value={post.content}
              onChange={handleChange}
            ></textarea>
            <label>내용 입력</label>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <button
          type="submit"
          className="btn btn-primary btn-md"
          onClick={handleSubmit}
        >
          글 등록
        </button>
        <button
          type="button"
          className="btn btn-primary btn-md mx-1"
          onClick={() => history.goBack()}
        >
          취소
        </button>
      </div>
    </>
  );
};

export function dataURLtoFile(dataurl: any, fileName: any) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
}

export default BandBoardWrite;
