import axios from "axios";
import { WorshipType } from "../types/heerak";
import baseUrl from "../utils/baseUrl";

const addWorship = async (worship: WorshipType) => {
  const { data } = await axios.post(`${baseUrl}/api/worships`, worship);
  return data;
};

const deleteWorship = async (id: string) => {
  const { data } = await axios.delete(`${baseUrl}/api/worships/${id}`);
  return data;
};

const getWorships = async () => {
  const { data } = await axios.get(`${baseUrl}/api/worships`);
  return data;
};

const saveWorships = async (worships: WorshipType[]) => {
  const { data } = await axios.put(`${baseUrl}/api/worships`, worships);
  return data;
};

const worshipApi = {
  addWorship,
  deleteWorship,
  getWorships,
  saveWorships,
};

export default worshipApi;
