import { ChangeEventHandler } from "react";

type Props = {
  id: string;
  checked?: boolean;
  name?: string;
  label?: string;
  handleChange?: ChangeEventHandler;
};

const SliderCheckBox = ({ id, name, checked, label = "", handleChange }: Props) => {
  return (
    <div className="form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={handleChange}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default SliderCheckBox;
