export const BandBoardCard = ({ post, setSelectedPost }: any) => {
  return (
    <div
      className="card"
      data-bs-toggle="modal"
      data-bs-target="#bandBoardModal"
      role="button"
      onClick={() => {
        setSelectedPost(post);
      }}
    >
      {post.fileInfos?.length > 0 ? (
        <img
          src={`/uploads/${post.fileInfos[0].saveName}`}
          className="card-image-top"
          alt="..."
        />
      ) : (
        <img src="" className="card-image-top" alt="..." />
      )}
      <div className="card-body">
        <p className="card-text text-truncate">{post.content}</p>
      </div>
    </div>
  );
};
