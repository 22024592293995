import axios from "axios";
import baseUrl from "../utils/baseUrl";

const readAll = async () => {
    const { data } = await axios.get(`${baseUrl}/api/banner`);
    return data
};

const readMainBanners = async () => {
  const { data } = await axios.get(`${baseUrl}/api/banner/main`);
  return data
};

const readBannerById = async (bannerId: string) => {
  const { data } = await axios.get(`${baseUrl}/api/banner/${bannerId}`);
  return data
}

const submit = async (formData: any) => {
    const { data } = await axios.post(`${baseUrl}/api/banner`, formData)
    return data
};

const remove = async (id: any) => {
    const { data } = await axios.delete(`${baseUrl}/api/banner/${id}`);
    return data
};

const updateBanner = async (id:string, formData: any) => {
  const { data } = await axios.put(`${baseUrl}/api/banner/${id}`, formData)
  return data
};

const bannerApi = {
    readAll,
    readBannerById,
    readMainBanners,
    submit,
    remove,
    updateBanner,
}

export default bannerApi