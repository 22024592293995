import { ChangeEventHandler, FormEventHandler, useEffect, useState } from "react";

export const ChangePassword = ({ serverError, setChangePassword, submitPassword }: any) => {
  const [state, setState] = useState<{
    currentPassword: string;
    password: string;
    password2: string;
  }>({
    currentPassword: "",
    password: "",
    password2: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    setError(serverError)
  }, [serverError])

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    console.log("name: ", name, ", value: ", value);
    setState({ ...state, [name]: value });
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    setError("");
    const passwordMatch = state.password === state.password2;
    if (!passwordMatch) {
      return setError("변경된 비밀번호가 일치하지 않습니다.");
    }
    console.log("match?", passwordMatch, "state", state);
    submitPassword(state.currentPassword, state.password);
  };

  return (
    <div className="border p-2">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <label htmlFor="name" className="form-label">
            현재 비밀번호
          </label>
          <div className="col-md-6">
            <input
              type="password"
              name="currentPassword"
              required
              className="form-control"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-md-6">
            <label htmlFor="name" className="col-auto col-form-label">
              변경 비밀번호
            </label>
            <div className="col">
              <input
                type="password"
                name="password"
                required
                className="form-control"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="name" className="col-auto col-form-label">
              변경 비밀번호 확인
            </label>
            <div className="col-auto">
              <input
                type="password"
                name="password2"
                required
                className="form-control"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        {error && <div className="text-danger mb-1">{error}</div>}
        <div className="mt-2">
          <button type="submit" className="btn btn-primary me-2">
            확인
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setChangePassword(false)}
          >
            취소
          </button>
        </div>
      </form>
    </div>
  );
};
