import { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import styles from "./EditImg.module.css";

type Props = { recievedImg: any, imgIdx?: number, handleCloseCrop: any, editImg: any, group: string }

const CropImg = ({ recievedImg, imgIdx, handleCloseCrop, editImg, group }: Props) => {
    const [cropped, setCropped] = useState(false)

    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState<any>();

    const cropImg = () => {
        editImg(cropData, imgIdx)
        handleCloseCrop()
    }

    const refresh = () => {
        if (typeof cropper !== "undefined") {
            setCropped(false)
            cropper.reset();
        }
    }

    const moveLeft = () => {
        if (typeof cropper !== "undefined") {
            cropper.move(-10, 0);
        }
    };

    const moveRight = () => {
        if (typeof cropper !== "undefined") {
            cropper.move(10, 0);
        }
    }

    const moveUp = () => {
        if (typeof cropper !== "undefined") {
            cropper.move(0, -10);
        }
    }

    const moveDown = () => {
        if (typeof cropper !== "undefined") {
            cropper.move(0, 10);
        }
    }

    const zoomIn = () => {
        if (typeof cropper !== "undefined") {
            cropper.zoom(0.1);
        }
    }

    const zoomOut = () => {
        if (typeof cropper !== "undefined") {
            cropper.zoom(-0.1);
        }
    }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas({
                fillColor: '#fff',
            }).toDataURL('image/jpeg'));
            setCropped(true)
        }
    };

    return (
        <div style={{ width: "100vw", height: "100vh", zIndex: 3, background: `rgba( 94, 94, 94, 0.9 )`, position: "fixed", top: "0px", left: "0px" }}>
            <div className="row justify-content-end">
                <div className="col-auto px-4"><i className="bi bi-x" style={{ fontSize: "2rem", color: "white" }} onClick={handleCloseCrop} /></div>
            </div>

            {cropped ?
                <div className="container-fluid d-flex-column">
                    <div className="row justify-content-center">
                        <div className={`col-auto mx-3 ${styles.editBar}`}>
                            <i className={`bi bi-arrow-counterclockwise ${styles.editButton}`} onClick={refresh}>다시 자르기</i>
                        </div>
                        <div className={`col-auto mx-3 ${styles.editBar}`}>
                            <i className={`bi bi-check-circle-fill ${styles.editButton}`} onClick={cropImg}>등록</i>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2">
                        <div className="col-8 col-lg-5">
                            <img style={{ width: "100%", height: "auto", overflow: "hidden" }} src={cropData} alt="cropped" />
                        </div>
                    </div>
                </div>
                :
                <div className="container-fluid d-flex-column">
                    <div className="d-flex justify-content-center my-2" style={{ width: "100%" }}>
                        <Cropper
                            style={{ height: 400, width: "90%" }}
                            initialAspectRatio={group === "banner" ? 10 / 1.8 : 1}
                            aspectRatio={group === "banner" ? 10 / 1.8 : 1} //width/height
                            preview=".img-preview"
                            src={recievedImg}
                            viewMode={1}
                            guides={true}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        />
                    </div>
                    <div className="row justify-content-center">
                        <div className={`col-auto ${styles.editBar}`}>
                            <i className={`bi bi-arrow-left ${styles.editButton}`} onClick={moveLeft} />
                            <i className={`bi bi-arrow-right ${styles.editButton}`} onClick={moveRight} />
                            <i className={`bi bi-arrow-up ${styles.editButton}`} onClick={moveUp} />
                            <i className={`bi bi-arrow-down ${styles.editButton}`} onClick={moveDown} />
                            <i className={`bi bi-zoom-in ${styles.editButton}`} onClick={zoomIn} />
                            <i className={`bi bi-zoom-out ${styles.editButton}`} onClick={zoomOut} />
                        </div>
                        <div className={`col-auto ${styles.editBar} mx-1`}>
                            <i className={`bi bi-crop ${styles.editButton}`} onClick={getCropData}>crop</i>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default CropImg;