import { useHistory, useLocation, useParams } from "react-router-dom";
import { GROUP_NAMES } from "../../utils/constants";
import { BandBoardSearchResultsList } from "./BandBoardSearchResultsList";

export const BandBoardSearch = ({ setSelectedPost }: any) => {
  const history = useHistory();
  const location =
    useLocation<{ results: any[]; from: string; keyword: string }>();
  const { group } = useParams<{ group: string }>();
  const { results, from, keyword } = location.state;
  console.log("results : ", results);
  console.log("from : ", from);
  console.log("group : ", group);
  console.log("location : ", location);

  return (
    <div>
      <BandBoardHeader
        title={GROUP_NAMES.get(group)?.name}
        goBack={() => history.goBack()}
      />
      <p>
        검색어: <span className="fw-bold">{keyword}</span>
      </p>
      <div className="row row-cols-2 row-cols-md-3 m-3 py-1">
        <BandBoardSearchResultsList results={results} setSelectedPost={setSelectedPost} />
      </div>
    </div>
  );
};

const BandBoardHeader = ({ title, goBack }: any) => {
  return (
    <div className="row pb-3" style={{ borderBottom: "solid 1px #d9d9d9" }}>
      <div className="col-1 d-flex align-items-center">
        <i
          className="bi bi-chevron-left"
          onClick={goBack}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="col-10 d-flex justify-content-center align-items-center fs-4">
        <b>{title} 자유게시판 검색 결과</b>
      </div>
    </div>
  );
};
