import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { useHistory } from "react-router-dom";
import { MinisterType } from "../../types/heerak";
import ministerApi from '../../apis/minister.api'

export const AddMinister = () => {
  const history = useHistory();
  const [state, setState] = useState<MinisterType>({
    id: "",
    name: "",
    kind: "",
    role: "",
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    console.log("handle submit", state);
    const result = await ministerApi.addMinister(state);
    console.log("added minister", result);
    history.replace("/info/minister", { refresh: true });
  };

  return (
    <div>
      <h5>섬기는 사람들 추가</h5>
      <form onSubmit={handleSubmit}>
        <div className="row mb-2">
          <label htmlFor="role" className="form-label">
            직분
          </label>
          <div className="col-auto">
            <input
              className="form-control"
              type="text"
              name="role"
              value={state.role}
              required
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-2">
          <label htmlFor="name" className="form-label">
            이름
          </label>
          <div className="col-auto">
            <input
              className="form-control"
              type="text"
              name="name"
              value={state.name}
              required
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <button type="submit" className="btn btn-primary me-2">
            확인
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => history.replace("/info/minister")}
          >
            취소
          </button>
        </div>
      </form>
    </div>
  );
};
