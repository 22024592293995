import { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import mediaApi from "../../apis/media.api";
import { useAuth } from "../../context/auth_context";
import { AlertMessage } from "../../types/heerak";
import Title from "../Title";
import ToastAlert from "../ToastAlert";

export const AddChannel = () => {
  const { catchErrorAuth } = useAuth();
  const history = useHistory();
  const [channel, setChannel] = useState({ url: "", show: false });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  function handleChannelChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const { name, value } = e.target;
    console.log(`name: ${name}, value: ${value}`);
    setChannel({ ...channel, [name]: value });
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = e.target;
    console.log(`name: ${name}, checked: ${checked}`);
    setChannel({ ...channel, [name]: checked });
  }

  async function handleChannelSubmit(e: any) {
    e.preventDefault();
    if (channel) {
      try {
        setLoading(true);
        setError("");

        console.log("channel submit", channel);
        await mediaApi.postChannel(channel);

        setLoading(false);
        setAlertMessage({
          message: "채널 추가가 완료되었습니다.",
          msgType: "success",
          closeFunction: () =>
            history.push("/admin/media/channel", { refresh: true }),
        onCancel: () => setAlertMessage(null),
      });
      } catch (error) {
        setLoading(false);
        catchErrorAuth(error, setError);
      }
    } else {
      setAlertMessage({
        message: "올바른 형식의 주소를 입력하세요",
        msgType: "error",
        closeFunction: () => setAlertMessage(null),
        onCancel: () => setAlertMessage(null),
      });
    }
  }

  return (
    <div>
      <Title>
        <h5 className="mt-5">채널 추가</h5>
      </Title>
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleChannelSubmit}>
        <textarea
          name="url"
          className="form-control my-4"
          placeholder="채널 주소를 입력하세요"
          id="url"
          value={channel.url}
          style={{ resize: "none" }}
          onChange={handleChannelChange}
        ></textarea>
        <div className="form-check">
          <label className="form-check-label" htmlFor="show">
            홈페이지 보이기
          </label>
          <input
            type="checkbox"
            name="show"
            id="show"
            className="form-check-input"
            onChange={handleInputChange}
          />
        </div>
        <div className="d-flex gap-2 mt-4">
          <button className="btn btn-primary" type="submit">
            등록
          </button>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => history.goBack()}
          >
            취소
          </button>
        </div>
      </form>
    </div>
  );
};
