import { Link } from "react-router-dom";

const InfoDropdown = () => {
  return (
    <>
      <div
        className="nav-link"
        id="infoDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        교회소개
      </div>
      <ul className={`dropdown-menu`} aria-labelledby="infoDropdown">
        <Link to="/info/intro" className="dropdown-item">
          <li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
            교회안내
          </li>
        </Link>
        <Link to="/info/senior-pastor" className="dropdown-item">
          <li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
            담임목사
          </li>
        </Link>
        <Link to="/info/minister" className="dropdown-item">
          <li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
            섬기는 사람들
          </li>
        </Link>
        <Link to="/info/map" className="dropdown-item">
          <li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
            오시는 길
          </li>
        </Link>
      </ul>
    </>
  );
};

export default InfoDropdown;
