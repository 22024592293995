import { ChangeEventHandler, useEffect, useState } from "react";
import SliderCheckBox from "./SliderCheckBox";
import { Role, User } from "../../types/heerak";

type MemberItemParams = {
  roles: Role[];
  user: User;
  deleteUser: Function;
  updateUser: Function;
};

export const MemberItem = ({
  roles,
  user,
  deleteUser,
  updateUser,
}: MemberItemParams) => {
  const [member, setMember] = useState(user);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setMember(user);
  }, [user]);

  const getRoleName = (roleId: string) => {
    const role = roles.find((role: any) => role.id === roleId);
    return role?.name;
  };

  const onSave = () => {
    setIsEdit(false);
    updateUser(member);
  };

  const onCancel = () => {
    setIsEdit(false);
    setMember(user);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value, checked } = event.target;
    setMember({ ...member, [name]: name === "isMember" ? checked : value });
  };

  const handleRole: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { name, value } = event.target;
    setMember({ ...member, [name]: value });
  };

  return (
    <tr key={user.id}>
      <td className="text-center">
        {isEdit ? (
          <SliderCheckBox
            id={user.id}
            name="isMember"
            checked={member.isMember}
            handleChange={handleChange}
          />
        ) : user.isMember ? (
          "예"
        ) : (
          "아니오"
        )}
      </td>
      <td className="text-center">{member.email}</td>
      <td className="text-center">{member.name}</td>
      <td className="text-center">
        {isEdit ? (
          <select
            name="roleId"
            id="role"
            value={member.roleId}
            onChange={handleRole}
          >
            {roles.map((role) => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          </select>
        ) : (
          getRoleName(member.roleId)
        )}
      </td>
      <td className="text-center">
        {isEdit ? (
          <>
            <button className="btn btn-primary me-2" onClick={onSave}>
              저장
            </button>
            <button className="btn btn-primary" onClick={onCancel}>
              취소
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-primary me-2"
              onClick={() => setIsEdit(true)}
            >
              수정
            </button>
            <button
              className="btn btn-primary"
              onClick={() => deleteUser(user.id)}
            >
              삭제
            </button>
          </>
        )}
      </td>
    </tr>
  );
};
