import { Link, useHistory, useParams } from "react-router-dom";
import Title from "../components/Title";
import NoticeList from "../components/NoticeList";
import { GROUP_NAMES } from "../utils/constants";
import { useAuth } from "../context/auth_context";

interface ParamTypes {
  group: string;
}

const NoticeListPage = () => {
  const history = useHistory(); // test purpose!!!
  const { group } = useParams<ParamTypes>();
  const { user } = useAuth();
  console.log("history in notice list page", history);

  return (
    <div className="d-flex flex-column">
      <Title>{GROUP_NAMES.get(group)?.name}</Title>
      {((group === "notice" &&
        ["staff", "admin"].includes(user?.role || "user")) ||
        (group === "other" && user?.isMember)) && (
        <Link
          to={`/write/${group}`}
          className="btn btn-primary text-white mb-4 align-self-end"
        >
          글 등록
        </Link>
      )}
      <NoticeList group={group} />
    </div>
  );
};

export default NoticeListPage;
