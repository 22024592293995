import { useEffect, useState } from "react";
import userApi from "../../apis/user.api";
import { useAuth } from "../../context/auth_context";
import { AlertMessage, User } from "../../types/heerak";
import ToastAlert from "../ToastAlert";
import { MembersList } from "./MembersList";

const MemberManager = () => {
  const { catchErrorAuth } = useAuth();
  const [error, setError] = useState("");
  const [roles, setRoles] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  useEffect(() => {
    updateUsers();
  }, []);

  const updateUsers = async () => {
    try {
      const users = await userApi.getUsers();
      const roles = await userApi.getAllRoles();
      console.log("fetch users", users, "roles: ", roles);
      setUsers(users);
      setRoles(roles);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const updateUser = async (user: User) => {
    console.log("user to update", user);
    const userUpdated = await userApi.updateUser(user);
    console.log("updated user", userUpdated);
    await updateUsers();
  };

  const deleteUser = async (userId: string) => {
    console.log("user to be deleted", userId);
    setAlertMessage({
      message: `삭제하시겠습니까?`,
      msgType: "confirm",
      closeFunction: async () => {
        const result = await userApi.removeUser(userId);
        console.log("user deleted", result);
        await updateUsers();
        setAlertMessage(null);
      },
      onCancel: () => setAlertMessage(null),
    });
  };

  if (error) {
    alert(error);
    setError("");
  }

  return (
    <div>
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <MembersList
        roles={roles}
        users={users}
        deleteUser={deleteUser}
        updateUser={updateUser}
      />
    </div>
  );
};

export default MemberManager;
