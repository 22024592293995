import BandPrayCard from "./BandPrayCard";

type Props = { cards: any; setData: any; deletePray: any; setWriteMode: any };

const BandPrayCardsList = ({
  cards,
  setData,
  deletePray,
  setWriteMode,
}: Props) => {
  return (
    <div className="row row-cols-1">
      {cards.importantPrays.map((card: any) => (
        <BandPrayCard
          key={card.id}
          card={card}
          cardType="important"
          setData={setData}
          setWriteMode={setWriteMode}
          deletePray={deletePray}
        />
      ))}
      {cards.normalPrays.map((card: any) => (
        <BandPrayCard
          key={card.id}
          card={card}
          cardType="normal"
          setData={setData}
          setWriteMode={setWriteMode}
          deletePray={deletePray}
        />
      ))}
      {cards.finishedPrays.map((card: any) => (
        <BandPrayCard
          key={card.id}
          card={card}
          cardType="finished"
          setData={setData}
          setWriteMode={setWriteMode}
          deletePray={deletePray}
        />
      ))}
    </div>
  );
};

export default BandPrayCardsList;
