import axios from "axios";
import { useEffect, useState } from "react";
import mediaApi from "../../apis/media.api";
import { useAuth } from "../../context/auth_context";
import baseUrl from "../../utils/baseUrl";

export const MediaList = () => {
  const { catchErrorAuth } = useAuth();
  const [churchVideoList, setChurchVideoList] = useState<any[]>([]);
  const [heerakVideoList, setHeerakVideoList] = useState<any[]>([]);
  const [channelList, setChannelList] = useState<any[]>([]);
  const [error, setError] = useState("");

  useEffect(() => {
    getHeerakVideos();
    getChurchVideos();
    getChannels();
  }, []);

  const getHeerakVideos = async () => {
    try {
      const kind = "heerak";

      const videos = await mediaApi.getVideo(kind);

      if (videos.length > 0) {
        setHeerakVideoList(videos);
      }
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const getChurchVideos = async () => {
    try {
      const kind = "church";

      const videos = await mediaApi.getVideo(kind);
      console.log("church videos:", videos);
      if (videos.length > 0) {
        setChurchVideoList(videos);
      }
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const getChannels = async () => {
    try {
      const channels = await mediaApi.getChannels();
      setChannelList(channels);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  return (
    <>
      <div className="container-fluid ms-3">
        <div
          className="row justify-content-center align-items-center "
          style={{ height: "4rem" }}
        >
          <div className="col">
            <h3>
              <b>HEERAK VIDEO</b>
            </h3>
            <h6>희락 교회 동영상페이지</h6>
          </div>
        </div>
        <div className="row justify-content-end ">
          <div className="col-5 col-lg-3">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="boardSearchWord"
                placeholder="검색"
              />
              <button type="submit" className="btn btn-outline-primary">
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          {channelList.map((channel: any) => {
            return (
              <div className="col-6 col-xl-2 col-md-4 text-center">
                <a href={channel.url}>
                  <img
                    style={{ borderRadius: 130, blockSize: 150 }}
                    className="p-3"
                    src={channel.thumbnail}
                    alt=""
                  />
                </a>
                <a href={channel.url} style={{ textDecoration: "none" }}>
                  <div className="text-dark">{channel.title}</div>
                </a>
              </div>
            );
          })}
        </div>
        <div style={{ borderTop: "1px solid #4f4f4f" }}>
          <h4 className="mt-3">교회동영상</h4>
          <div className=" border-start border-5 border-danger row my-4 py-1 flex-nowrap overflow-auto">
            {heerakVideoList.map((video, index) => (
              <a href={video.url} style={{ maxWidth: "max-content" }}>
                <div
                  className="card col p-1 my-2"
                  style={{ height: "23rem", width: "25rem" }}
                >
                  <div
                    className="card-header"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {video.title}
                  </div>
                  <div className="card-body">
                    <img
                      src={video.thumbnail}
                      className="img-fluid"
                      alt="..."
                      style={{
                        height: "15rem",
                        width: "25rem",
                      }}
                    />
                  </div>
                  <div
                    className="card-footer "
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {video.duration}
                  </div>
                </div>
              </a>
            ))}
          </div>

          <h4 className="mt-3">희락동영상</h4>
          <div className="border-start border-5 border-danger row flex-nowrap overflow-auto my-4 py-1">
            {churchVideoList.map((video, index) => (
              <a href={video.url} style={{ maxWidth: "max-content" }}>
                <div
                  className="card col p-1 my-2"
                  data-bs-toggle="modal"
                  data-bs-target="#examplePost"
                  style={{ height: "23rem", width: "25rem" }}
                >
                  <div
                    className="card-header"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {video.title}
                  </div>
                  <div className="card-body">
                    <img
                      src={video.thumbnail}
                      className="img-fluid"
                      alt="..."
                      style={{
                        height: "15rem",
                        width: "25rem",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    />
                  </div>
                  <div
                    className="card-footer "
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {video.duration}
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
