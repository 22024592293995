import { Link } from "react-router-dom";

export const EventComponent = ({ contents, link }: any) => {
  console.log('contents in event compoent', contents);
  return (
    <div className="col-12 col-md-4 ">
      <div className="card m-3 mb-3" style={{ border: "none" }}>
        <div className="card-header">
          행사
          <Link
            to="/event"
            className="card-link text-decoration-none"
            style={{ float: "right", color: "gray" }}
          >
            더보기 +
          </Link>
        </div>
        <div className="card-body p-0">
          {contents.map((content: string, index: number) => {
            return (
              <li
                key={index}
                className="list-group-item ps-2 d-flex justify-content-beteween"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <span
                  className="col-auto me-2 bg-primary"
                  style={
                    content.split(":")[0] === ""
                      ? { display: "none" }
                      : {
                          fontSize: "1em",
                          color: "white",
                          overflow: "hidden",
                          borderRadius: "8px",
                        }
                  }
                >
                  {content.split(":")[0]} ~
                </span>
                <span className="col text-center text-truncate">
                  {content.split(":")[1]}
                </span>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
};
