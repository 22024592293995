import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import commentApi from "../../apis/comment.api";
import { useAuth } from "../../context/auth_context";

type CommentProps = { postId: string };

const Comment = ({ postId }: CommentProps) => {
  const { catchErrorAuth } = useAuth();
  const { group } = useParams<{ group: string }>();
  const [comments, setComments] = useState([]);
  const [content, setContent] = useState("");

  useEffect(() => {
    const fetchComments = async () => {
      const comments = await commentApi.getComments(group, postId, 1, 100);
      console.log("comments", comments);
      setComments(comments);
    };
    fetchComments();
  }, [postId, group]);

  const deleteComment = async (commentId:string) => {
    try {
      const updatedComments = await commentApi.removeComment(commentId);
      setComments(updatedComments);
    } catch (error) {
      catchErrorAuth(error, () => console.log(error))      
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setContent(value);
  };

  const handleSubmit = async () => {
    console.log("comment submitted");
    const comment = { content, group, bandBoardId: postId };
    console.log("comment", comment);
    try {
      const updatedComments = await commentApi.createComment(comment);
      setComments(updatedComments);
      console.log("new comment created", updatedComments);
    } catch (error) {
      catchErrorAuth(error, () => {
        console.log(error);
      });
    }
  };

  return (
    <>
      <div className="overflow-auto p-2 h-50">
        <ul style={{ listStyle: "none", paddingLeft: "0" }}>
          {comments.map((comment: any) => (
            <li>
              <i
                className="bi bi-trash-fill px-1"
                role="button"
                onClick={() => deleteComment(comment.id)}
              />
              <b className="me-2">{comment.user.name}</b>
              {comment.content}
            </li>
          ))}
        </ul>
      </div>
      <div className="row justify-content-end">
        <div className="col-10">
          <input
            type="text"
            className="form-control"
            placeholder="댓글 달기..."
            name="content"
            value={content}
            onChange={handleChange}
          />
        </div>
        <button
          type="button"
          className="btn btn-outline-primary col-2"
          onClick={handleSubmit}
        >
          게시
        </button>
      </div>
    </>
  );
};

export default Comment;
