import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

type SearchParams = {
  searchList?: string[];
  keyword?: string;
  getSearchResults: (group:string, category: string, keyword: string) => Promise<any>;
};

const Search = ({ getSearchResults }: SearchParams) => {
  const history = useHistory();
  // const match = useRouteMatch(history.location.pathname)
  const { group } = useParams<{ group: string }>();
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("titleOrContent");

  // console.log('match in search comp', match);
  console.log("match in search group", group);

  const handleSearch = async () => {
    try {
      if (!keyword.trim()) {
        setKeyword("")
        return;
      }
      const results = await getSearchResults(group, category, keyword);
      console.log("results in Search component", results);
      history.push("/search", {
        results,
        from: `${history.location.pathname}`,
        keyword,
      });
    } catch (error) {}
  };

  return (
    <div className="d-flex justify-content-center mb-4 bg-info">
      <div className="input-group">
        <select
          id="sort"
          className={`form-select`}
          aria-label="sort Select"
          defaultValue="titleOrContent"
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="titleOrContent">제목+내용</option>
          <option value="author">작성자</option>
        </select>
        <input
          type="text"
          className={`form-control w-50`}
          id="inputSearchWord"
          placeholder="검색어 입력"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <button
          type="submit"
          className={`btn btn-primary text-white`}
          onClick={handleSearch}
        >
          <i className="bi bi-search"></i>
        </button>
      </div>
    </div>
  );
};

export default Search;
