import {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from "react";

export const ProfileForm = ({
  error,
  profile,
  submitProfile,
  isChangeProfile,
  setIsChangeProfile,
}: any) => {
  const [state, setState] = useState({ name: "", email: "" });

  useEffect(() => {
    setState(profile);
  }, [profile]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    console.log("state:", state);
    setIsChangeProfile(false)
    submitProfile(state)
  };

  const onCancel = () => {
    setIsChangeProfile(false);
    setState(profile);
  };

  return (
    <div className="border p-2 mb-2">
      <form onSubmit={handleSubmit}>
        <div className="row mb-2">
          <label htmlFor="name" className="col-sm-2 col-form-label">
            이름
          </label>
          <div className="col-md-6">
            <input
              className="form-control"
              type="text"
              name="name"
              disabled={!isChangeProfile}
              value={state?.name}
              required
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <label htmlFor="name" className="col-sm-2 col-form-label">
            이메일
          </label>
          <div className="col-md-6">
            <input
              className="form-control"
              type="email"
              name="email"
              disabled={!isChangeProfile}
              value={state?.email}
              required
              onChange={handleChange}
            />
          </div>
        </div>
        {error && <div className="text-danger mb-1">{error}</div>}
        {isChangeProfile && (
          <div className="mt-2">
            <button type="submit" className="btn btn-primary me-2">
              확인
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={onCancel}
            >
              취소
            </button>
          </div>
        )}
      </form>
    </div>
  );
};
