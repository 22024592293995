import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { useHistory } from "react-router-dom";
import worshipApi from "../../apis/worship.api";
import { WorshipType } from "../../types/heerak";

export const WorshipAdd = () => {
  const history = useHistory();
  const [state, setState] = useState<WorshipType>({
    id: "",
    name: "",
    time: "",
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    console.log("handle submit", state);
    const result = await worshipApi.addWorship(state);
    console.log("added worship", result);
    history.replace("/admin/worship", { refresh: true });
  };

  return (
    <div>
      <h5>예배 추가</h5>
      <form onSubmit={handleSubmit}>
        <div className="row mb-2">
          <label htmlFor="name" className="form-label">
            예배 이름
          </label>
          <div className="col-auto">
            <input
              className="form-control"
              type="text"
              name="name"
              value={state.name}
              required
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-2">
          <label htmlFor="time" className="form-label">
            예배 시간
          </label>
          <div className="col-auto">
            <input
              className="form-control"
              type="text"
              name="time"
              value={state.time}
              required
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <button type="submit" className="btn btn-primary me-2">
            확인
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => history.replace("/admin/worship")}
          >
            취소
          </button>
        </div>
      </form>
    </div>
  );
};
