import axios from "axios";
import { createContext, FC, useCallback, useContext, useState } from "react";
import authApi from "../apis/auth.api";
import { getLocalUser } from "../utils/auth";
import baseUrl from "../utils/baseUrl";
import catchErrors from "../utils/catchErrors";
import config from "../utils/clientConfig";

type AuthContextType = {
  error: string;
  user: { userId: number; name: string; role: string; isMember: boolean } | null;
  loading: boolean;
  setUser: (user: any) => void;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
  catchErrorAuth: (error: any, displayError: Function) => void;
};

const AuthContext = createContext<AuthContextType>({
  error: "",
  loading: false,
  user: null,
  setUser: () => {},
  login: () => Promise.resolve(false),
  logout: () => {},
  catchErrorAuth: (error, displayError) => {},
});

const AuthProvider: FC = ({ children }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(getLocalUser());

  const login = useCallback(async (email: string, password: string) => {
    try {
      setError("");
      setLoading(true);
      const user = await authApi.login(email, password);
      localStorage.setItem(config.loginUser, JSON.stringify(user));
      setUser(user);

      return true;
    } catch (error) {
      catchErrors(error, setError);
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      setError("");
      setUser(null);
      localStorage.removeItem(config.loginUser);
      setLoading(true);
      await axios.get(`${baseUrl}/api/auth/logout`);
      // alert("로그아웃 되었습니다");
    } catch (error) {
      catchErrors(error, setError);
    } finally {
      setLoading(false);
    }
  }, []);

  const catchErrorAuth = useCallback(async (error, displayError) => {
    let errorMsg;
    if (error.response) {
      if (typeof error.response.data === "string") {
        errorMsg = error.response.data;
        console.log("Error response:", errorMsg);
      } else {
        const { data } = error.response;
        if (data.redirectUrl) {
          errorMsg = data.message;
          console.log("Error response with redirected message:", errorMsg);
          console.log("redirect url", data.redirectUrl);
          displayError(errorMsg);
          return await logout();
        }
      }
    } else if (error.request) {
      errorMsg = error.request;
      console.log("Error request:", errorMsg);
    } else {
      errorMsg = error.message;
      console.log("Error message:", errorMsg);
    }

    displayError(errorMsg);
  }, []);

  return (
    <AuthContext.Provider
      value={{ error, loading, user, setUser, login, logout, catchErrorAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
