import { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import mediaApi from "../../apis/media.api";
import { useAuth } from "../../context/auth_context";
import { AlertMessage } from "../../types/heerak";
import Title from "../Title";
import ToastAlert from "../ToastAlert";

export const EditChannel = () => {
  const { catchErrorAuth } = useAuth();
  const history = useHistory();
  const { channelId } = useParams<{ channelId: string }>();
  const [channel, setChannel] = useState({
    id: "",
    show: false,
    url: "",
  });
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchChannel = async () => {
      const channel = await mediaApi.getChannelById(channelId);
      setChannel(channel);
    };

    fetchChannel();
  }, []);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = e.target;
    console.log(`name: ${name}, value: ${checked}`);
    setChannel({ ...channel, [name]: checked });
  }

  async function handleEditChannel(e: any) {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");

      console.log("channel submit", channel);
      await mediaApi.editChannelById(channelId, channel);

      setLoading(false);
      setAlertMessage({
        message: "채널 수정이 완료되었습니다.",
        msgType: "success",
        closeFunction: () =>
          history.push("/admin/media/channel", { refresh: true }),
        onCancel: () => setAlertMessage(null),
      });
    } catch (error) {
      setLoading(false);
      catchErrorAuth(error, setError);
    }
  }

  return (
    <div>
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <Title>
        <h5 className="mt-5">채널 수정</h5>
      </Title>
      <form onSubmit={handleEditChannel}>
        <textarea
          name="show"
          className="form-control mt-4"
          id="show"
          value={channel.url}
          style={{ resize: "none" }}
          disabled
        ></textarea>
        <div className="row">
          <div className="col-md">
            <div className="form-check">
              <input
                type="checkbox"
                id="check-show"
                name="show"
                className="form-check-input"
                checked={channel.show}
                onChange={handleChange}
              />
              <label htmlFor="check-show" className="form-check-label">
                보이기
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex gap-2 mt-4">
          <button className="btn btn-primary" type="submit">
            확인
          </button>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => history.goBack()}
          >
            취소
          </button>
        </div>
      </form>
    </div>
  );
};
