import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../context/auth_context";
import ErrorPage from "../pages/ErrorPage";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          if (rest.role) {
            if (rest.role === user.role) {
              return <Component {...props} />;
            } else {
              return <ErrorPage />
            }
          } else {
            return <Component {...props} />
          }
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

export default PrivateRoute;
