import { ChangeEventHandler, useEffect, useState } from "react";
import { WorshipType } from "../../types/heerak";

type WorshipItemParams = {
  worship: WorshipType;
  updateWorship: Function;
  deleteWorship: Function;
};

export const WorshipItem = ({
  worship,
  updateWorship,
  deleteWorship,
}: WorshipItemParams) => {
  const [isEditing, setIsEditing] = useState(false);
  const [state, setState] = useState({ name: "", time: "" });

  useEffect(() => {
    setState(worship);
  }, [worship]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleConfirm = () => {
    console.log("on confirm", state);
    setIsEditing(false);
    updateWorship(state);
  };

  const onCancel = () => {
    setIsEditing(false);
    setState({...worship})
  }

  return (
    <>
      <td>
        {isEditing ? (
          <input name="name" value={state.name} onChange={handleChange} />
        ) : (
          state.name
        )}
      </td>
      <td>
        {isEditing ? (
          <input name="time" value={state.time} onChange={handleChange} />
        ) : (
          state.time
        )}
      </td>
      <td>
        {isEditing ? (
          <>
            <button
              className="btn btn-primary me-2"
              onClick={() => handleConfirm()}
            >
              확인
            </button>
            <button
              className="btn btn-primary me-2"
              onClick={() => onCancel()}
            >
              취소
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-primary me-2"
              onClick={() => setIsEditing(true)}
            >
              수정
            </button>
            <button
              className="btn btn-primary me-2"
              onClick={() => deleteWorship(worship.id)}
            >
              삭제
            </button>
          </>
        )}
      </td>
    </>
  );
};
