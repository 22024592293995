import { Link } from "react-router-dom"

const ErrorPage = () => {
  return (
    <div>
      Error Page
      <Link to="/home">Go Home</Link>
    </div>
  )
}

export default ErrorPage
