import { MemberItem } from "./MemberItem";
import { Role, User } from "../../types/heerak";

type MembersParams = {
  roles: Role[];
  users: User[];
  deleteUser: Function;
  updateUser: Function;
};

export const MembersList = ({
  roles,
  users,
  deleteUser,
  updateUser,
}: MembersParams) => {
  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="text-center">교인</th>
            <th className="text-center">이메일</th>
            <th className="text-center">이름</th>
            <th className="text-center">권한</th>
            <th className="text-center">수정/삭제</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 &&
            users.map((user: any) => {
              return (
                <MemberItem
                  key={user.id}
                  roles={roles}
                  user={user}
                  deleteUser={deleteUser}
                  updateUser={updateUser}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
