import { useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import styles from "./Event.module.scss";

const EventCalendar = ({ calendarEvents, setDate }: any) => {
  const calendarRef = useRef<FullCalendar>(null);

  const prevMonth = () => {
    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const nextMonth = () => {
    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const todayMonth = () => {
    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  return (
      <div className={styles.calendar}>
        <div className="d-flex justify-content-between">
          <h3>
            {calendarRef.current
              ? calendarRef.current.getApi().currentDataManager?.getCurrentData().viewTitle
              : null}
          </h3>
          <div>
            <button onClick={prevMonth}>{"<"}</button>
            <button onClick={todayMonth}>오늘</button>
            <button onClick={nextMonth}>{">"}</button>
          </div>
        </div>
        <FullCalendar
          ref={calendarRef}
          locale="ko"
          headerToolbar={{
            left: "",
            center: "",
            right: "",
          }}
          dayMaxEvents={2}
          moreLinkClick="popover"
          height="auto"
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={calendarEvents}
          defaultAllDay={true}
        />
      </div>
  );
};

export default EventCalendar;
