import { useState, useEffect } from "react";
import initialCrop from "./InitialCrop";
import CropImg from "./CropImg";
import "cropperjs/dist/cropper.css";
import styles from "./EditImg.module.css"

type Props = { recievedImg: any, imgIdx: number, handleCloseCrop: any, editImg: any }

const INIT_SELECTEDIMG={idx: 0, img: ''}

const EditImg = ({editedImages, setEditedImages}: any) => {
    const [orgImages, setOrgImages] = useState<any>([])

    const [showCrop, setShowCrop] = useState(false);

    const [selectedImg, setSelectedImg] = useState(INIT_SELECTEDIMG);

    const handleShowCrop = () => setShowCrop(true)
    const handleCloseCrop = () => setShowCrop(false)

    const handleFileOnChange = (event: any) => {
        if (event.target.files.length) {
            for (let i = 0; i < event.target.files.length; i++) {
                let imgTarget = event.target.files[i]
                let reader = new FileReader();
                reader.readAsDataURL(imgTarget);
                reader.onload = function (e: any) {
                    orgImages.push(e.target.result);
                    initialCrop(e.target.result)
                        .then((initialCroppedImg: any) => {editedImages.push(initialCroppedImg.toDataURL())
                            setEditedImages([...editedImages])});
                }
            }
        } else {

        }
    }

    const showEditor = (idx: number) => {
        setSelectedImg({idx: idx, img: orgImages[idx]})
        handleShowCrop()
    }

    const editImg = (img: string, idx: number) => {
        editedImages.splice(idx, 1, img)
        setSelectedImg(INIT_SELECTEDIMG)
        setEditedImages([...editedImages])
    }

    const deleteImg = (idx: number) => {
        editedImages.splice(idx, 1)
        setEditedImages([...editedImages])
        orgImages.splice(idx, 1)
    }

    useEffect(() => {
        setOrgImages([...editedImages])
    }, [editedImages])

    return (
        <div>
            {console.log(orgImages)}
            { showCrop ? <CropImg recievedImg={selectedImg['img']} imgIdx={selectedImg['idx']} handleCloseCrop={handleCloseCrop} editImg={editImg} group="group" /> : null}

            <div className="container-fluid row row-cols-3">
                {editedImages.map((img: string, index: number) =>
                    <div className="col" style={{ padding: "2px" }}>
                        <div className="card">
                            <img src={img} className="img-fluid" alt="..." style={{ width: "100%", height: "auto", overflow: "hidden" }} />
                            <div className="card-img-overlay justify-content-end">
                                <i className={`bi bi-pencil-square ${styles.imgButton}`} onClick={() => showEditor(index)} />
                                <i className={`bi bi-trash ${styles.imgButton}`} onClick={() => deleteImg(index)} />
                            </div>
                        </div>
                    </div>
                )}
                <div className="col" style={{ padding: "2px" }}>
                    <div className="card" style={{ width: "100%", height: "30px"}}>
                        <div className={`${styles.filebox}`}>
                            <label htmlFor="inputImg"><i className="bi bi-plus-circle" /></label>
                            <input type="file"
                                id="inputImg"
                                accept="image/*"
                                onChange={handleFileOnChange}
                                multiple
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditImg;