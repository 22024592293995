import { ChangeEvent, useEffect, useState } from "react";
import Title from "../Title";
import { useAuth } from "../../context/auth_context";
import catchErrors from "../../utils/catchErrors";
import { FileInfo } from "../../types/heerak";

type Props = {
  direction: {
    fileInfo: FileInfo;
    data: {
      src: string;
      address: string;
      phone: string;
      files: FileList | null;
    };
  };
  updateOrCreateInfo: (category: string, infoData: object) => void;
};

const Direction = ({ direction, updateOrCreateInfo }: Props) => {
  const { user } = useAuth();
  const [error, setError] = useState("");
  const [info, setInfo] = useState(direction.data);
  const [isEdit, setIsEdit] = useState(false);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value, files } = e.target;
    if (files) {
      setInfo({ ...info, [name]: files });
    } else {
      setInfo({ ...info, [name]: value });
    }
  }

  function handleSubmit() {
    // e.preventDefault();
    try {
      setError("");
      const formData = new FormData();
      formData.append("src", info["src"]);
      formData.append("address", info["address"]);
      formData.append("phone", info["phone"]);
      // formData.append("content", JSON.stringify(content));
      if (info.files) {
        formData.append("file", info.files[0]);
      }
      updateOrCreateInfo("direction", formData);
    } catch {
      catchErrors(error, setError);
    } finally {
    }
  }

  useEffect(() => {
    console.log("direction in direction", direction);
    setInfo(direction.data);
    setIsEdit(false);
  }, [direction]);

  return (
    <>
      <Title>
        <div className="d-flex justify-content-between mb-2">
          오시는 길
          {user?.role === "admin" ? (
            isEdit ? (
              <div>
                <button
                  className="btn btn-primary text-white me-2"
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  확인
                </button>

                <button
                  className="btn btn-primary text-white"
                  onClick={() => {
                    setIsEdit(!isEdit);
                    setInfo(direction.data);
                  }}
                >
                  취소
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="btn btn-primary text-white"
                  onClick={() => setIsEdit(!isEdit)}
                >
                  수정
                </button>
              </div>
            )
          ) : null}
        </div>
      </Title>

      {isEdit ? (
        <div className="row d-flex justify-content-center mx-2">
          <form className="w-100" onSubmit={handleSubmit}>
            <p>
              주소 :
              <input
                type="text"
                className="form-control"
                id="addressInput"
                name="address"
                value={info.address}
                onChange={handleChange}
              />
              <br />
              연락처 :
              <input
                type="text"
                className="form-control"
                id="phoneInput"
                name="phone"
                value={info.phone}
                onChange={handleChange}
              />
            </p>
            <div className="">
              <div>
                지도링크 :
                <input
                  type="text"
                  className="form-control mb-5"
                  id="srcInput"
                  name="src"
                  value={info.src}
                  onChange={handleChange}
                />
              </div>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                id="fileInput"
                name="files"
                onChange={handleChange}
              />
            </div>
          </form>
        </div>
      ) : (
        <div className="row d-flex justify-content-center mx-2">
          <div className="w-100">
            <p>
              주소 : {info.address}
              <br />
              연락처 : {info.phone}
            </p>
            <div className="d-flex justify-content-space">
              <div>
                <a
                  style={{ height: "50px", textDecoration: "none" }}
                  href={info.src}
                >
                  <img
                    className="img-fluid"
                    alt={`direction map`}
                    style={{ objectFit: "cover" }}
                    src={`/uploads/${direction.fileInfo?.saveName}`}
                  />
                  ※ 지도를 클릭하시면 네이버지도로 연결됩니다.
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Direction;
