import axios from "axios";
import baseUrl from "../utils/baseUrl";
import config from "../utils/clientConfig";

const login = async (email: string, password: string): Promise<any> => {
  const payload = { email, password };
  const { data } = await axios.post(`${baseUrl}/api/auth/login`, payload);
  return data;
};

const logout = async () => {
  alert("로그아웃 되었습니다.");
  localStorage.removeItem(config.loginUser);
  await axios.get(`${baseUrl}/api/auth/logout`);
};

const signup = async (user: any) => {
  const url = `${baseUrl}/api/auth/signup`;
  await axios.post(url, user);
};

const authApi = {
  login,
  logout,
  signup,
};

export default authApi;