import { Toast } from "bootstrap";
import { useState, useEffect } from "react";
import { useRef } from "react";
import { AlertMessage } from "../types/heerak";

const INIT_TOASTSTYLE = {
  color: "#476bbf",
  icon: "bi bi-info-circle-fill",
};

const ToastAlert = ({ message, msgType, closeFunction, onCancel, style }: AlertMessage) => {
  const toastRef = useRef<HTMLDivElement>(null);
  const focusRef = useRef<HTMLButtonElement>(null);

  const [toastStyle, setToastStyle] = useState(INIT_TOASTSTYLE);

  useEffect(() => {
    setStyle();

    if (toastRef.current) {
      const toast = new Toast(toastRef.current);
      toast.show();
      focusRef.current?.focus();
    }
  }, [message]);

  function setStyle() {
    switch (msgType) {
      case "error":
        setToastStyle({
          color: "#d64238",
          icon: "bi bi-exclamation-triangle-fill",
        });
        break;
      case "success":
        setToastStyle({ color: "#439c67", icon: "bi bi-check-circle-fill" });
        break;
      case "confirm":
        setToastStyle({ color: "#f7b119", icon: "bi bi-question-circle" });
        break;
      default:
        setToastStyle(INIT_TOASTSTYLE);
        break;
    }
  }

  return (
    <div
      className="toast-container position-fixed top-0 start-50 p-3"
      style={style}
      id="toastPlacement"
    >
      <div
        ref={toastRef}
        className={`toast align-items-center border-0 bg-white translate-middle-x`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-bs-autohide="false"
        style={{ borderRadius: "0" }}
      >
        <div>
          <div className="toast-body px-2 py-3">
            <div
              className="d-flex justify-content-between align-items-top px-3"
              style={{ fontWeight: "bold" }}
            >
              <div className="d-flex align-items-top">
                <i
                  className={`${toastStyle.icon} pe-3`}
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    color: toastStyle.color,
                  }}
                />
                <div className="justify-content-center" style={{marginTop: "4px"}} >{message}</div>
              </div>
              <button
                ref={focusRef}
                type="button"
                className="btn-close btn-close-gray"
                data-bs-dismiss="toast"
                aria-label="Close"
                onClick={msgType === "confirm" ? onCancel : closeFunction}
                style={{ fontSize: "0.7rem", marginLeft: "5px", marginTop: "4px" }}
              ></button>
            </div>
          </div>
        </div>
        {msgType === "confirm" ? (
          <div className="row justify-content-center pb-3">
            <button
              type="button"
              className="col-3 btn btn-dark me-3"
              onClick={closeFunction}
              style={{ lineHeight: "1.2", borderRadius: "0" }}
            >
              확인
            </button>
            <button
              type="button"
              className="col-3 btn"
              data-bs-dismiss="toast"
              onClick={onCancel}
              style={{
                lineHeight: "1.2",
                borderRadius: "0",
                borderColor: "gray",
              }}
            >
              취소
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ToastAlert;
