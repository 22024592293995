import { BandBoardCard } from "./BandBoardCard";

export const BandBoardSearchResultsList = ({ results, setSelectedPost }: any) => {
  console.log("results : ", results);

  return (
    <>
      {results.map((notice: any) => (
        <div key={notice.id} className="p-1">
          <BandBoardCard post={notice} setSelectedPost={setSelectedPost} />
        </div>
      ))}
    </>
  );
};
