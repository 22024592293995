import axios from "axios";
import baseUrl from "../utils/baseUrl";

const create = async (
  title: string,
  content: string,
  place: string,
  phone: string,
  startDate: string,
  endDate: string
) => {
  const payload = { title, content, place, phone, startDate, endDate };
  const { data } = await axios.post(`${baseUrl}/api/events`, payload);
  return data;
};

const read = async (year: number, month: number, date: number) => {
  const payload = {
    params: {
      year,
      month,
      date,
    },
  };
  const { data } = await axios.get(`${baseUrl}/api/events`, payload);
  return data;
};

const readById = async (id: string) => {
  const { data } = await axios.get(`${baseUrl}/api/events/${id}`);
  return data;
};

const remove = async (eventId: any) => {
  const { data } = await axios.delete(`${baseUrl}/api/events`, {
    params: { eventId },
  });
  return data;
};

const update = async (
  id: number,
  title: string,
  content: string,
  place: string,
  phone: string,
  startDate: string,
  endDate: string
) => {
  const payload = { id, title, content, place, phone, startDate, endDate };
  const { data } = await axios.put(`${baseUrl}/api/events`, payload);
  return data;
};

const eventApi = {
  create,
  read,
  readById,
  remove,
  update,
};

export default eventApi;
