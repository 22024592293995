import { useAuth } from "../../context/auth_context";
import Comment from "./Comment";

type PostType = {
  id: number;
  attachments: number[];
  content: string;
  group: string;
  createdAt: string;
  updateddAt: string;
  fileInfos: { saveName: string }[];
  user: { id: number, name: string };
};

type Props = {
  modalId: string;
  post: PostType;
  deletePost: any;
  editPost: any;
};

const BandBoardModal = ({ modalId, post, deletePost, editPost }: Props) => {
  const { user } = useAuth();

  return (
    <div
      className="modal fade"
      id={modalId}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title px-2" id="exampleModalLabel">
              {post.user.name}{" "}
              <span style={{ fontSize: "70%" }}>
                {post.createdAt.split(/T/)[0]}
              </span>
            </h5>
            {user?.userId === post.user.id && (
              <>
                <i
                  className="bi bi-pencil-fill px-1"
                  role="button"
                  data-bs-dismiss="modal"
                  onClick={editPost}
                />
                <i
                  className="bi bi-trash-fill px-1"
                  role="button"
                  data-bs-dismiss="modal"
                  onClick={() => deletePost(post.id)}
                />
              </>
            )}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div
            className="modal-body row row-cols-1 row-cols-lg-2"
            style={{ height: "500px" }}
          >
            <div className="col-lg-6">
              {post.fileInfos?.length === 1 ? (
                <img
                  src={`/uploads/${post.fileInfos[0].saveName}`}
                  className="img-fluid"
                  alt="..."
                  style={{ width: "100%", height: "auto", overflow: "hidden" }}
                />
              ) : (
                <div
                  id="carouselExampleIndicators"
                  className="carousel carousel-dark slide"
                  data-bs-ride="carousel"
                  data-bs-interval="false"
                >
                  <div className="carousel-indicators">
                    {post.fileInfos?.map((value, index) => (
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={index}
                        className={index === 0 ? "active" : undefined}
                        aria-current={index === 1 ? "true" : undefined}
                        aria-label={`Slide ${index}`}
                      ></button>
                    ))}
                  </div>
                  <div className="carousel-inner">
                    {post.fileInfos?.map((value, index) => (
                      <div
                        className={
                          index === 0 ? "carousel-item active" : "carousel-item"
                        }
                      >
                        <img
                          src={`/uploads/${value.saveName}`}
                          className="d-block w-100"
                          alt="..."
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <p>{post.content}</p>
              <Comment postId={String(post.id)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BandBoardModal;
