import { Link, useRouteMatch } from "react-router-dom";
import { GROUP_NAMES } from "../../utils/constants";
import styles from "./band.module.scss";

interface MatchParams {
  category: string;
}

export const BandSidebar = ({ group }: any) => {
  const category =
    useRouteMatch<MatchParams>(`/band/${group}/:category`)?.params.category ||
    "notice";

  const activeLink = (btnCategory: string) => {
    return category === btnCategory
      ? "nav-link text-primary active"
      : "nav-link";
  };

  return (
    <div className={`col-md-3 col-lg-2 px-2 ${styles["band-sidebar"]}`}>
      <div className="d-none d-md-block">
        <span className="ms-1">희락</span>
        <p className="fs-2 fw-bold">{GROUP_NAMES.get(group)?.name}</p>
      </div>
      <div className={`${styles["navBar"]}`}>
        <ul className={`list-group ${styles["listGroup"]}`}>
          <Link to={`/band/${group}/notice`} className="text-decoration-none">
            <li
              className={`list-group-item border-0 border-bottom bg-transparent ${activeLink(
                "notice"
              )}`}
            >
              공지사항
            </li>
          </Link>
          <Link to={`/band/${group}/pray`} className="text-decoration-none ">
            <li
              className={`list-group-item border-0 border-bottom bg-transparent ${activeLink(
                "pray"
              )}`}
            >
              기도제목
            </li>
          </Link>
          <Link to={`/band/${group}/board`} className="text-decoration-none">
            <li
              className={`list-group-item border-0 bg-transparent ${activeLink(
                "board"
              )}`}
            >
              자유게시판
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};
