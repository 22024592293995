import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import worshipApi from "../../apis/worship.api";
import { useAuth } from "../../context/auth_context";
import { WorshipType } from "../../types/heerak";
import { firstCharacterUpper } from "../../utils/clientHelper";
import MenuInfoDropdown from "../InfoContainer/MenuInfoDropdown";
import styles from "./Menu.module.css";
import WorshipTimetable from "./WorshipTimetable";

const Menu = () => {
  const { user, logout } = useAuth();
  const [worships, setWorships] = useState<WorshipType[]>([]);

  useEffect(() => {
    getWorships();
  }, []);

  const getWorships = async () => {
    const worshipsList = await worshipApi.getWorships();
    console.log("worship list in menu", worshipsList);
    setWorships(worshipsList);
  };

  console.log("user in Menu", user);
  return (
    <div className={styles.menuRow}>
      <div className={styles.siteMenuRow} id="siteMenu">
        <ul className={`nav justify-content-end ${styles.siteMenu}`}>
          {user ? (
            <>
              <li className="nav-item">
                <Link className="nav-link" to="/profile">
                  <i className="bi bi-person me-1"></i>
                  {firstCharacterUpper(user.name)}님
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/login" onClick={logout}>
                  <i className="bi bi-box-arrow-right me-1"></i>로그아웃
                </Link>
              </li>
            </>
          ) : (
            <>
              <li className="nav-item">
                <Link className="nav-link" to="/login">
                  로그인
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/signup">
                  회원가입
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/home">
            <img
              src="/heerak_logo.png"
              alt="홈페이지"
              style={{ height: "3rem" }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarExtraContent"
            aria-controls="navbarExtraContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarExtraContent">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                {/* <Link className="nav-link " aria-current="page" to="/info">
                  교회소개
                </Link> */}
                <MenuInfoDropdown />
              </li>
              <Link className="nav-link" to="/board/notice">
                <li
                  className="nav-item"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  공지사항
                </li>
              </Link>
              <Link className="nav-link" to="/board/other">
                <li
                  className="nav-item"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  자유게시판
                </li>
              </Link>
              <li className="nav-item dropdown">
                <div
                  className="nav-link"
                  id="navbarWorshipTimeDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  예배시간
                </div>
                <div
                  className={`dropdown-menu ${styles.dropdownMenu}`}
                  aria-labelledby="navbarWorshipTimeDropdown"
                >
                  <WorshipTimetable worships={worships} />
                </div>
              </li>
              <li className="nav-item dropdown">
                <div
                  className="nav-link"
                  id="navbarGroupDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  모임방
                </div>
                <ul
                  className={`dropdown-menu ${styles.mwTest}`}
                  aria-labelledby="navbarGroupDropdown"
                >
                  <li>
                    <Link to="/band/youth" className="dropdown-item">
                      중고등부
                    </Link>
                  </li>
                  <li>
                    <Link to="/band/youngadult" className="dropdown-item">
                      청년부
                    </Link>
                  </li>
                  <li>
                    <Link to="/band/menministry" className="dropdown-item">
                      남선교회
                    </Link>
                  </li>
                  <li>
                    <Link to="/band/womenministry" className="dropdown-item">
                      여전도회
                    </Link>
                  </li>
                  <li>
                    <Link to="/band/district" className="dropdown-item">
                      구역모임
                    </Link>
                  </li>
                </ul>
              </li>
              {user && user.role === "admin" && (
                <li className="nav-item">
                  <Link to="/admin" className="nav-link">
                    관리
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Menu;
