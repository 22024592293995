import AdminLayout from "./AdminLayout";
import { AdminHome } from "../../components/admin";

const AdminPage = () => {
  return (
    <AdminLayout>
      <AdminHome />
    </AdminLayout>
  );
};

export default AdminPage;
