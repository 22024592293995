import { BANNER_KINDS } from "./BannerAdd";
import { Link } from "react-router-dom";
import { AdminBannerCarousel } from "./AdminBannerCarousel";

type Props = {
  banners: {
    createdAt: string;
    description: string;
    endDate: string;
    fileInfo: { id: number; orgName: string; saveName: string };
    fileInfoId: number;
    id: number;
    kinds: string;
    show: false;
    startDate: string;
    updatedAt: string;
    url: string;
  }[];
  handleRemove: any;
};

const BannerList = ({ banners, handleRemove }: Props) => {
  console.log("banner list", banners);
  const mainBanners = banners.filter((banner) => banner.show);

  return (
    <div className="">
      <h4 className="my-4">배너 미리보기</h4>
      <div className="border border-1">
        <AdminBannerCarousel mainBanners={mainBanners} />
      </div>
      <div className="d-flex mt-5 justify-content-between">
        <h5 className="">배너 목록</h5>
        <Link to="/admin/banner/add">
          <button type="button" className="btn btn-primary">
            배너 추가
          </button>
        </Link>
      </div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr className="text-center">
              <th>구분</th>
              <th>설명</th>
              <th>이미지</th>
              <th>홈화면</th>
              <th>시작일</th>
              <th>종료일</th>
              <th>선택</th>
            </tr>
          </thead>
          <tbody>
            {banners.map((banner: any) => {
              return (
                <tr className="text-center">
                  <td>{BANNER_KINDS.get(banner.kinds)}</td>
                  <td>{banner.description}</td>
                  <td>
                    {banner.fileInfo?.saveName && (
                      <img
                        src={`/uploads/${banner.fileInfo.saveName}`}
                        alt={banner.description}
                        className="img-fluid"
                        style={{ width: "200px" }}
                      />
                    )}{" "}
                  </td>
                  <td>{banner.show ? "예" : "아니오"}</td>
                  <td>{banner.startDate?.split("T")[0]}</td>
                  <td>{banner.endDate?.split("T")[0]}</td>
                  <td>
                    <Link to={`/admin/banner/edit/${banner.id}`}>
                      <button className="me-3">수정</button>
                    </Link>
                    <button onClick={() => handleRemove(banner.id)}>
                      삭제
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BannerList;
