import { useEffect, useState } from "react";
import eventApi from "../apis/event.api";
import mediaApi from "../apis/media.api";
import noticeApi from "../apis/notice.api";
import { useAuth } from "../context/auth_context";
import { VideoComponent } from "./VideoComponent";
import { EventComponent } from "./EventComponent";
import { NoticeComponent } from "./NoticeComponent";
import { Notice } from "../types/heerak";

const initNotice: Notice = {
  id: "",
  content: "",
  title: "\u200b",
  importance: "",
  group: "",
};
const InitNoticeList = Array(4).fill(initNotice);
let InitEvent = [":　", ":　", ":　", ":　"];

export const ShowRoom = () => {
  const { catchErrorAuth } = useAuth();
  const [notices, setNotices] = useState(InitNoticeList);
  const [events, setEvents] = useState(InitEvent);

  const [churchVideoList, setChurchVideoList] = useState([]);
  const [mainVideos, setMainVideos] = useState([]);
  const [heerakVideoList, setHeerakVideoList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [error, setError] = useState("");

  const getHeerakVideos = async () => {
    try {
      const kind = "heerak";
      const videos = await mediaApi.getVideo(kind);
      console.log("heerak videos", videos);
      setHeerakVideoList(videos);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const getChurchVideos = async () => {
    try {
      const kind = "church";
      const videos = await mediaApi.getVideo(kind);
      setChurchVideoList(videos);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const getAllMainVideos = async () => {
    try {
      const mainVideos = await mediaApi.getAllMainVideos();
      console.log("main vedios", mainVideos);
      setMainVideos(mainVideos);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  useEffect(() => {
    getAllMainVideos();
    getHeerakVideos();
    getChurchVideos();
    getChannels();
    getNotices();
    getEvent();
  }, []);

  const getChannels = async () => {
    try {
      const channels = await mediaApi.getChannels();
      // if (channels.length > 3) {
      setChannelList(channels);
      // }
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const getNotices = async () => {
    try {
      // const titles = ["등록된 공지가 없습니다.", "　", "　", "　"];
      const titles: Notice[] = Array(4).fill(initNotice);
      const { notices, importantNotices } = await noticeApi.readPosts(
        "notice",
        1,
        4
      );
      console.log("notices=", notices, "important notices=", importantNotices);
      importantNotices.forEach((notice: Notice, index: number) => {
        titles.splice(index, 1, notice);
      });
      notices.forEach((notice: Notice, index: number) => {
        if (importantNotices.length + index < 4) {
          titles.splice(importantNotices.length + index, 1, notice);
        }
      });
      console.log("titles", titles);
      setNotices(titles);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  const getEvent = async () => {
    try {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth();
      const date = today.getDate();
      const response = await eventApi.read(year, month, date);
      let events: any = [":이후 등록된 행사가 없습니다.", ":　", ":　", ":　"];
      response.map((event: any, index: number) => {
        if (index >= 4) {
          return;
        }
        event.startDay = event.startDate.split(/T/)[0];
        events.splice(
          index,
          1,
          `${event.startDay.split(/-/)[1]}/${event.startDay.split(/-/)[2]}:${
            event.title
          }`
        );
        return events;
      });
      setEvents(events);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  };

  return (
    <div className="row align-items-center text-center my-4">
      <NoticeComponent notices={notices} />
      <EventComponent contents={events} />
      <VideoComponent
        churchVideoList={churchVideoList}
        heerakVideoList={heerakVideoList}
        mainVideos={mainVideos}
        channelList={channelList}
      />
    </div>
  );
};
