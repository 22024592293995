import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Title from "./Title";
import noticeApi from "../apis/notice.api";
import fileApi from "../apis/file.api";
import { useAuth } from "../context/auth_context";
import { GROUP_NAMES } from "../utils/constants";
import ToastAlert from "./ToastAlert";
import { AlertMessage } from "../types/heerak";

type NoticeType = {
  id: number;
  title: string;
  userId: number;
  createdAt: string;
  user: { name: string };
  content: string;
  images: ImageFileType[];
  attachList: { id: number; orgName: string; type: string }[];
};

type ImageFileType = {
  id: number;
  saveName: string;
  orgName: string;
  type: string;
};

const INIT_NOTICE: NoticeType = {
  id: 0,
  title: "",
  userId: 0,
  createdAt: "",
  user: { name: "" },
  content: "",
  images: [],
  attachList: [],
};

interface ParamTypes {
  group: string;
  noticeId: string;
}

const Notice = () => {
  const { catchErrorAuth } = useAuth();
  const history = useHistory();
  const { group, noticeId } = useParams<ParamTypes>();
  const [notice, setNotice] = useState({
    previous: { ...INIT_NOTICE },
    current: { ...INIT_NOTICE },
    next: { ...INIT_NOTICE },
  });
  const [error, setError] = useState("");
  const { user } = useAuth();
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  console.log("history in notice", history);

  useEffect(() => {
    getNotice(group, noticeId);
  }, [group, noticeId]);

  async function getNotice(group: string, noticeId: string) {
    try {
      setError("");
      const { previous, current, next } = await noticeApi.readOne(
        group,
        noticeId
      );
      const images: ImageFileType[] = [];
      const attachList: ImageFileType[] = [];
      if (current.attachList.length > 0) {
        current.attachList.forEach((file: ImageFileType) => {
          if (["jpg", "jpeg", "png"].includes(file.type)) {
            images.push(file);
          } else attachList.push(file);
        });
      }
      setNotice({
        previous,
        current: { ...current, attachList: attachList, images: images },
        next,
      });
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }

  async function deleteNotice(e: any, id: any) {
    e.preventDefault();
    try {
      setError("");
      setAlertMessage({
        message: `${GROUP_NAMES.get(group)?.name} 글을 삭제하시겠습니까?`,
        msgType: "confirm",
        closeFunction: async () => {
          await noticeApi.remove(group, id);
          history.push(`/board/${group}`);
        },
        onCancel: () => setAlertMessage(null),
      });
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }

  function moveNotice(id: any) {
    if (id === null) {
      setAlertMessage({
        message: "존재하지 않는 글입니다. 다시 선택해주세요.",
        msgType: "error",
        closeFunction: () => setAlertMessage(null),
        onCancel: () => setAlertMessage(null),
      });
    } else history.push(`/board/${group}/${id}`);
  }

  async function handleClick(id: number, fileName: string) {
    try {
      setError("");
      await fileApi.download(id, fileName);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }

  return (
    <div className="row flex-column">
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <Title>{GROUP_NAMES.get(group)?.name}</Title>
      {error && <div className="alert alert-danger">{error}</div>}
      {user &&
      (Number(user.userId) === notice.current.userId ||
        user.role === "admin") ? (
        <div className="d-flex gap-2 justify-content-end mb-3">
          <button
            className="btn btn-primary text-white me-1 col-md-1 col-2"
            type="button"
            onClick={() => history.push(`/edit/${group}/${notice.current.id}`)}
          >
            수정
          </button>
          <button
            className="btn btn-danger text-white col-md-1 col-2"
            type="button"
            onClick={(e) => deleteNotice(e, notice.current.id)}
          >
            삭제
          </button>
        </div>
      ) : null}
      <div className="row bg-info py-3 mx-auto">
        <h4 className="col-md-9 align-self-baseline mb-0">
          {notice.current.title}
        </h4>
        <p className="col-md-3 align-self-baseline mb-0 text-md-end">
          {notice.current.createdAt && notice.current.createdAt.split(/T/)[0]} |{" "}
          {notice.current.user.name}
        </p>
      </div>
      <div
        className="border-bottom border-3 pb-5 mt-5 mb-3"
        style={{ whiteSpace: "pre-line" }}
      >
        {notice.current.content}
        {notice.current.images?.map((file: any) => (
          <img
            key={file.id}
            src={`/uploads/${file.saveName}`}
            className="img-fluid d-block mx-auto my-3"
            alt={`${file.orgName}`}
          />
        ))}
      </div>
      <div className="row list-group mx-auto my-3">
        {notice.current.attachList?.map((file: any) => (
          <li
            key={file.id}
            className="col-md-8 col-12 list-group-item d-flex justify-content-between align-self-center"
            onClick={() => handleClick(file.id, file.orgName)}
            style={{ cursor: "pointer" }}
          >
            <div>{file.orgName}</div>
            <i className="bi bi-download"></i>
          </li>
        ))}
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary text-white"
          type="button"
          onClick={() => history.push(`/board/${group}`)}
        >
          목록으로
        </button>
      </div>
      <div
        className="my-3"
        style={{
          borderTop: "solid 2px #4f4f4f",
          borderBottom: "solid 2px #4f4f4f",
        }}
      >
        <div className="row py-3">
          <div
            className="col-12 pb-3"
            onClick={() => moveNotice(notice.next.id)}
            style={{ borderBottom: "solid 1px #d9d9d9", cursor: "pointer" }}
          >
            <i className="bi bi-chevron-up" />
            <span style={{ padding: "0 1rem 0 1rem" }}>다음 글</span>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {notice.next.title}
            </span>
          </div>
          <div
            className="col-12 mt-3"
            onClick={() => moveNotice(notice.previous.id)}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-chevron-down" />
            <span style={{ padding: "0 1rem 0 1rem" }}>이전 글</span>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {notice.previous.title}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notice;
