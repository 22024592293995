import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminPage from "./pages/AdminPage";
import PrivateRoute from "./components/PrivateRoute";
import { BandPage } from "./components/Band";
import { AuthProvider } from "./context/auth_context";
import { AppWrapper } from "./AppWrapper";

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <AuthProvider>
        <Switch>
          <PrivateRoute path="/admin" component={AdminPage} role="admin" />
          <Route path="/band/:group" component={BandPage} />
          <Route>
            <AppWrapper />
          </Route>
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;
