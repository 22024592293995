import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import bandApi from "../../apis/band.api";
import { GROUP_NAMES } from "../../utils/constants";
import Title from "../Title";
import { useAuth } from "../../context/auth_context";
import ToastAlert from "../ToastAlert";
import { AlertMessage } from "../../types/heerak";

type NoticeType = {
  id: number;
  importance: string;
  title: string;
  content: string;
  attachList: { id: number; filename: string }[];
  files: FileList | null;
};

const INIT_NOTICE: NoticeType = {
  id: 0,
  title: "",
  importance: "",
  content: "",
  attachList: [],
  files: null,
};

const BandNoticeEdit = () => {
  const { catchErrorAuth } = useAuth();
  const history = useHistory();
  const match = useRouteMatch();
  const { group, noticeId } = useParams<{ group: string; noticeId: string }>();
  const [notice, setNotice] = useState(INIT_NOTICE);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const myCheckBox = useRef<HTMLInputElement>(null);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  function backClick() {
    history.goBack();
  }

  useEffect(() => {
    getNotice(group, noticeId);
  }, [group, noticeId]);

  useEffect(() => {
    const isNotice = Object.values({ title: notice.title }).every((el) =>
      Boolean(el)
    );
    isNotice ? setDisabled(false) : setDisabled(true);

    if (myCheckBox.current) {
      myCheckBox.current.checked = notice.importance === "on" ? true : false;
    }
  }, [notice]);

  async function getNotice(group: string, noticeId: string) {
    try {
      setError("");
      const { current } = await bandApi.readBandNoticeById(group, noticeId);
      setNotice(current);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }

  function delUrl(index: any) {
    notice.attachList.splice(index, 1);
    setNotice({ ...notice, attachList: notice.attachList });
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value, files } = e.target;
    if (files) {
      setNotice({ ...notice, [name]: files });
    } else if (name === "importance") {
      notice[name] !== "on"
        ? setNotice({ ...notice, [name]: value })
        : setNotice({ ...notice, [name]: "off" });
    } else {
      setNotice({ ...notice, [name]: value });
    }
  }

  function handleTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const { name, value } = e.target;
    setNotice({ ...notice, [name]: value });
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");
      const formData = new FormData();
      notice["title"] && formData.append("title", notice["title"]);
      notice["content"] && formData.append("content", notice["content"]);
      notice["importance"] &&
        formData.append("importance", notice["importance"]);
      formData.append("group", group);
      if (notice.attachList.length !== 0) {
        for (let index = 0; index < notice.attachList.length; index++) {
          const element = notice.attachList[index];
          formData.append("attachList", String(element.id));
        }
      } else {
        formData.append("attachList", "");
      }
      if (notice.files) {
        for (let index = 0; index < notice.files.length; index++) {
          const element = notice.files[index];
          formData.append("file", element);
        }
      }
      await bandApi.editBandNotice(group, String(notice.id), formData);
      setAlertMessage({
        message: "공지사항 수정이 완료되었습니다.",
        msgType: "success",
        closeFunction: () => setSuccess(true),
        onCancel: () => setAlertMessage(null),
      });
    } catch (error) {
      catchErrorAuth(error, setError);
    } finally {
      setLoading(false);
    }
  }

  if (success) {
    const path = match.url.replace(/\/edit.*$/, "");
    history.push(path, { refresh: true });
  }

  return (
    <div className="container-fluid">
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <div
        className="container-fluid p-3"
        style={{
          borderTop: "1px solid #4f4f4f",
          borderBottom: "1px solid #4f4f4f",
          marginTop: "16px",
        }}
      >
        <div className="row pb-3" style={{ borderBottom: "solid 1px #d9d9d9" }}>
          <div className="col-1 d-flex align-items-center">
            <i
              className="bi bi-chevron-left"
              onClick={backClick}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="col-10 d-flex justify-content-center align-items-center fs-4">
            <b>공지사항 수정</b>
          </div>
        </div>
        <>
          <Title>{`${GROUP_NAMES.get(group)?.name} 글 수정`}</Title>
          {error && <div className="alert alert-danger">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div
              className="d-flex justify-content-between"
              style={{ marginBottom: "0.5rem" }}
            >
              <label
                htmlFor="titleInput"
                className="form-label mb-0"
                style={{ fontSize: "1.25rem" }}
              >
                제목
              </label>
            </div>
            <input
              type="text"
              className="form-control"
              id="titleInput"
              name="title"
              value={notice.title}
              onChange={handleChange}
            />
            <div className="my-2">
              <label
                htmlFor="contentTextarea"
                className="form-label"
                style={{ fontSize: "1.25rem" }}
              >
                내용
              </label>
              <textarea
                className="form-control"
                id="contentTextarea"
                name="content"
                value={notice.content}
                onChange={handleTextAreaChange}
                style={{ resize: "none", height: "15rem" }}
              ></textarea>
            </div>
            <div className="row mx-auto my-2">
              {notice.attachList?.map((file: any, index) => (
                <div
                  className="col-3 alert alert-white text-primary text-center mb-0 py-1"
                  role={"alert" + index}
                >
                  {file.orgName}
                  <button
                    type="button"
                    className="btn-close text-danger mx-1"
                    data-bs-dismiss={"alert" + index}
                    aria-label="Close"
                    onClick={() => delUrl(index)}
                    style={{ fontSize: "0.7rem" }}
                  ></button>
                </div>
              ))}
            </div>
            <div className="mb-4 mt-2">
              <input
                className="form-control"
                type="file"
                id="multipleFile"
                name="files"
                onChange={handleChange}
                multiple
              />
            </div>
            <div className="d-grid gap-2 my-1">
              <button
                className="btn btn-primary text-white"
                type="submit"
                disabled={disabled || loading}
              >
                등록
              </button>
            </div>
          </form>
        </>
      </div>
    </div>
  );
};

export default BandNoticeEdit;
