import { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import fileApi from "../../apis/file.api";
import bandApi from "../../apis/band.api";
import { useAuth } from "../../context/auth_context";
import ToastAlert from "../ToastAlert";

type NoticeType = {
  id: number;
  title: string;
  createdAt: string;
  user: { name: string };
  content: string;
  images: { id: number; saveName: string; orgName: string; type: string }[];
  attachList: { id: number; orgName: string; type: string }[];
};

type ImageFileType = {
  id: number;
  saveName: string;
  orgName: string;
  type: string;
};

const INIT_NOTICE: NoticeType = {
  id: 0,
  title: "",
  createdAt: "",
  user: { name: "" },
  content: "",
  images: [],
  attachList: [],
};

interface ParamTypes {
  noticeId: string;
}

const BandNoticePost = ({ match }: any) => {
  const { catchErrorAuth } = useAuth();
  const { group } = useParams<{ group: string }>();
  const history = useHistory();
  const { noticeId: id } = useParams<ParamTypes>();

  const [noticeId, setNoticeId] = useState(id);
  const [notice, setNotice] = useState({
    previous: { ...INIT_NOTICE },
    current: { ...INIT_NOTICE },
    next: { ...INIT_NOTICE },
  });
  const [error, setError] = useState("");

  const [alertMessage, setAlertMessage] = useState<any>({
    message: "",
    msgType: "Info",
    closeFunction: () => {},
    onCancel: () => {},
  });

  useEffect(() => {
    getNotice(noticeId);
  }, [noticeId]);

  async function getNotice(id: any) {
    try {
      setError("");
      const { previous, current, next } = await bandApi.readBandNoticeById(
        group,
        id
      );
      const images: ImageFileType[] = [];
      const attachList: ImageFileType[] = [];
      if (current.attachList.length !== 0) {
        current.attachList.forEach((file: ImageFileType) => {
          if (["jpg", "jpeg", "png"].includes(file.type)) {
            images.push(file);
          } else attachList.push(file);
        });
      }
      setNotice({
        previous,
        current: { ...current, attachList: attachList, images: images },
        next,
      });
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }

  function editNotice(id: any) {
    console.log("match.url in band notice post", match.url);
    return history.push(`${match.url}/edit/${id}`);
  }

  async function deleteNotice(e: any, id: any) {
    e.preventDefault();
    try {
      setError("");
      setAlertMessage({
        message: "공지사항을 삭제하시겠습니까?",
        msgType: "confirm",
        closeFunction: async () => {
          await bandApi.removeBandNotice(group, id);
          history.push(`${match.url}`, { refresh: true })
        },
        onCancel: () => setAlertMessage(""),
      });
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }

  function moveNotice(id: any) {
    if (id === null) {
      setAlertMessage({
        message: "존재하지 않는 글입니다. 다시 선택해주세요.",
        msgType: "error",
        closeFunction: () => setAlertMessage(""),
        onCancel: () => setAlertMessage(""),
      });
    } else {
      setNoticeId(id);
    }
  }

  async function handleClick(id: number, fileName: string) {
    try {
      setError("");
      await fileApi.download(id, fileName);
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }

  return (
    <div className="container-fluid">
      {alertMessage.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <div
        className="container-fluid p-3"
        style={{
          borderTop: "1px solid #4f4f4f",
          borderBottom: "1px solid #4f4f4f",
          marginTop: "16px",
        }}
      >
        <div className="row pb-3" style={{ borderBottom: "solid 1px #d9d9d9" }}>
          <div className="col-1 d-flex align-items-center">
            <Link
              to={`${match.url}`}
              type="button"
              className="bi bi-chevron-left"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="col-10 d-flex justify-content-center align-items-center fs-4">
            <b>{notice.current.title}</b>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-2">
          <button
            className="btn btn-primary text-white me-1 col-sm-2 col-4"
            type="button"
            onClick={() => editNotice(noticeId)}
          >
            수정
          </button>
          <button
            className="btn btn-danger text-white col-sm-2 col-4"
            type="button"
            onClick={(e) => deleteNotice(e, noticeId)}
          >
            삭제
          </button>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="d-flex justify-content-end align-items-center pt-2">
          {notice.current.user.name} {notice.current.createdAt.split(/T/)[0]}
        </div>
        <div className="py-3">
          {notice.current.content}
          {notice.current.images?.map((file: any) => (
            <img
              src={`/uploads/${file.saveName}`}
              className="d-block mx-auto my-3"
              alt={`${file.orgName}`}
            />
          ))}
        </div>
        <div className="row list-group mx-auto my-3">
          {notice.current.attachList?.map((file: any) => (
            <li className="col-md-8 col-12 list-group-item d-flex justify-content-between align-self-center">
              <div
                onClick={() => handleClick(file.id, file.orgName)}
                style={{ cursor: "pointer" }}
              >
                {file.orgName}
              </div>
              <i
                className="bi bi-download"
                onClick={() => handleClick(file.id, file.orgName)}
                style={{ cursor: "pointer" }}
              ></i>
            </li>
          ))}
        </div>
      </div>
      <div
        className="container-fluid my-3"
        style={{
          borderTop: "solid 2px #4f4f4f",
          borderBottom: "solid 2px #4f4f4f",
        }}
      >
        <div className="row py-3">
          <div
            className="col-12 pb-3"
            onClick={() => moveNotice(notice.next.id)}
            style={{ borderBottom: "solid 1px #d9d9d9" }}
          >
            <i className="bi bi-chevron-up" />
            <span style={{ padding: "0 1rem 0 1rem" }}>다음 글</span>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {notice.next.title}
            </span>
          </div>
          <div
            className="col-12 mt-3"
            onClick={() => moveNotice(notice.previous.id)}
          >
            <i className="bi bi-chevron-down" />
            <span style={{ padding: "0 1rem 0 1rem" }}>이전 글</span>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {notice.previous.title}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BandNoticePost;
