import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Pagination from "../Pagination";
import bandApi from "../../apis/band.api";
import { useAuth } from "../../context/auth_context";
import ToastAlert from "../ToastAlert";
import { AlertMessage } from "../../types/heerak";

interface ParamTypes {
  group: string;
}

const BandPrayList = ({ match }: any) => {
  const { catchErrorAuth } = useAuth();
  const history = useHistory();
  const { group } = useParams<ParamTypes>();

  const [error, setError] = useState("");

  const [finishedCards, setFinishedCards] = useState([]);

  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>();

  useEffect(() => {
    getFinishedCards();
  }, []);

  const getFinishedCards = async () => {
    const data = await bandApi.groupFinishedPrayRead(group);
    return setFinishedCards(data);
  };

  async function deletePray(id: any) {
    try {
      console.log('delete pray????');
      setError("");
      setAlertMessage({
        message: "기도제목을 삭제하시겠습니까?",
        msgType: "confirm",
        closeFunction: async () => {
          await bandApi.groupPrayRemove(id);
          setAlertMessage(null);
        },
        onCancel: () => setAlertMessage(null),
      });
      getFinishedCards();
      // window.location.reload();
    } catch (error) {
      catchErrorAuth(error, setError);
    }
  }

  return (
    <>
      {alertMessage?.message && (
        <ToastAlert
          message={alertMessage.message}
          msgType={alertMessage.msgType}
          closeFunction={alertMessage.closeFunction}
          onCancel={alertMessage.onCancel}
        />
      )}
      <div className="card bg-dark text-white" style={{ height: "8rem" }}>
        <div className="card-img-overlay">
          <h3 className="card-title">지난기도제목</h3>
          <p
            className="card-text"
            onClick={() => history.replace(`${match.url}`)}
            style={{ cursor: "pointer" }}
          >
            <span>
              <i className="bi bi-arrow-left-square px-1" />
            </span>
            기도제목으로 돌아가기
          </p>
        </div>
      </div>
      <div
        className="container-fluid py-3"
        style={{
          borderTop: "1px solid #4f4f4f",
          borderBottom: "1px solid #4f4f4f",
          marginTop: "16px",
        }}
      >
        <div className="row justify-content-end my-1 mx-auto">
          <div className="col-3 col-md-2 px-1">
            <select
              id="sort"
              className="form-select px-0"
              aria-label="sort Select"
            >
              <option selected>목록</option>
              <option value="제목">제목</option>
              <option value="작성자">작성자</option>
            </select>
          </div>
          <div className="col-9 col-md-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="inputSearchWord"
                placeholder="입력"
              />
              <button type="submit" className="btn btn-outline-primary">
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
        </div>
        <table
          className="table table-hover my-3"
          style={{ tableLayout: "fixed" }}
        >
          <thead className="table-dark">
            <tr style={{ height: "3rem" }}>
              <th className="text-center" style={{ width: "4rem" }}>
                구분
              </th>
              <th className="text-center">제목</th>
              <th
                className="d-none d-md-table-cell text-center"
                style={{ width: "5rem" }}
              >
                작성자
              </th>
              <th
                className="d-none d-md-table-cell text-center"
                style={{ width: "11rem" }}
              >
                마감일
              </th>
            </tr>
          </thead>
          <tbody>
            {finishedCards.map((post: any, index: any) => (
              <tr style={{ height: "3rem" }}>
                <td className="text-center">
                  {post.importance ? "중요" : "일반"}
                </td>
                <td>
                  <div
                    data-bs-toggle="collapse"
                    data-bs-target={`#post${index}`}
                    onClick={() => deletePray(post.id)}
                    aria-expanded="false"
                    aria-controls={`post${index}`}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {post.title}
                    <i
                      className="bi bi-trash-fill px-1"
                    />
                  </div>
                  <div className="collapse text-break" id={`post${index}`}>
                    {post.content}
                    <div className="d-block d-md-none">
                      {post.user.name} / {post.endDate.split(/T/)[0]}
                    </div>
                  </div>
                </td>
                <td className="d-none d-md-table-cell text-center">
                  {post.user.name}
                </td>
                <td className="d-none d-md-table-cell text-center">
                  {post.endDate.split(/T/)[0]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination />
    </>
  );
};

export default BandPrayList;
